import React from 'react';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Eu from '../../../containers/auth/Eu';

const StyledLogo = styled.img`
  margin: ${(props) => props.margin || '32px 0 0 32px'};
  cursor: ${(props) => props.cursor || 'pointer'};
  object-fit: contain;
  width: 188px;
  height: 26px;
`;

const Logo = ({ darkMode, src, margin, history, ...rest }) => {
  const { odm, app } = useSelector(({ app }) => ({
    odm: app.odm,
    app: app.app,
  }));

  return (
    <>
      <StyledLogo
        alt="Logo"
        src={src || process.env.PUBLIC_URL + `/${odm.images}/login/logo-heron-cloud-dark.svg`}
        margin={margin}
        onClick={() => {
          history.push('/');
        }}
        {...rest}
      ></StyledLogo>
      <Eu darkMode={darkMode}></Eu>
      {/* {app && app.server_loc === 'EU' && <span>.eu</span>} */}
    </>
  );
};

export default withRouter(Logo);
