import React, { useRef, useEffect, useContext, useState } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { TabletOrDesktop } from '../../../lib/responsive';

const sizeStyles = css`
  ${({ width, height, padding }) => css`
    width: ${width};
    height: ${height};
    padding: ${padding};
  `}
`;

const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${(props) =>
    props.isLoginForm
      ? props.isTabletOrDesktop
        ? props.width || '416px'
        : '100%'
      : props.width || '416px'};
  max-width: 100%;
`;

const StyledInput = styled.input`
  margin: ${(props) => props.margin};
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background-color: ${(props) => props.bgkColor || '#ffffff'};
  outline: none;

  color: ${(props) => props.color || '#59616a'};
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  /* Size */
  ${sizeStyles}

  ::placeholder {
    color: #969ba1;
  }

  &:not(:first-child) {
    margin-top: ${(props) => props.marginTop || '8px'};
  }
`;

const EyeIcon = styled.div`
  position: absolute;
  right: ${(props) => {
    if (props.isLoginForm) {
      return props.isTabletOrDesktop ? '-10px' : '75px';
    }
    return '-10px';
  }};
  top: ${(props) => (props.isPasswordReset ? '66%' : '50%')};
  transform: translateY(-50%);
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
    fill: #969ba1;

    &:hover {
      fill: #59616a;
    }
  }
`;

const Input = ({
  children,
  width,
  height,
  margin,
  padding,
  readOnly,
  maxLength,
  color,
  bgkColor,
  marginTop,
  type,
  isLoginForm,
  isPasswordReset,
  ...rest
}) => {
  const inputRef = useRef(null);
  let focus = false;
  const themeContext = useContext(ThemeContext);
  const isTabletOrDesktop = TabletOrDesktop();

  // Ajout d'états pour gérer la visibilité du mot de passe
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [inputType, setInputType] = useState(type);
  const isPassword = type === 'password';

  // Met à jour le type d'input quand le type change ou quand on clique sur l'icône
  useEffect(() => {
    setInputType(isPassword && passwordVisible ? 'text' : type);
  }, [type, passwordVisible, isPassword]);

  useEffect(() => {
    if (inputRef.current && readOnly) {
      inputRef.current.disabled = true;
      inputRef.current.removeEventListener('focus', onFocusInput);
      inputRef.current.removeEventListener('blur', onBlurInput);
      inputRef.current.removeEventListener('mouseover', onMouseOver);
      inputRef.current.removeEventListener('mouseout', onMouseOut);
    } else if (inputRef.current) {
      inputRef.current.addEventListener('focus', onFocusInput);
      inputRef.current.addEventListener('blur', onBlurInput);
      inputRef.current.addEventListener('mouseover', onMouseOver);
      inputRef.current.addEventListener('mouseout', onMouseOut);
    }

    // Clean up des event listeners
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('focus', onFocusInput);
        inputRef.current.removeEventListener('blur', onBlurInput);
        inputRef.current.removeEventListener('mouseover', onMouseOver);
        inputRef.current.removeEventListener('mouseout', onMouseOut);
      }
    };
  }, []);

  const onFocusInput = () => {
    focus = true;
    if (inputRef.current) {
      inputRef.current.style.border = `solid 1px ${themeContext.main_color}`;
      inputRef.current.style.backgroundColor = '#ffffff';
    }
  };

  const onBlurInput = () => {
    focus = false;
    if (inputRef.current) {
      inputRef.current.style.border = 'solid 1px #e7e9ea';
      inputRef.current.style.backgroundColor = '#ffffff';
    }
  };

  const onMouseOver = () => {
    if (focus === false) {
      if (inputRef.current) {
        inputRef.current.style.border = 'solid 1px #eeeff0';
        inputRef.current.style.backgroundColor = '#f5f6f6';
      }
    }
  };

  const onMouseOut = () => {
    if (focus === false) {
      if (inputRef.current) {
        inputRef.current.style.border = 'solid 1px #e7e9ea';
        inputRef.current.style.backgroundColor = '#ffffff';
      }
    }
  };

  // Fonction pour basculer la visibilité du mot de passe
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <InputWrapper
      width={width}
      isTabletOrDesktop={isTabletOrDesktop}
      isLoginForm={isLoginForm}
    >
      <StyledInput
        ref={inputRef}
        width={width}
        height={height}
        margin={margin}
        padding={padding}
        autoComplete="off"
        maxLength={maxLength}
        color={color}
        bgkColor={bgkColor}
        marginTop={marginTop}
        readOnly={readOnly}
        type={inputType}
        isPassword={isPassword}
        {...rest}
      />
      {isPassword && (
        <EyeIcon
          onClick={togglePasswordVisibility}
          isTabletOrDesktop={isTabletOrDesktop}
          isLoginForm={isLoginForm}
          isPasswordReset={isPasswordReset}
        >
          {passwordVisible ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-44.2 0-80 35.8-80 80s35.8 80 80 80z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
            </svg>
          )}
        </EyeIcon>
      )}
      {children}
    </InputWrapper>
  );
};

Input.defaultProps = {
  width: '416px',
  height: '19px',
  padding: '14px 15px 13px',
  margin: '24px 496px 0px 496px',
};

export default React.memo(Input);
