// import qs from 'qs';
// import { useState } from 'react';
// import Cookies from 'universal-cookie';
// import { resetAccessToken } from './api/auth';

// export const buildQueryString = (params_old, params_new = {}) => {
//   let params = { ...params_old, ...params_new };

//   // remove page if page is 1
//   if (params.page && params.page === 1) delete params['page'];
//   // remove search if search is empty
//   if (!params.search) delete params['search'];

//   const queryString = qs.stringify(params, { addQueryPrefix: true });
//   return queryString;
// };

// const cookies = new Cookies();

export const esObj = { setInProg: false };

export const isEmpty = (obj) => {
  if (obj === null || obj === undefined) return true;
  if (Object.keys(obj).length === 0) return true;
  return false;
};

export const humanSize = (size) => {
  if (size) {
    if (2 ** 30 <= size) return `${(size / 2 ** 30).toFixed(1)} GiB`;
    else if (2 ** 20 <= size) return `${(size / 2 ** 20).toFixed(1)} MiB`;
    else if (2 ** 10 <= size) return `${(size / 2 ** 10).toFixed(1)} kiB`;
    else return `${size} B`;
  } else return '0 B';
};

// async sleep
export const sleep = (ms) => {
  return new Promise((r) => setTimeout(r, ms));
};
