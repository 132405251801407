import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { getODM, theme } from '../../lib/odm';

const TextBlock = styled.div`
  width: 36px;
  height: 36px;
  float: left;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.2px;
  text-align: center;
  color: #a5a9ae;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 20px;
  cursor: pointer;
`;

const dwName = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const CalendarTextBox = ({ today, month, date, isSelected, onClicked }) => {
  const [btnState, setBtnState] = useState('leave');
  //const [isSelected, setIsSelected] = useState(false);
  // const [isToday, setIsToday] = useState(false);
  //const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    //console.log(today + "//" + date);
    // setIsToday();
  });

  function getStyle() {
    if (btnState === 'hover') {
      return {
        color: '#ffffff',
        fontWeight: '600',
        backgroundColor: theme.main_color_hover,
      };
      // return {color: '#ffffff', fontWeight: '600', backgroundColor: '#e5621f'};
    }

    if (isSelected) {
      return {
        color: '#ffffff',
        fontWeight: '600',
        backgroundColor: theme.main_color,
      };
      // return {color: '#ffffff', fontWeight: '600', backgroundColor: '#ff6d22'};
    }

    if (
      today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()
    ) {
      return {
        color: '#59616a',
        fontWeight: '600',
        backgroundColor: '#eeeff0',
      };
    }

    if (date.getMonth() === month)
      return {
        color: '#59616a',
        fontWeight: 'normal',
        backgroundColor: '#ffffff',
      };
    else
      return {
        color: '#a5a9ae',
        fontWeight: 'normal',
        backgroundColor: '#ffffff',
      };
  }

  return (
    <>
      <TextBlock
        onClick={() => onClicked(date)}
        onMouseEnter={() => setBtnState('hover')}
        onMouseLeave={() => setBtnState('leave')}
        style={getStyle()}
      >
        {date.getDate()}
      </TextBlock>
    </>
  );
};

export default CalendarTextBox;
