import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './ActivityItem.css';
import styled from 'styled-components';
// import { caseInfo } from '../../modules/case';
import { Link } from 'react-router-dom';
import { collapsed } from '../../modules/leftpanel';
import { getInitial } from '../common/Util';
import { setFilters } from '../../modules/cases';

const Container = styled.div`
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  width: auto;
  height: 54px;
  margin-top: 8px;
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background-color: #ffffff;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  /* .normal {
    width: 727px;
  }

  .collapsed {
    width: 855px;
  } */

  &:hover {
    background-color: #f5f6f6;
  }

  display: grid;
  grid-template-columns: 20px 120fr 5fr 36fr 8fr 200fr 5fr 250fr 8fr 56fr 20px;
  grid-template-rows: 56px;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'Space1 Date Space2 Portrait Space3 Name Space4 Desc Space5 CaseID Space6 ';

  .Date {
    /* width: 79px;
    height: 19px;
    margin: 18px 0px 19px 20px; */
    /* display: flex;
    justify-content: left;
    align-items: center; */
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969ba1;
    user-select: none;
    grid-area: Date;
    margin: auto 0 auto 0;
  }

  .Portrait {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    user-select: none;
    grid-area: Portrait;
    margin: auto 0 auto 0;
  }

  .Name {
    /* width: 200px;
    height: 19px;
    margin: 18px 0px 19px 8px; */
    /* display: flex;
    justify-content: left;
    align-items: center; */
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969ba1;
    user-select: none;
    grid-area: Name;
    margin: auto 0 auto 0;
  }

  .Desc {
    /* display: flex;
    justify-content: left;
    align-items: center; */
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    user-select: none;
    grid-area: Desc;
    margin: auto 0 auto 0;
  }

  .CaseID {
    /* display: flex;
    justify-content: flex-end;
    align-items: center; */
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: right;
    color: #969ba1;
    user-select: none;
    grid-area: CaseID;
    margin: auto 0 auto 0;
  }

  .picture {
    width: 32px;
    height: 32px;
    line-height: 32px;
    //margin-left: 10px;
    //margin-top: 0px;
    border-radius: 18px;
    background-color: #787f86;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    float: left;
  }
`;

const StyledImage = styled.img`
  width: 32px;
  height: 32px;
  border: 0;
  padding: 0;
  object-fit: cover;
  border-radius: 60%;
`;

const ActivityItem = ({ case_date, portrait, dent_name, img, type, case_id, case_no }) => {
  const leftpanel = useSelector((state) => state.leftpanel);

  const { filter, collapsed } = useSelector(({ cases, leftpanel }) => ({
    filter: cases.filters,
    collapsed: leftpanel.collapsed,
  }));

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const ActItem = () => {
    return (
      <Container
      //style={{ width: leftpanel.collapsed === false ? '727px' : '855px' }}
      >
        <div className="Date"> {case_date} </div>
        <div className="Portrait">
          {img === null && <div className="picture">{getInitial(dent_name)}</div>}

          {img !== null && <StyledImage src={img} />}
        </div>
        <div className="Name"> {dent_name} </div>
        <div className="Desc"> {type} </div>
        <div className="CaseID"> {case_no} </div>
      </Container>
    );
  };

  return (
    <>
      {case_id ? (
        <Link
          to={'/case/' + case_id}
          style={{ textDecoration: 'none' }}
          onClick={() => {
            /*
        filter.searchString = case_no;
        filter.status = [];
        dispatch(setFilters(filter));
        */
            //dispatch(caseInfo(case_id))
          }}
        >
          <ActItem />
        </Link>
      ) : (
        <ActItem />
      )}
    </>
  );
};

export default ActivityItem;
