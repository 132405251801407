import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { caseStatus } from '../../lib/api/case';
import { caseContent } from '../../modules/case';
import { getStatusString } from '../common/Util';

const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};

const Container = styled.div`
  .menu-container {
    position: relative;
    display: flex;
  }

  .menu-trigger {
    display: flex;
    background: #ffffff;
    border: solid 1px #e7e9ea;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    align-items: center;
    vertical-align: middle;

    &:hover {
      border: solid 1px #eeeff0;
      background: #f5f6f6;
    }

    &:active {
      border: solid 1px ${(props) => props.theme.main_color};
      background: #ffffff;
    }

    &:disabled {
      border: solid 1px #e7e9ea;
      background: #f5f6f6;
    }

    span {
      width: auto;
      height: auto;
      margin-left: 10px;
      padding-right: 40px;
      color: #59616a;
      font-family: 'Source Sans Pro';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      position: absolute;
      right: 0;
      margin: 6px 6px 6px 0;
      width: 24px;
      height: 24px;
    }
  }

  .menu {
    position: absolute;
    background: #ffffff;
    border: solid 1px #e7e9ea;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    display: block;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      &:first-child {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
    li {
      height: 40px;

      &:hover {
        background: #eeeff0;
      }
    }
    li div {
      font-family: Source Sans Pro;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #59616a;
      height: 100%;
      padding: 0px 0px 0px 15px;
      display: block;
    }

    li span {
    }
  }

  .active {
    opacity: 1;
    visibility: visible;
  }
`;

const StatusSelect = ({ content, width, height }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [selectedItem, setSelectedItem] = useState('');
  const onClick = () => setIsActive(!isActive);

  var menuTop = parseInt(height) + 4 + 'px';

  const getStatusStrings = () => {
    // OPEN - Open
    // PROC - In Progress
    // COMP - Completed
    // CLSD - Closed
    // REOP - Reopened

    if (content.usertype === 'AU' || content.usertype === 'SG') {
      // clinic
      if (content.status.code === 'OPEN' || content.status.code === 'READ')
        return [t('Close')];
      else if (content.status.code === 'PROC') return [t('Close')];
      else if (content.status.code === 'COMP') return [t('Reopen'), t('Close')];
      else if (content.status.code === 'REOP')
        return [t('Complete'), t('Close')];
      else if (content.status.code === 'CLSD') {
        if (content.status.prev === 'OPEN' || content.status.prev === 'READ')
          return [t('Open')];
        else return [t('Complete'), t('Reopen')];
      }
      return null;
    } else {
      // lab, usertype 'OT'
      if (content.status.code === 'OPEN' || content.status.code === 'READ')
        return [t('In Progress')];
      else if (content.status.code === 'PROC')
        return [t('Open'), t('Complete')];
      else if (content.status.code === 'COMP') return [t('In Progress')];
      else if (content.status.code === 'REOP') return [t('In Progress')];
      return null;
    }
  };

  const onStatusSelect = async (item) => {
    // setResetCombo(resetCombo + 1);

    let st;
    if (item === t('Open')) st = 'OPEN';
    else if (item === t('In Progress')) st = 'PROC';
    else if (item === t('Complete')) st = 'COMP';
    else if (item === t('Close')) st = 'CLSD';
    else if (item === t('Reopen')) st = 'REOP';
    else return;

    try {
      const res = await caseStatus({ id: content.id, action: st });
      dispatch(caseContent(content.id));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <div className="menu-container" style={{ width: width, height: height }}>
        <button
          className="menu-trigger"
          style={{ width: width }}
          onClick={onClick}
          type="button"
        >
          <span>{t(getStatusString(content.status.code))}</span>
          <img
            src={
              process.env.PUBLIC_URL +
              '/images/login/icon-24-arrow-down-active.svg'
            }
          />
        </button>
        <nav
          ref={dropdownRef}
          className={`menu ${isActive ? 'active' : 'inactive'}`}
          style={{ top: menuTop }}
        >
          {getStatusStrings() && (
            <ul>
              {getStatusStrings().map((item, index) => (
                <li key={index}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedItem(item);
                      onStatusSelect(item);
                      onClick();
                    }}
                  >
                    <table
                      style={{
                        tableLayout: 'fixed',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t(item)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </nav>
      </div>
    </Container>
  );
};
export default StatusSelect;
