import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputSearch from '../../components/common/InputSearch';
import HButton from '../../components/common/HButton';
import ConnectionItem from './ConnectionItem';
import CreateConnectionBox from './CreateConnection';
import MessageBox from '../common/MessageBox';
import ConfirmBox from '../common/ConfirmBox';
import styled from 'styled-components';
import { changeField, initializeForm, listConnections } from '../../modules/connections';
import {
  createConnection,
  createLabConnection,
  listLabs,
  requestConnection,
} from '../../lib/api/connections';
import { useTranslation } from 'react-i18next';
import Spinner from '../common/Spinner';
import { listAlarms } from '../../modules/leftpanel';

const MainBlock = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .ConnectionsNormal {
    width: 100%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: 64px 32px auto 60px;
    gap: 0px;
  }

  .ConnectionsCollapsed {
    width: 100%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: 64px 32px auto 60px;
    gap: 0px;
  }

  .ConnectionsTitle {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-left: 48px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }

  .ConnectionsSearch {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: center;
  }

  .ConnectionsButton {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: right;
    margin-right: 40px;
  }

  .ConnectionsContent {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .ConnectionsBottom {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  .pendingTitle {
    width: 100%;
    height: 25px;
    margin: 56px 0 16px 48px;
    font-size: 20px;
    font-weight: normal;
    color: #969ba1;
  }

  .acceptedTitle {
    width: 100%;
    height: 25px;
    margin: 56px 0 16px 48px;
    font-size: 20px;
    font-weight: normal;
    color: #969ba1;
  }

  .loading {
    margin-top: 100px;
    text-align: center;
  }
`;

const Connections = ({ match, history, accept }) => {
  const { token } = match;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  const {
    user,
    page1,
    connections,
    acceptconn,
    error,
    remove,
    reject,
    editconn,
    lab,
    changefield,
    userChecking,
    loading,
  } = useSelector(({ connections, user, loading }) => ({
    user: user.user,
    page1: connections.page.page1,
    lab: connections.lab.lab,
    changefield: connections.changefield,
    connections: connections.connections,
    acceptconn: connections.acceptconn,
    error: connections.error,
    remove: connections.remove,
    editconn: connections.editconn,
    reject: connections.reject,
    userChecking: loading['user/CHECK'],
    loading: loading['connections/LIST_CONNECTIONS'],
  }));

  const leftpanel = useSelector((state) => state.leftpanel);
  const [modalConnection, setModalConnection] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  const [searchFilter, setSearchFilter] = useState(null);

  const [confirmBoxContent, setConfirmBoxContent] = useState('');
  const [confirmBoxVisible, setConfirmBoxVisible] = useState(false);
  const [labNames, setLabNames] = useState(null);
  const [labs, setLabs] = useState(null);

  const onConfirmBoxOk = () => {
    setConfirmBoxVisible(false);
  };

  const onSelect = (value, name) => {
    if (labs !== null) {
      labs.forEach((el) => {
        // console.log(el);
        if (value === el.name) {
          dispatch(
            changeField({
              form: 'lab',
              key: 'lab',
              value: el,
            }),
          );
        }
      });
    }
  };

  const getLabList = async () => {
    try {
      // setLoading(true);
      await listLabs().then((res) => {
        if (res.data) {
          let labNames = [];
          res.data.dental_labs.forEach((el) => {
            if (el.user !== null) labNames.push(el.name);
          });
          setLabNames(labNames);
          setLabs(res.data.dental_labs);
        }
        // setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onCreateConnecton = () => {
    dispatch(initializeForm('page'));
    dispatch(initializeForm('lab'));
    dispatch(initializeForm('changefield'));
    setModalConnection(true);
    getLabList();
  };

  const onCancel = () => {
    setModalConnection(false);
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    setModalConnection(false);

    try {
      if (page1) {
        const { email, alias, message } = changefield;
        const res = await createConnection({
          email: email,
          alias: alias,
          message: message,
        });
      } else {
        const res = await createLabConnection({ labId: lab.id });
      }
      dispatch(listConnections(searchFilter));
    } catch (e) {
      if (e.response && e.response.data) showMessage(e.response.data.detail);
    }
  };

  const onClickRequestAgain = async (email, alias, text, id) => {
    try {
      const res = await requestConnection({
        email: email,
        alias: alias,
        message: text,
        id: id,
      });

      if (res.data.detail === 'request already accepted')
        setConfirmBoxContent(t('Request is already accepted'));
      // success
      else
        setConfirmBoxContent(
          t('A connection request was sent to abc@def.com').replace(/abc@def.com/g, email),
        );
      setConfirmBoxVisible(true);

      dispatch(listConnections(''));
      dispatch(listAlarms());
    } catch (e) {
      if (e.response && e.response.data) showMessage(e.response.data.detail);
    }
  };

  const showMessage = (e) => {
    // Tested
    if (e === 'email already added')
      setMessageContent(t('You already have a connection to this email address.'));
    else if (e === 'email same as yours') setMessageContent(t("Can't invite yourself"));
    else if (e === 'same group user') setMessageContent(t("Can't invite group member"));
    // Not tested yet
    else if (e === 'request email sent') setMessageContent(t('An Invitation has been sent'));
    else if (e === 'data invalid') setMessageContent(t('Data is invalid'));
    else if (e === 'request accepted') setMessageContent(t('Request is accepted'));
    else if (e === 'request already accepted') setMessageContent(t('Request is already accepted'));
    else if (e === 'invalid token') setMessageContent(t('Invalid token'));
    else if (e === 'request needed') setMessageContent(t('Request is needed'));
    else if (e === 'request rejected') setMessageContent(t('Request is rejected'));

    setMessageTitle(t('Error'));
    setMessageVisible(true);
  };

  const onMessageBoxOk = () => {
    setMessageVisible(false);
    if (token) history.push('/connections');
  };

  const onMessageBoxCancel = () => {
    setMessageVisible(false);
  };

  useEffect(() => {
    if (!userChecking && !token) {
      dispatch(listConnections(searchFilter));
    }
  }, [dispatch, userChecking, token]);

  useEffect(() => {
    if (acceptconn) {
      dispatch(listConnections(searchFilter));
      dispatch(initializeForm('acceptconn'));
    }
  }, [dispatch, acceptconn]);

  useEffect(() => {
    if (reject) {
      dispatch(listConnections(searchFilter));
      dispatch(initializeForm('reject'));
    }
  }, [dispatch, reject]);

  useEffect(() => {
    dispatch(initializeForm('createconn'));
  }, [dispatch, token, userChecking]);

  useEffect(() => {
    if (remove) {
      dispatch(listConnections(searchFilter));
      dispatch(initializeForm('remove'));
    }
  }, [dispatch, remove]);

  useEffect(() => {
    if (editconn) {
      dispatch(listConnections(searchFilter));
      dispatch(initializeForm('editconn'));
    }
  }, [dispatch, editconn]);

  /*
  useEffect(() => {
    if (request) {
      dispatch(initializeForm('request'));
      setMessageTitle('Info');
      setMessageContent('Sent invitation request!');
      setMessageVisible(true);
      dispatch(listConnections(searchFilter));
    }
  }, [dispatch, request]);
  */

  const showMessageAccept = (result, detail) => {
    if (result) {
      if (detail === 'request accepted') setMessageContent(t('Request is accepted'));
      else if (detail === 'request already accepted')
        setMessageContent(t('Request is already accepted'));
      setMessageTitle(t('Info'));
    } else {
      setMessageTitle(t('Warning'));
      if (detail === 'request needed') setMessageContent(t('Request is needed'));
      else if (detail === 'no email')
        setMessageContent(
          t(
            'Unable to verify if you are the requested user since your account ' +
              'does not have an email. If you have not verified your email ' +
              'on social media, please first complete the verification and ' +
              'then try again.',
          ),
        );
      else if (detail === 'no permission')
        setMessageContent(
          t(
            'Access denied because you are not the user with the requested email. Are you logged in with another account?',
          ),
        );
      else if (detail === 'no server location') setMessageContent(t('Invalid data'));
      else if (detail === 'server is wrong')
        setMessageContent(t('The users are from different regions: EU and non-EU.'));
      else if (detail === 403) setMessageContent(t('Access denied'));
      else {
        // error
        setMessageTitle(t('Error'));
        if (detail === 'invalid token') setMessageContent(t('Invalid token'));
        else setMessageContent(detail);
      }
    }
    setMessageVisible(true);
  };

  useEffect(() => {
    if (accept)
      showMessageAccept(
        accept.result,
        accept.result
          ? accept.detail
          : accept.error.response.data
          ? accept.error.response.data.detail
          : accept.error.response.status,
      );
  }, [accept]);

  const onSearchEnter = (inputText) => {
    if (inputText === '') {
      setSearchFilter(null);
      dispatch(listConnections(null));
    } else {
      setSearchFilter(inputText);
      dispatch(listConnections(inputText));
    }
  };

  if (error) {
    return (
      <div>
        {error.message} {error.detail}
      </div>
    );
  }

  return (
    <MainBlock>
      <div className={leftpanel.collapsed ? 'ConnectionsCollapsed' : 'ConnectionsNormal'}>
        {/* Titile */}
        <div className={'ConnectionsTitle'}>{t('Connections')}</div>

        {/* Search */}
        <div className={'ConnectionsSearch'}>
          <InputSearch
            size="Medium"
            placeholder={t('Filter connections by search')}
            width="355px"
            value=""
            onSearchEnterPress={onSearchEnter}
          />
        </div>

        {/* Button */}
        <div className={'ConnectionsButton'}>
          <HButton
            category="Primary"
            size="Medium"
            text={t('Create New Connection').toUpperCase()}
            width="206px"
            onClick={onCreateConnecton}
          />
        </div>

        <div className={'ConnectionsContent'}>
          {loading && (
            <div className={'loading'}>
              <Spinner />
            </div>
          )}

          {!loading && connections && (
            <div>
              {/* Pending */}
              <div className={'pendingTitle'}>{t('Pending Connections')}</div>

              <div>
                {connections.pending.map((connection) => (
                  <>
                    {(connection.is_owner || connection.Im_invited) && (
                      <ConnectionItem
                        connection={connection}
                        onClickRequestAgain={onClickRequestAgain}
                        searchFilter={searchFilter}
                        type={'pending'}
                        odm={odm}
                      />
                    )}
                  </>
                ))}
              </div>

              {/* Accepted */}
              <div className={'acceptedTitle'}>{t('Accepted Connections')}</div>

              <div>
                {connections.accepted.map((connection) => (
                  <ConnectionItem
                    connection={connection}
                    onClickRequestAgain={onClickRequestAgain}
                    searchFilter={searchFilter}
                    type={'accepted'}
                    odm={odm}
                  />
                ))}
              </div>

              {/* Locked */}
              {connections.locked && connections.locked.length > 0 && (
                <div>
                  <div className={'acceptedTitle'}>{t('Designated Connection')}</div>

                  <div>
                    {connections.locked.map((connection) => (
                      <ConnectionItem
                        connection={connection}
                        onClickRequestAgain={onClickRequestAgain}
                        searchFilter={searchFilter}
                        type={'locked'}
                        odm={odm}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={'ConnectionsBottom'}></div>

        <CreateConnectionBox
          user={user}
          connections={connections}
          page1={page1}
          lab={lab}
          labNames={labNames}
          visible={modalConnection}
          title={t('Create Connection')}
          form={changefield}
          onSubmit={onConfirm}
          onCancel={onCancel}
          onSelect={onSelect}
        />
        <MessageBox
          visible={messageVisible}
          type="ok"
          title={messageTitle}
          content={messageContent}
          onOk={onMessageBoxOk}
          onCancel={onMessageBoxCancel}
        />
      </div>
      <ConfirmBox visible={confirmBoxVisible} content={confirmBoxContent} onOk={onConfirmBoxOk} />
    </MainBlock>
  );
};

export default Connections;
