import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThisMonth from '../../components/dashboard/ThisMonth';
import {
  getCasesRestoration,
  getCasesStatus,
  getCasesActivity,
} from '../../modules/dashboard';
import { useTranslation } from 'react-i18next';
import { setFilters } from '../../modules/cases';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { useRefreshOnAuthError, useAutoRefreshEvent } from '../../lib/common';

import ActivityList from '../../components/dashboard/ActivityList';
import CasesStatus from '../../components/dashboard/CasesStatus';
import InputSelect from '../../components/common/InputSelect';
import Spinner from '../../components/common/Spinner';
import MessageBox from '../../components/common/MessageBox';
import Cookies from 'universal-cookie';
import HButton from '../../components/common/HButton';
import { getEventsToken } from '../../lib/api/auth';

const DashboardLayout = styled.div`
  display: grid;
  width: auto;
  height: 100%;
  max-width: ${(props) => props.maxWidth}; // 1184px;
  min-width: 1036px;

  grid-template-columns: 40px 1fr 40px;
  grid-template-rows: 64px 32px 16px 272px 64px 1fr 64px;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    '. TopMargin RightMargin'
    '. DashboardTitle RightMargin'
    '. . RightMargin'
    '. CaseStatus-ThisMonth .'
    '. . .'
    '. Activity-AskSupport .'
    '. . .';

  .RightMargin {
    grid-area: RightMargin;
  }
  .TopMargin {
    grid-area: TopMargin;
  }
  .DashboardTitle {
    margin-left: 8px;
    font-family: Source Sans Pro;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    grid-area: DashboardTitle;
  }

  .CaseStatus-ThisMonth {
    display: grid;
    grid-template-columns: 842fr 262fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'CaseStatus ThisMonth';
    grid-area: CaseStatus-ThisMonth;
  }

  .CaseStatus {
    grid-area: CaseStatus;
  }
  .ThisMonth {
    min-width: 262px;
    max-width: 309px;
    grid-area: ThisMonth;
  }

  .Activity-AskSupport {
    display: grid;
    grid-template-columns: 729fr 21px 353fr;
    //grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'Activity Space5 AskSupportArea';
    grid-area: Activity-AskSupport;
  }

  .Activity {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 40px 8px 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'ActivityTitle-DayFilter ActivityTitle-DayFilter'
      'Space4 Space4'
      'ActivityList ActivityList';
    grid-area: Activity;
  }

  .ActivityTitle-DayFilter {
    display: grid;
    grid-template-columns: 1fr 167px;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'ActivityTitle DayFilter';
    grid-area: ActivityTitle-DayFilter;
  }

  .Space4 {
    grid-area: Space4;
  }

  .ActivityTitle {
    margin-left: 8px;
    font-family: Source Sans Pro;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    grid-area: ActivityTitle;
  }

  .DayFilter {
    margin-right: 21px;
    grid-area: DayFilter;
  }

  .ActivityList {
    grid-area: ActivityList;
  }

  .Download3DiscLab {
    margin-top: 152px;
    height: 72px;
    border-radius: 4px;
    background-color: #f5f6f6;
    grid-area: Download3DiscLab;
    cursor: pointer;
  }

  .Documentation3Disc {
    cursor: pointer;
    margin-top: 11px;
    height: 72px;
    background-color: #f5f6f6;
    /* background-color: white; */
    grid-area: Documentation;
  }

  .Documentation3DiscTitle {
    position: absolute;
    width: 300px;
    margin-left: 20px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-overflow: ellipsis;
    color: #59616a;
  }

  .AskSupportArea {
    margin-top: 48px;
    height: 200px; /* sum height 240px */
    background-color: transparent;
    border-radius: 4px;

    display: grid;
    position: relative;
    grid-template-rows: 1fr 18px 1fr;
    gap: 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'Documentation'
      'AskSupport'
      'Download3DiscLab';
    grid-area: AskSupportArea;
  }

  .AskSupport {
    position: relative;
    height: 150px;
    background-color: #f5f6f6;
    grid-area: AskSupport;
    margin-top: 10px;
  }

  .AskSupport a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-decoration: none;
  }

  .AskSupportTitle {
    position: absolute;
    width: 300px;
    /* height: 100px; */
    margin-left: 20px;
    margin-top: 20px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-overflow: ellipsis;
    color: #59616a;
  }

  .AskSupportDesc {
    position: absolute;
    width: 210px;
    /* height: 100px; */
    margin-left: 20px;
    margin-top: 70px;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #59616a;
  }

  .AskTitle {
    position: absolute;
    top: -44px;
    font-family: Source Sans Pro;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }
`;

const LoadMoreButton = styled.button`
  display: block;
  width: auto;
  min-width: 95px;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background-color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #f5f6f6;
  }
  &:active {
    border: solid 1px ${(props) => props.theme.main_color};
    background-color: ${(props) => props.theme.main_color_focus};
  }

  .Text {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.2px;
    text-align: center;
    color: ${(props) => props.theme.main_color};
  }
`;

const ErrorLoadingBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || '160px'};

  font-family: Source Sans Pro;
  font-size: 15px;
  color: #59616a;
`;

const DashboardContainer = (props) => {
  const dispatch = useDispatch();

  const {
    filter,
    user_checking,
    casesStatus,
    casesRestorations,
    casesActivities,
    error_status,
    error_resto,
    error_activity,
    loading_status,
    loading_resto,
    loading_activity,
    collapsed,
    user,
    userSettings,
    odm,
    app,
  } = useSelector(({ cases, dashboard, loading, leftpanel, user, app }) => ({
    filter: cases.filters,
    user_checking: loading['user/CHECK'],
    loading_status: loading['dashboard/CASESSTATUS'],
    casesStatus: dashboard.casesStatus,
    loading_resto: loading['dashboard/CASESRESTORATION'],
    casesRestorations: dashboard.casesRestorations,
    loading_activity: loading['dashboard/CASESACTIVITY'],
    casesActivities: dashboard.casesActivities,
    error_status: dashboard.error_status,
    error_resto: dashboard.error_resto,
    error_activity: dashboard.error_activity,
    collapsed: leftpanel.collapsed,
    user: user.user,
    userSettings: user.settings,
    odm: app.odm,
    app: app.app,
  }));

  const { t } = useTranslation();

  const download3DiscLab = t('Download 3DiscLab');
  const index = download3DiscLab.indexOf(t('3Disc'));

  const [loadedPageNum, setLoadedPageNum] = useState(1);
  const [actUsers, setActUsers] = useState([]);
  const [actList, setActList] = useState([]);
  const [actPeriod, setActPeriod] = useState('thisweek');
  const [restoPeriod, setRestoPeriod] = useState('thisweek');
  const [requestActivity, setRequestActivity] = useState(false);
  const telnumber = odm.company_name === 'vatech' ? '244 - 2076' : '570 – 0363';

  const [lastSelectedAct, setLastSelectedAct] = useState(t('Work week'));

  //MessageBox
  const [messageTitle, setMessageTitle] = useState('');
  const [messageType, setMessageType] = useState('ok');
  const [messageVisible, setMessageVisible] = useState(false);
  const [messageContent, setMessageContent] = useState('');

  var selectedAct = 'Work week';
  var selectedResto = 'Work week';

  const event = useAutoRefreshEvent();

  const onConfirmBoxOk = (e, callback) => {
    callback();
    setMessageVisible(false);
  };

  const onCancel = () => {
    setMessageVisible(false);
  };

  const getCookies = (name) => {
    const cookies = new Cookies();
    const cookie = cookies.get(name);
    return cookie;
  };

  const onClickDownload3DiscLab = async () => {
    if (user.inst_type_guessed !== 'Clinic') {
      try {
        await getEventsToken({ callFrom: 'download' });
        const uidb64 = user.idb64;
        const uri = `/heclore/v1/3disclab/install/download/${uidb64}/`;
        // setDownloadAllDisabled(false); // reset
        window.location.href = uri;
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (!user_checking && user.inst_type_guessed === 'Lab' && app.server_loc === 'EU') {
      if (!getCookies('hide')) {
        setMessageContent(
          t(
            'For labs with customers in Europe note that until Clinics/Doctors you are working with have updated their region/country please continue to check the US and EU pages to see all cases that have been sent',
          ),
        );
        setMessageTitle(t('Info'));
        setMessageType('ok');
        setMessageVisible(true);
      }
    }
  }, [user_checking]);

  useEffect(() => {
    if (event) {
      const msg = JSON.parse(event);
      if (!window.actionIDs.has(msg.action_id)) {
        if (
          msg.id === 'post_published' ||
          msg.id === 'post_uploaded' ||
          msg.id === 'post_modified' ||
          msg.id === 'post_status_modified' ||
          msg.id === 'posts_deleted' ||
          msg.id === 'files_uploaded' ||
          msg.id === 'file_deleted' ||
          msg.id === 'comment_added' ||
          msg.id === 'comment_modified' ||
          msg.id === 'comment_deleted'
        ) {
          setActUsers([]);
          setActList([]);
          if (msg.id === 'post_published' || msg.id === 'post_status_modified')
            dispatch(getCasesStatus());
          dispatch(getCasesActivity({ period: actPeriod }));
          setRequestActivity(true);
        }
      }
    }
  }, [event]);

  useEffect(() => {
    if (!user_checking) {
      dispatch(getCasesStatus());
    }
  }, [user_checking]);

  useEffect(() => {
    if (!user_checking) {
      dispatch(getCasesActivity({ period: actPeriod, page: loadedPageNum }));
      setRequestActivity(true);
    }
  }, [user_checking, loadedPageNum, actPeriod]);

  useEffect(() => {
    if (!user_checking) {
      dispatch(getCasesRestoration({ period: restoPeriod }));
    }
  }, [user_checking, restoPeriod]);

  function onLoadMore() {
    setLoadedPageNum(loadedPageNum + 1);
  }

  function onActivityFilter(item) {
    //item = item.split(' ')[1];
    selectedAct = item;
    setLastSelectedAct(item);
    // item = item.replace(' ', '').toLowerCase();

    if (item === t('Work week')) setActPeriod('thisweek');
    else if (item === t('Last 7 days')) setActPeriod('week');
    else if (item === t('Work month')) setActPeriod('thismonth');
    else if (item === t('Last 30 days')) setActPeriod('month');

    setActList([]);
    setActUsers([]);
    setLoadedPageNum(1);

    if (lastSelectedAct === item) {
      dispatch(getCasesActivity({ period: actPeriod, page: loadedPageNum }));
      setRequestActivity(true);
    }
  }

  function onRestorationFilterChanged(item) {
    selectedResto = item;
    //item = item.replace(' ', '').toLowerCase();

    if (item === t('Work week')) setRestoPeriod('thisweek');
    else if (item === t('Last 7 days')) setRestoPeriod('week');
    else if (item === t('Work month')) setRestoPeriod('thismonth');
    else if (item === t('Last 30 days')) setRestoPeriod('month');
  }

  function statusCardClicked(id) {
    filter.status = [id];
    filter.searchString = '';
    dispatch(setFilters(filter));
  }

  function findSame(arr, c_arr) {
    return (
      arr.find((x) => {
        return c_arr.find((el) => {
          return el.action_date === x.action_date;
        });
      }) !== undefined
    );
  }

  if (requestActivity && !loading_activity && casesActivities) {
    if (
      casesActivities.users.length &&
      findSame(casesActivities.users, actUsers) === false
    ) {
      setActUsers(actUsers.concat(casesActivities.users));
    }

    if (
      casesActivities.cases_actions.length &&
      findSame(casesActivities.cases_actions, actList) === false
    ) {
      setActList(actList.concat(casesActivities.cases_actions));
    }

    setRequestActivity(false);
  }

  const getContents = () => {
    return <></>;
  };

  useRefreshOnAuthError();

  return (
    <DashboardLayout maxWidth={props.collapsed ? '1376px' : '1184px'}>
      <div className="DashboardTitle"> {t('Dashboard')} </div>
      <div className="CaseStatus-ThisMonth">
        <div className="CaseStatus">
          {error_status ? (
            <>
              <ErrorLoadingBlock>{error_status}</ErrorLoadingBlock>
            </>
          ) : (
            <>
              {!casesStatus ? (
                <ErrorLoadingBlock>
                  <Spinner />
                </ErrorLoadingBlock>
              ) : (
                <CasesStatus status={casesStatus} clicked={statusCardClicked} />
              )}
            </>
          )}
        </div>
        <div className="ThisMonth">
          {error_resto ? (
            <>
              <ErrorLoadingBlock>{error_resto}</ErrorLoadingBlock>
            </>
          ) : (
            <>
              {!casesRestorations ? (
                <ErrorLoadingBlock>
                  <Spinner />
                </ErrorLoadingBlock>
              ) : (
                <ThisMonth
                  restos={casesRestorations.restoration_counts}
                  onFilterChanged={onRestorationFilterChanged}
                  period={selectedResto}
                  loading={loading_resto}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="Activity-AskSupport">
        <div className="Acitivity">
          <div className="ActivityTitle-DayFilter">
            <div className="ActivityTitle">{t('Activity')}</div>
            <div className="DayFilter">
              <InputSelect
                items={[
                  t('Work week'),
                  t('Last 7 days'),
                  t('Work month'),
                  t('Last 30 days'),
                ]}
                onSelect={onActivityFilter}
                placeholder=""
                width="167px"
                height="40px"
                selItem={selectedAct}
                reset="0"
              />
            </div>
          </div>
          <div className="Space4" />
          <div className="ActivityList">
            {error_activity ? (
              <>
                <ErrorLoadingBlock>{error_activity}</ErrorLoadingBlock>
              </>
            ) : (
              <>
                {!casesActivities ? (
                  <ErrorLoadingBlock>
                    <Spinner />
                  </ErrorLoadingBlock>
                ) : (
                  <>
                    {loading_activity === false && actList.length == 0 ? (
                      <>
                        <ErrorLoadingBlock>{t('No activity yet')}</ErrorLoadingBlock>
                      </>
                    ) : (
                      <>
                        {loading_activity ? (
                          <ErrorLoadingBlock>
                            <Spinner />
                          </ErrorLoadingBlock>
                        ) : (
                          <>
                            <ActivityList list={actList} users={actUsers} />
                            {casesActivities.num_pages > loadedPageNum && (
                              <LoadMoreButton
                                type="button"
                                onClick={(e) => onLoadMore()}
                              >
                                <div className="Text">
                                  {' '}
                                  {t('Load More').toUpperCase()}{' '}
                                </div>
                              </LoadMoreButton>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="AskSupportArea">
          <div className="AskTitle">Can we help you ?</div>

          <div className="Documentation3Disc">
            <a
              href={
                user.inst_type_guessed !== 'Clinic'
                  ? 'https://docs.3disccloud.com/3disc-cloud/3disclab/index.html'
                  : 'https://docs.3disccloud.com/3disc-cloud/index.html'
              }
              target="_blank"
            >
              <img
                src={process.env.PUBLIC_URL + '/images/dashboard/help3disccloud.svg'}
                style={{
                  float: 'right',
                  width: '100%',
                  height: '100%',
                }}
              ></img>

              <div className="Documentation3DiscTitle">
                {user.inst_type_guessed !== 'Clinic' ? (
                  <div style={{ marginTop: '9px' }}>
                    How to use <span style={{ color: '#FF6D22' }}>3Disc</span>Cloud{' '}
                    <br /> for Labs
                  </div>
                ) : (
                  <div style={{ marginTop: '9px' }}>
                    {/* Visit <span style={{ color: '#FF6D22' }}>3Disc</span> Online Help
                    for <br /> <span style={{ color: '#FF6D22' }}>more </span>
                    information */}
                    How to use <span style={{ color: '#FF6D22' }}>3Disc</span>Cloud{' '}
                    <br /> for Dental Clinics
                  </div>
                )}
              </div>
            </a>
          </div>

          <div className="AskSupport">
            <a href="https://3disc.com/contact/?s=4" target="_blank">
              <img
                src={process.env.PUBLIC_URL + '/images/dashboard/AskSupport_Image.svg'}
                style={{ float: 'right' }}
              ></img>

              <div className="AskSupportTitle">{t('Ask Support')}</div>
              <div className="AskSupportDesc">
                {app?.server_loc === 'EU'
                  ? t(
                      'Please submit a support request or call us at + 33 (1) 86 65 68 93',
                    )
                  : t(
                      'Please submit a support request or call us at + 1 (800) 570 – 0363',
                    ).replace(/570 – 0363/g, telnumber)}
              </div>
            </a>
          </div>

          {user.inst_type_guessed !== 'Clinic' && (
            <div
              className="Download3DiscLab"
              onClick={(e) => {
                e.preventDefault();
                onClickDownload3DiscLab();
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL + '/images/dashboard/bg-3disclab-banner.svg'
                }
                style={{ float: 'right' }}
              ></img>

              <div className="AskSupportTitle">
                {download3DiscLab.substring(0, index)}
                <span style={{ color: '#FF6D22' }}>{t('3Disc')}</span>
                {download3DiscLab.substring(index + t('3Disc').length)}
              </div>
            </div>
          )}
        </div>
      </div>

      <MessageBox
        visible={messageVisible}
        type={messageType}
        title={messageTitle}
        content={messageContent}
        onOk={onConfirmBoxOk}
        onCancel={onCancel}
        expire={true}
      />
    </DashboardLayout>
  );
};

export default DashboardContainer;
