import React, { useState, useEffect } from 'react';
import InputSearch from '../../components/common/InputSearch';
import styled from 'styled-components';
import InputSelect from '../common/InputSelect';
import CaseItem from './CaseItem';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setFilters, userFilter, listCases } from '../../modules/cases';
import { deleteCases } from '../../lib/api/cases';
import { useTranslation } from 'react-i18next';
import { caseInfo, fullScreen, initializeForm } from '../../modules/case';
import Filters from '../../components/cases/Filters';
import Spinner from '../common/Spinner';
import MessageBox from '../common/MessageBox';
import {
  downloadFileList,
  setDownloadFileList,
  downloadCancel,
} from '../../lib/api/case';
import SVG from '../common/svg';

import { TabletOrDesktop } from '../../lib/responsive';
import HButton from '../common/HButton';

const MobileCaseListMainBox = styled.div`
  .CaseFilterboxContainer {
    height: 56px;
    display: grid;
    grid-template-columns: 56px 48px 48px 48px auto 180px;
    grid-template-rows: 56px;
    gap: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeff0;
  }

  .SearchCaseFilterboxContainer {
    height: 56px;
    display: grid;
    grid-template-columns: 20px auto 8px 89px 19px;
    grid-template-rows: 56px;
    gap: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeff0;
  }

  .SearchBar {
    grid-column: 2;
    grid-row: 1; 
    margin: 0px;
    align-self: center;
    justify-self: left;
    height: 40px;
    width: -webkit-fill-available;
  }

  .SearchCancel {
    grid-column: 4;
    grid-row: 1;
    margin:0px;
    align-self: center;
    justify-self: left;
    height: 40px;
  }

  .Filter_checkbox {
    grid-column: 1;
    grid-row: 1;
    margin: 0px;
    align-self: center;
    justify-self: center;
    height: 24px;
  }

  .Filter_Button {
    grid-column: 2;
    grid-row: 1;
    margin: 0px;
    align-self: center;
    justify-self: center;
    height: 24px;
  }

  .Search_Button {
    grid-column: 3;
    grid-row: 1;
    margin: 0px;
    align-self: center;
    justify-self: center;
    height: 24px;
  }

  .Refresh_Button {
    grid-column: 4;
    grid-row: 1;
    margin: 0px;
    align-self: center;
    justify-self: center;
    height: 24px;
  }

  .Delete_Button {
    grid-column: 6;
    grid-row: 1;
    margin-right: 20px;
    align-self: center;
    justify-self: right;
    height: 24px;
  }

  .Combo_Sort {
    grid-column: 6;
    grid-row: 1;
    margin-right: 20px;
    align-self: center;
    justify-self: right;
  }

  .checkbox {
    display: flex;
    width: 100%;
    flex-basis: 24px;
    margin: 20px 20px;
    align-items: center; //vertical center
  }

  input[type='checkbox'] {
    background-image: url('${
      process.env.PUBLIC_URL
    }/images/settings/icon-24-checkbox-active.svg');
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    margin: 0;
    outline: none;

    &:checked {
      background-image: url('${process.env.PUBLIC_URL}/${(props) =>
  props.theme.images}/settings/icon-24-checkbox-selected.svg');
    }
`;

const CaseListMainBox = styled.div`
  border-right: solid 1px #eeeff0;

  .CaseFilterboxContainer {
    height: 50px;
    display: grid;
    grid-template-columns: 72px 49px auto 180px;
    grid-template-rows: 50px;
    gap: 0;
    background-color: #ffffff;
    border-bottom: solid 1px #eeeff0;
  }

  .Icon_checkbox {
    grid-column:1;
    grid-row:4;
    margin:0px;
    align-self:start;
    justify-self:center;
  }

  .Filter_checkbox {
    grid-column: 1;
    grid-row: 1;
    margin: 0px;
    align-self:center;
    justify-self:center;
    height: 24px;
  }

  .Filter_Button {
    grid-column: 2;
    grid-row: 1;
    margin: 0px;
    align-self: center;
    justify-self: left;
    height: 24px;
  }

  .Refresh_Button {
    grid-column: 3;
    grid-row: 1;
    margin: 0px;
    align-self: center;
    justify-self: left;
    height: 24px;
  }

  .Delete_Button {
    grid-column: 4;
    grid-row: 1;
    margin-right: 20px;
    align-self: center;
    justify-self: right;
    height: 24px;
  }

  .Combo_Sort {
    grid-column: 4;
    grid-row: 1;
    margin-right: 20px;
    align-self: center;
    justify-self: right;
  }

  .CaseList_CaseListBox {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    background: #ffff;
    border-left: solid 1px #eeeff0;
  }

  .CaseList_Pagenation {
    width: 448px;
    align-self: end;
    justify-self: end;    

    padding: 0px -6px 0px 1px;
    border-top: solid 1px #eeeff0;
    border-left: solid 1px #eeeff0;
    border-right: solid 1px #eeeff0;

//    position: fixed;
    bottom: 0;
  }
  .CaseList_NoItemBox {
    align-self: center;
    justify-self: center;    
  }

  .checkbox {
    display: flex;
    width: 100%;
    flex-basis: 24px;
    margin: 20px 20px;
    align-items: center; //vertical center
  }

  input[type='checkbox'] {
    background-image: url('${
      process.env.PUBLIC_URL
    }/images/settings/icon-24-checkbox-active.svg');
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 24px;
    height: 24px;
    margin: 0;
    outline: none;

    &:checked {
      background-image: url('${process.env.PUBLIC_URL}/${(props) =>
  props.theme.images}/settings/icon-24-checkbox-selected.svg');
    }
`;

const CaseList_CaseListBlock = styled.div`
  .myScrollbar {
    height: 627px;
    overflow-y: auto;
    //    background: #ffffff;
    background: #eeeff0;
  }

  .myScrollbar::-webkit-scrollbar {
    width: 8px;
  }

  .myScrollbar::-webkit-scrollbar-thumb {
    background-color: #c0c3c7;
    border-radius: 4px;
    background-clip: padding-box;
    border: 1px solid transparent;
  }

  .myScrollbar::-webkit-scrollbar-track {
    //    background-color: #aaaaaa;
    /* box-shadow: inset 0px 0px 5px white; */
  }
`;

const Loading = styled.div`
  width: 100%;
  height: auto;

  .loading {
    margin-top: 50%;
    text-align: center;
    width: 100%;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59646a;
  }

  .mobile_loading {
    margin-top: 50%;
    margin-bottom: 50%;
    text-align: center;
    width: 100%;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59646a;
  }
`;

/* const CaseList = ({ cases, loading, error, filterChecking, userFilter }) => { */
const CaseList = ({
  cases,
  loading,
  error,
  filters,
  filterChecking,
  page,
  del_disabled_all,
  selectedID,
  _case,
  popCount,
  prevLocRef,
  history,
  location,
  match,
}) => {
  //  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { form, content, odm } = useSelector(({ cases, caseDetail, app }) => ({
    form: cases.filters,
    //      leftpanel: leftpanel,
    content: caseDetail.content,
    odm: app.odm,
  }));
  const [modalFilter, setModalFilter] = useState(false);
  const [checkedInputs, setCheckedInputs] = useState([]);
  const [selectedCase, setSelectedCase] = useState('');
  //  const [searchFilter, setSearchFilter] = useState(null);

  const [isAllChecked, setAllChecked] = useState(false);
  const [isAnyChecked, setAnyChecked] = useState(false);
  const [isSearchChecked, setSearchChecked] = useState(false);

  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  const isTabletOrDesktop = TabletOrDesktop();

  /* useEffect(() => {
    alert('CaseList: ' + isTabletOrDesktop);
  }, [isTabletOrDesktop]); */

  //  const onRemoveClick = () => {
  //    setModal(true);
  //  };
  //  const onCancel = () => {
  //    setModal(false);
  //  };
  //  const onConfirm = () => {
  //    setModal(false);
  //  };

  /* select it from the list of cases if exists */
  const selectCase = (_case, cases) => {
    if (_case && _case.no && cases) {
      const sel_case = cases.filter((c) => c.no === _case.no);
      if (0 < sel_case.length) {
        setSelectedCase(sel_case[0].id);
        return true;
      }
    }
    return false;
  };

  // reload case when history back and forward
  useEffect(() => {
    if (location.pathname) {
      // const items = location.pathname.split('/');
      // const _case = { no: items[items.length - 1] };

      // dispatch(caseInfo(_case));
      if (location.state || (prevLocRef.current && prevLocRef.current.state)) {
        const filtersStr = location.state
          ? JSON.stringify(location.state.filters)
          : null;
        const prevFiltersStr = prevLocRef.current.state
          ? JSON.stringify(prevLocRef.current.state.filters)
          : null;
        if (filtersStr !== prevFiltersStr) {
          dispatch(setFilters(location.state.filters));
        }
        // else if (location.state.page !== prevLocRef.current.state.page) {
        //   dispatch(listCases({ filters, page: location.state.page }));
        // } else selectCase(_case, cases);
      } // else selectCase(_case, cases);
    }

    prevLocRef.current = {
      pathname: location.pathname,
      search: location.search,
      state: location.state,
    };
  }, [popCount]);

  // cases refreshed
  useEffect(() => {
    if (!isTabletOrDesktop) {
      dispatch(initializeForm('content'));
      window.scrollTo(0, 0);
      return;
    }

    // cases exists without loading
    if (!loading && cases && cases.length > 0) {
      selectCase(_case, cases);
    }
    // cases exists after loading
    else if (cases && cases.length > 0) {
      let selectFirst = false;
      if (_case) {
        // from URL
        if (!content) {
          dispatch(caseInfo({ no: match.params.caseNo }));
          selectCase(_case, cases);
        }
        // from filter, search or refresh
        // case detail already exists
        else if (!selectCase(_case, cases)) selectFirst = true;
      }
      // no case specified
      else selectFirst = true;

      // When no case is specified, the first case is selected
      if (selectFirst) {
        setSelectedCase(cases[0].id);
        dispatch(caseInfo({ id: cases[0].id }));
        // if filter changed
        if (location.pathname === '/cases') {
          history.replace(`/cases/${cases[0].no}`, {
            filters,
            page: page.number,
          });
        }
        // if page changed
        else history.push(`/cases/${cases[0].no}`, { filters, page: page.number });
      }
    }
    // no case after loading
    else if (cases && cases.length === 0) {
    }
  }, [dispatch, cases]);

  const changeHandler = (checked, id) => {
    //    var caseNum = 0;
    var chekedNum = checkedInputs.length;

    if (checked) {
      setCheckedInputs([...checkedInputs, id]);
      chekedNum++;
    } else {
      setCheckedInputs(checkedInputs.filter((el) => el !== id));
      chekedNum--;
    }

    var resultArray = cases.filter((_case) => _case.del_enabled === true);

    setAllChecked(resultArray.length === chekedNum ? true : false);
    setAnyChecked(chekedNum > 0 ? true : false);
  };

  const onAllCardCheck = (cases, checked) => {
    var _checkInputs = [];
    //    var EnabledCheckBoxNum = 0;

    if (checked) {
      cases.map(
        (_case) =>
          (_checkInputs = _case.del_enabled
            ? _checkInputs.concat(_case.id)
            : _checkInputs),
      );
      setCheckedInputs(_checkInputs);
    } else setCheckedInputs(checkedInputs.filter((el) => el === ''));

    //    var caseNum = cases.length;
    var chekedNum = _checkInputs.length;

    setAllChecked(checked ? true : false);
    setAnyChecked(chekedNum > 0 ? true : false);
  };

  //  const onChanges = () => {
  //    console.log('>> on Change Event.');
  //  };

  const onFilterClicked = (status) => {
    setAnyChecked(false);
    setCheckedInputs([]);
    setModalFilter(true);
    console.log(form);
    dispatch(userFilter());
  };

  const onSearchClicked = () => {
    setSearchChecked(true);
  };

  const onSearchCancelClicked = () => {
    setSearchChecked(false);
  };

  const onRefreshClicked = (status) => {
    dispatch(listCases({ filters }));
  };

  const onSortSelect = (_orderBy) => {
    filters.orderBy = _orderBy;
    dispatch(setFilters(filters));
  };

  const onMessageBoxOk = (e) => {
    onDeleteCases(e);
    setAnyChecked(false);
    setMessageVisible(false);
  };

  const onMessageBoxCancel = () => {
    setMessageVisible(false);
  };

  const onConfirmDelete = (e) => {
    setMessageVisible(true);
    setMessageTitle(t('Warning'));
    setMessageContent(t('Delete checked case(s)?'));
  };

  const onDeleteCases = async (e) => {
    console.log('>> Delete Cases');
    console.log(checkedInputs);
    e.currentTarget.src = DeleteCaseIconS;

    /* dispatch(deleteCases({ ids: checkedInputs })); */
    try {
      await deleteCases({ ids: checkedInputs });
      dispatch(listCases({ filters, page: page.number }));
    } catch (e) {
      console.log(e);
    }
  };

  const onSearchEnter = (inputText) => {
    if (inputText || (filters.searchString && !inputText)) {
      filters.searchString = inputText;
      dispatch(setFilters(filters));
      history.push('/cases', { filters });
    }
  };

  function isFiltersOn(filters) {
    if (
      filters.createDateFrom === null &&
      filters.createDateTo === null &&
      filters.person === 'All' &&
      filters.status.length === 0 &&
      filters.institution === 'All'
    )
      return false;
    else return true;
  }

  function onFilterClosed(apply) {
    if (apply) {
      try {
        localStorage.setItem('caseFilters', JSON.stringify(form));
      } catch (e) {
        console.log(e);
      }

      console.log(form);

      dispatch(setFilters(form));
      history.push('/cases', { filters: form });
    } else {
      var data = JSON.parse(localStorage.getItem('caseFilters'));
      if (data !== null) {
        if (data.createDateFrom !== null)
          data.createDateFrom = new Date(data.createDateFrom);
        if (data.createDateTo !== null) data.createDateTo = new Date(data.createDateTo);
        form.status = data.status;
        form.createDateFrom = data.createDateFrom;
        form.createDateTo = data.createDateTo;
        form.person = data.person;
        form.institution = data.institution;
        //dispatch(setFilters(data));
      }
    }

    setModalFilter(false);
  }

  //  const checkIcon =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-checkbox-active.svg';

  //  const filterIconA =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-filters-active.svg';
  //  const filterIconS =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-filters-selected.svg';
  //  const filterIconH =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-filters-hover.svg';

  //  const DeleteCaseIconA =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-delete-active.svg';
  //  const DeleteCaseIconH =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-delete-hover.svg';
  const DeleteCaseIconS =
    process.env.PUBLIC_URL + '/images/common/icon-24-delete-selected.svg';

  //  const RefreshIconA =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-refresh-active.svg';
  //  const RefreshIconS =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-refresh-selected.svg';
  //  const RefreshIconH =
  //    process.env.PUBLIC_URL + '/images/common/icon-24-refresh-hover.svg';

  //  const leftpanel = useSelector(state => state.leftpanel);

  if (error) {
    return (
      <div>
        <p>{error.message}</p>
        <p>{error.stack}</p>
      </div>
    );
  }

  const sortString = ['Last Updated', 'Order Sent', 'Status']; // HCD-82
  //  var firstCaseID = '';

  return (
    <>
      {isTabletOrDesktop && (
        <CaseListMainBox>
          <div>
            <div className={'CaseList_CaseListBox'}>
              <div class="row" style={{ width: '100%' }}>
                <InputSearch
                  size="Medium"
                  placeholder={t('Filter by search')}
                  width="calc(100% - 40px - 2px)"
                  float="left"
                  value={filters.searchString !== '' ? filters.searchString : ''}
                  inputText={filters.searchString !== '' ? filters.searchString : ''}
                  margin="16px 20px 16px 20px"
                  onSearchEnterPress={onSearchEnter}
                />
              </div>
              <div className="CaseFilterboxContainer">
                {!del_disabled_all && (
                  <div className="Filter_checkbox" title={t('Select all cases')}>
                    <input
                      type="checkBox"
                      name="checkBoxCheckedAll"
                      checked={isAllChecked}
                      onChange={(e) => {
                        onAllCardCheck(cases, e.currentTarget.checked);
                      }}
                    />
                  </div>
                )}

                <div
                  className={'Filter_Button'}
                  onClick={onFilterClicked}
                  title={t('Display filter options')}
                >
                  <SVG
                    name="filter"
                    color={isFiltersOn(filters) ? odm.main_color : '#878D93'}
                    colorOver={odm.main_color_hover}
                    colorOut={isFiltersOn(filters) ? odm.main_color : '#878D93'}
                    colorDown={odm.main_color}
                  />
                </div>

                {
                  //           <img
                  //              className={'Filter_Button'}
                  //              src={isFiltersOn(filters) ? filterIconS : filterIconA}
                  //              onClick={onFilterClicked}
                  //              onMouseOver={(e) => (e.currentTarget.src = filterIconH)}
                  //              onMouseLeave={(e) =>
                  //              (e.currentTarget.src = isFiltersOn(filters)
                  //                ? filterIconS
                  //                : filterIconA)
                  //              }
                  //            />
                }
                <div
                  className={'Refresh_Button'}
                  onClick={onRefreshClicked}
                  title={t('Refresh')}
                >
                  <SVG
                    name="refresh"
                    color="#878D93"
                    colorOver={odm.main_color_hover}
                    colorOut="#878D93"
                    colorDown={odm.main_color}
                  />
                </div>
                {
                  //            <img
                  //              className={'Refresh_Button'}
                  //              src={RefreshIconA}
                  //              onClick={onRefreshClicked}
                  //              onMouseOver={(e) => (e.currentTarget.src = RefreshIconH)}
                  //              onMouseLeave={(e) => (e.currentTarget.src = RefreshIconA)}
                  //            />
                }
                {!loading && cases && isAnyChecked && (
                  <div
                    className={'Delete_Button'}
                    onClick={(e) => onConfirmDelete(e)}
                    title={t('Delete selected case(s)')}
                  >
                    <SVG
                      name="delete"
                      color="#878D93"
                      colorOver={odm.main_color_hover}
                      colorOut="#878D93"
                      colorDown={odm.main_color}
                    />
                  </div>
                )}

                {!loading && cases && !isAnyChecked && (
                  <div className={'Combo_Sort'}>
                    <InputSelect
                      placeholder={t('Last Updated')}
                      size="Medium"
                      width="150px"
                      height="40px"
                      items={sortString}
                      border={'none'}
                      onSelect={onSortSelect}
                      selItem={filters.orderBy}
                    />
                  </div>
                )}
              </div>
              <div />
              {/*console.log(filters)*/}
              <CaseList_CaseListBlock>
                <div
                  className="myScrollbar"
                  style={{ height: 'calc(100vh - 74px - 50px - 49px)' }}
                >
                  {loading && (
                    <Loading>
                      <div className={'loading'}>
                        <Spinner />
                      </div>
                    </Loading>
                  )}

                  {!loading && cases && cases.length > 0 && (
                    <div>
                      {cases.map((_case) => (
                        <CaseItem
                          key={_case.id}
                          _case={_case}
                          content={content}
                          isCheckVisible={!del_disabled_all}
                          selected={selectedCase === _case.id ? true : false}
                          checked={checkedInputs.includes(_case.id) ? true : false}
                          onChange={(value, id) => {
                            changeHandler(value, _case.id);
                          }}
                          onSelectCase={() => {
                            if (selectedCase !== _case.id) {
                              if (Object.keys(downloadFileList).length !== 0) {
                                /* downloadCancel('Operation canceled by the user.'); */
                                downloadCancel.forEach((cancel, i, arr) => {
                                  cancel('Operation canceled by the user.');
                                });
                                downloadCancel.length = 0;
                                setDownloadFileList({});
                              }
                              /* dispatch(caseInfo(_case.id)); */
                              dispatch(caseInfo({ id: _case.id }));
                              dispatch(initializeForm('file3D'));
                              dispatch(initializeForm('file3DName'));
                              dispatch(initializeForm('fileCount'));
                              setSelectedCase(_case.id);
                              history.push(`/cases/${_case.no}`, {
                                filters,
                                page: page.number,
                              });
                            }
                          }}
                        />
                      ))}
                    </div>
                  )}

                  {!loading && cases && cases.length === 0 && (
                    <Loading>
                      <div className={'loading'}>
                        {t('No cases available for this status')}
                      </div>
                    </Loading>
                  )}
                </div>
              </CaseList_CaseListBlock>
            </div>
            {/*}        
        <div className={'CaseList_Pagenation'}>
          <PaginationContainer page={page} lastPage={lastPage} />
          {console.log(lastPage)}
        </div>
*/}
          </div>
          <div className={'CaseList_LeftSpace'}></div>
          {
            <div>
              <Filters
                visible={modalFilter}
                formData={form}
                userFilter={userFilter}
                onModalClose={onFilterClosed}
              />
            </div>
          }
          <MessageBox
            visible={messageVisible}
            type="okcancel"
            title={messageTitle}
            content={messageContent}
            onOk={onMessageBoxOk}
            onCancel={onMessageBoxCancel}
          />
        </CaseListMainBox>
      )}
      {!isTabletOrDesktop && (
        <MobileCaseListMainBox>
          <div
            className={
              isSearchChecked
                ? 'SearchCaseFilterboxContainer'
                : 'CaseFilterboxContainer'
            }
          >
            {isSearchChecked && (
              <>
                <div className="SearchBar">
                  <InputSearch
                    size="Medium"
                    placeholder={t('Filter by search')}
                    /* width="calc(100% - 40px - 2px)" */
                    /*width="calc(100%)"*/
                    float="left"
                    value={filters.searchString !== '' ? filters.searchString : ''}
                    inputText={filters.searchString !== '' ? filters.searchString : ''}
                    /* margin="16px 20px 16px 20px" */
                    onSearchEnterPress={onSearchEnter}
                  />
                </div>
                <div className="SearchCancel" onClick={onSearchCancelClicked}>
                  <HButton
                    category="Secondary"
                    size="Medium"
                    text={t('Cancel').toUpperCase()}
                    // width="136px"
                  />
                </div>
              </>
            )}
            {!del_disabled_all && !isSearchChecked && (
              <div className="Filter_checkbox" title={t('Select all cases')}>
                <input
                  type="checkBox"
                  name="checkBoxCheckedAll"
                  checked={isAllChecked}
                  onChange={(e) => {
                    onAllCardCheck(cases, e.currentTarget.checked);
                  }}
                />
              </div>
            )}
            {!isSearchChecked && (
              <div
                className={'Filter_Button'}
                onClick={onFilterClicked}
                title={t('Display filter options')}
              >
                <SVG
                  name="filter"
                  color={isFiltersOn(filters) ? odm.main_color : '#878D93'}
                  colorOver={odm.main_color_hover}
                  colorOut={isFiltersOn(filters) ? odm.main_color : '#878D93'}
                  colorDown={odm.main_color}
                />
              </div>
            )}
            {!isSearchChecked && (
              <div className={'Search_Button'} onClick={onSearchClicked}>
                <SVG
                  name="search"
                  color={'#878D93'}
                  colorOver={odm.main_color_hover}
                  colorOut={'#878D93'}
                  colorDown={odm.main_color}
                />
              </div>
            )}
            {!isSearchChecked && (
              <div
                className={'Refresh_Button'}
                onClick={onRefreshClicked}
                title={t('Refresh')}
              >
                <SVG
                  name="refresh"
                  color="#878D93"
                  colorOver={odm.main_color_hover}
                  colorOut="#878D93"
                  colorDown={odm.main_color}
                />
              </div>
            )}
            {!loading && cases && isAnyChecked && !isSearchChecked && (
              <div
                className={'Delete_Button'}
                onClick={(e) => onConfirmDelete(e)}
                title={t('Delete selected case(s)')}
              >
                <SVG
                  name="delete"
                  color="#878D93"
                  colorOver={odm.main_color_hover}
                  colorOut="#878D93"
                  colorDown={odm.main_color}
                />
              </div>
            )}
            {!loading && cases && !isAnyChecked && !isSearchChecked && (
              <div className={'Combo_Sort'}>
                <InputSelect
                  placeholder={t('Last Updated')}
                  size="Medium"
                  width="150px"
                  height="40px"
                  items={sortString}
                  onSelect={onSortSelect}
                  selItem={filters.orderBy}
                />
              </div>
            )}
          </div>

          <CaseList_CaseListBlock>
            <div className="myScrollbar" style={{ height: '100%' }}>
              {loading && (
                <Loading>
                  <div className={'mobile_loading'}>
                    <Spinner />
                  </div>
                </Loading>
              )}

              {!loading && cases && cases.length > 0 && (
                <div>
                  {cases.map((_case) => (
                    <CaseItem
                      key={_case.id}
                      _case={_case}
                      content={content}
                      isCheckVisible={!del_disabled_all}
                      selected={selectedCase === _case.id ? true : false}
                      checked={checkedInputs.includes(_case.id) ? true : false}
                      onChange={(value, id) => {
                        changeHandler(value, _case.id);
                      }}
                      onSelectCase={() => {
                        if (selectedCase !== _case.id) {
                          if (Object.keys(downloadFileList).length !== 0) {
                            /* downloadCancel('Operation canceled by the user.'); */
                            downloadCancel.forEach((cancel, i, arr) => {
                              cancel('Operation canceled by the user.');
                            });
                            downloadCancel.length = 0;
                            setDownloadFileList({});
                          }
                          /* dispatch(caseInfo(_case.id)); */
                          dispatch(caseInfo({ id: _case.id }));
                          dispatch(initializeForm('file3D'));
                          dispatch(initializeForm('file3DName'));
                          dispatch(initializeForm('fileCount'));
                          dispatch(fullScreen(false));
                          setSelectedCase(_case.id);
                          history.push(`/cases/${_case.no}`, {
                            filters,
                            page: page.number,
                          });
                        }
                      }}
                    />
                  ))}
                </div>
              )}

              {!loading && cases && cases.length === 0 && (
                <Loading>
                  <div className={'mobile_loading'}>
                    {t('No cases available for this status')}
                  </div>
                </Loading>
              )}
            </div>
          </CaseList_CaseListBlock>
          <div className={'CaseList_LeftSpace'}></div>
          {
            <div>
              <Filters
                visible={modalFilter}
                formData={form}
                userFilter={userFilter}
                onModalClose={onFilterClosed}
              />
            </div>
          }
          <MessageBox
            visible={messageVisible}
            type="okcancel"
            title={messageTitle}
            content={messageContent}
            onOk={onMessageBoxOk}
            onCancel={onMessageBoxCancel}
          />
        </MobileCaseListMainBox>
      )}
    </>
  );
};
export default withRouter(CaseList);
