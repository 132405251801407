import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import * as dashboardAPI from '../lib/api/dashboard';
import { put, takeLatest } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const [CASESSTATUS, CASESSTATUS_SUCCESS, CASESSTATUS_FAILURE] =
  createRequestActionTypes('dashboard/CASESSTATUS');

const [CASESRESTORATION, CASESRESTORATION_SUCCESS, CASESRESTORATION_FAILURE] =
  createRequestActionTypes('dashboard/CASESRESTORATION');

const [CASESACTIVITY, CASESACTIVITY_SUCCESS, CASESACTIVITY_FAILURE] =
  createRequestActionTypes('dashboard/CASESACTIVITY');

const [INIT] = createRequestActionTypes('dashboard/INIT');

export const getCasesStatus = createAction(CASESSTATUS);
export const getCasesRestoration = createAction(
  CASESRESTORATION,
  ({ period }) => ({ period }),
);
export const getCasesActivity = createAction(
  CASESACTIVITY,
  ({ period, page = 1 }) => ({ period, page }),
);

const casesStatusSaga = createRequestSaga(
  CASESSTATUS,
  dashboardAPI.casesStatus,
);

const casesRestorationSaga = createRequestSaga(
  CASESRESTORATION,
  dashboardAPI.casesRestorations,
);

const casesActivitySaga = createRequestSaga(
  CASESACTIVITY,
  dashboardAPI.casesActivities,
);

// function casesStatusFailureSaga({ payload: e }) {
//   const refreshCookie = cookies.get('refresh');
//   if (e.response.status === 401 && !refreshCookie) {
//     window.location.href = '/';
//   }
// }

export const initAll = createAction(INIT);

export function* dashboardSaga() {
  yield takeLatest(CASESSTATUS, casesStatusSaga);
  yield takeLatest(CASESRESTORATION, casesRestorationSaga);
  yield takeLatest(CASESACTIVITY, casesActivitySaga);
  // yield takeLatest(CASESSTATUS_FAILURE, casesStatusFailureSaga);
}

// export function* InitDashboard() {
//   console.log("Init called");
//   yield put(initAll());
// }

const initialState = {
  casesStatus: null,
  casesRestorations: null,
  casesActivities: null,
  error_status: null,
  error_resto: null,
  error_activity: null,
};

const dashboard = handleActions(
  {
    [CASESSTATUS_SUCCESS]: (state, { payload: casesStatus }) => ({
      ...state,
      casesStatus,
      error_status: null,
    }),
    [CASESSTATUS_FAILURE]: (state, { payload: error_status }) => ({
      ...state,
      error_status,
    }),
    [CASESRESTORATION_SUCCESS]: (state, { payload: casesRestorations }) => ({
      ...state,
      casesRestorations,
      error_resto: null,
    }),
    [CASESRESTORATION_FAILURE]: (state, { payload: error_resto }) => ({
      ...state,
      error_resto,
    }),
    [CASESACTIVITY_SUCCESS]: (state, { payload: casesActivities }) => ({
      ...state,
      casesActivities,
      error_activity: null,
    }),
    [CASESACTIVITY_FAILURE]: (state, { payload: error_activity }) => ({
      ...state,
      error_activity,
    }),
    [INIT]: (state) => ({
      ...state,
      casesActivities: null,
      casesStatus: null,
      casesRestorations: null,
    }),
  },
  initialState,
);

export default dashboard;
