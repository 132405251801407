import { createAction, handleActions } from 'redux-actions';
import { produce } from 'immer';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import * as groupsAPI from '../lib/api/groups';
import { takeLatest } from 'redux-saga/effects';

/* Action Type */
const INITIALIZE_FORM = 'group/INITIALIZE_FORM';
const CHANGE_FIELD = 'group/CHANGE_FIELD';
const [LIST_GROUPUSERS, LIST_GROUPUSERS_SUCCESS, LIST_GROUPUSERS_FAILURE] = createRequestActionTypes('group/LIST_GROUPUSERS');
const [NEW_GROUP, NEW_GROUP_SUCCESS, NEW_GROUP_FAILURE] = createRequestActionTypes('group/NEW_GROUP');
const [EDIT_GROUP, EDIT_GROUP_SUCCESS, EDIT_GROUP_FAILURE] = createRequestActionTypes('group/EDIT_GROUP');
const [DELETE_GROUP, DELETE_GROUP_SUCCESS, DELETE_GROUP_FAILURE] = createRequestActionTypes('group/DELETE_GROUP');
const [
  REQUEST_GROUPUSER,
  REQUEST_GROUPUSER_SUCCESS,
  REQUEST_GROUPUSER_FAILURE,
] = createRequestActionTypes('group/REQUEST_GROUPUSER');

/* Action */
export const initializeForm = createAction(INITIALIZE_FORM, (form) => form);
export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({ form, key, value }),);
export const listGroupUsers = createAction(LIST_GROUPUSERS);
export const newGroup = createAction(NEW_GROUP, ({ name }) => ({ name }));
export const editGroup = createAction(EDIT_GROUP, ({ id, name }) => ({ id, name, }));
export const deleteGroup = createAction(DELETE_GROUP, ({ id }) => ({ id }));
export const requestGroupUser = createAction(REQUEST_GROUPUSER, ({ id, email }) => ({ id, email }),);

const listGroupsSaga = createRequestSaga(
  LIST_GROUPUSERS,
  groupsAPI.listGroupUsers,
);
const newGroupSaga = createRequestSaga(NEW_GROUP, groupsAPI.newGroup);
const editGroupSaga = createRequestSaga(EDIT_GROUP, groupsAPI.editGroup);
const deleteGroupSaga = createRequestSaga(DELETE_GROUP, groupsAPI.deleteGroup);
const requestGroupUserSaga = createRequestSaga(REQUEST_GROUPUSER, groupsAPI.requestGroupUser);

export function* groupsSaga() {
  yield takeLatest(LIST_GROUPUSERS, listGroupsSaga);
  yield takeLatest(NEW_GROUP, newGroupSaga);
  yield takeLatest(EDIT_GROUP, editGroupSaga);
  yield takeLatest(DELETE_GROUP, deleteGroupSaga);
  yield takeLatest(REQUEST_GROUPUSER, requestGroupUserSaga);
}

/* Init  */
const initialState = {
  groups: null,
  error: null,
  newgroup: null,
  deletegroup: null,
  requestgroupuser: null,
};

const groups = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      group: null,
      error: null,
    }),
    [LIST_GROUPUSERS_SUCCESS]: (state, { payload: groups }) => ({
      ...state,
      groups,
    }),
    [LIST_GROUPUSERS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [NEW_GROUP_SUCCESS]: (state, { payload: newgroup }) => ({
      ...state,
      newgroup: newgroup,
      error: null,
    }),
    [NEW_GROUP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error: error
    }),

    [EDIT_GROUP_SUCCESS]: (state, { payload: editgroup }) => ({
      ...state,
      editgroup,
    }),
    [EDIT_GROUP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error
    }),

    [DELETE_GROUP_SUCCESS]: (state, { payload: deletegroup }) => ({
      ...state,
      deletegroup,
    }),
    [DELETE_GROUP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [REQUEST_GROUPUSER_SUCCESS]: (state, { payload: requestgroupuser }) => ({
      ...state,
      requestgroupuser,
    }),
    [REQUEST_GROUPUSER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default groups;
