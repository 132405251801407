import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeField, initializeForm, login } from '../../modules/auth';
// import { cslogin, csinitializeForm } from '../../modules/cs';
import LoginForm from '../../components/auth/LoginForm';
import { check } from '../../modules/user';
import { withRouter } from 'react-router-dom';
import { sha256 } from 'js-sha256';
import { randomDataBase64url, base64urlencodeNoPadding } from '../../lib/socialLogin';
import { socialLoginOtp } from '../../modules/auth';
import { appConfig } from '../../index';
import Cookies from 'universal-cookie';
import { setLang } from '../../lib/api/app';
import DeviceLogin from '../../components/registration/DeviceLogin';
import qs from 'qs';
import { redirectLocation } from '../../lib/common';

const cookies = new Cookies();

const LoginContainer = ({ history, match, location }) => {
  const isAdmLogin = match.path === '/adm';
  const [error, setError] = useState(null);
  const [lngs, setLngs] = useState({
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    // ja: '日本語',
    it: 'Italiano',
    pl: 'Polski',
    'pt-BR': 'Português',
    ko: '한국어',
  });
  const dispatch = useDispatch();
  const {
    form,
    auth,
    authError,
    user,
    userSettings,
    next_from,
    app,
    odm,
    // csAuth,
    // csAuthError,
  } = useSelector(({ auth, user, router, app, loading }) => ({
    form: auth.login,
    auth: auth.auth,
    authError: auth.authError,
    user: user.user,
    userSettings: user.settings,
    next_from: router.location.state && router.location.state.from,
    app: app.app,
    odm: app.odm,
    // csAuth: cs.auth,
    // csAuthError: cs.authError,
  }));
  const { t, i18n } = useTranslation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const next = params.next
    ? params.next
    : next_from
    ? next_from.pathname + next_from.search
    : '';

  const onChange = (e) => {
    const { value, name } = e.target;
    dispatch(
      changeField({
        form: 'login',
        key: name,
        value,
      }),
    );
  };

  const onSelect = async (lng) => {
    i18n.changeLanguage(lng);
    setLang({ language: lng });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { username, password } = form;
    // dispatch(cslogin({ username, password }));
    dispatch(login({ username, password, callFrom: isAdmLogin ? 'adm' : null }));
  };

  // const onDeviceSubmit = (e) => {
  //   e.preventDefault();
  //   const { username, password } = form;
  //   dispatch(cslogin({ username, password }));
  // };

  // useEffect(() => {
  //   if (csAuth) {
  //     const { username, password } = form;
  //     dispatch(login({ username, password }));
  //   }
  //   //Don't have sufficient permissions.
  //   if (csAuthError) {
  //     if (csAuthError.response.data.detail === 'not device manager') {
  //       setError([
  //         t('You do not have sufficient permissions to access this page.'),
  //       ]);
  //     }
  //   }
  // }, [csAuth, csAuthError]);

  const onSocialLogin = (e) => {
    const { name: socialMedia } = e.currentTarget;

    openSignInWindow('/heclore/v1/auth/social/', socialMedia);
    // openSignInWindow('/signup/social/', socialMedia);
  };

  useEffect(() => {
    // time zone
    cookies.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone, {
      path: '/',
    });
  }, []);

  useEffect(() => {
    dispatch(initializeForm('login'));
    // dispatch(csinitializeForm('login'));
    // if (!app) dispatch(appInfo());
  }, []);

  useEffect(() => {
    //When added lans (en: 'English', de: 'Deutsch', ...)
    // if (Object.keys(lngs).length > 1) {
    const supportLanguage = findSupportLanguage(i18n.language.split('-')[0]);
    if (supportLanguage === undefined) i18n.changeLanguage('en');

    // set language on server
    const svr_lang = cookies.get('language');
    if (!svr_lang) {
      setLang({ language: supportLanguage || 'en' });
    }
    // }
  }, [lngs]);

  // useEffect(() => {}, [app]);

  useEffect(() => {
    if (authError) {
      if (isAdmLogin && authError.response.data.detail === 'not device manager') {
        setError([t('You do not have sufficient permissions to access this page.')]);
      } else {
        setError([t('Check your email or password')]);
      }

      dispatch(
        changeField({
          form: 'login',
          key: 'password',
          value: '',
        }),
      );
      return;
    }
    // if (auth) {
    // if (app.server_loc === userSettings.data_location) {
    if (auth && auth.user_loc) {
      // if (app.server_loc === auth.user_loc) {
      if (auth.server_loc === auth.user_loc) {
        dispatch(check({ callFrom: isAdmLogin ? 'adm' : null }));
      } else {
        const next_path = next ? next : isAdmLogin ? '/adm/registration' : '/';
        redirectLocation(auth.user_loc, next_path);
      }
    }
    // else {
    //   // no user_loc
    //   dispatch(check({ callFrom: isAdmLogin ? 'adm' : null }));
    // }
    // }
    return () => {
      // dispatch(initializeForm('authError'));
      // if (auth && auth.user_loc) dispatch(initializeForm('login'));
      dispatch(initializeForm('login'));
    };
  }, [auth, authError, dispatch]);

  useEffect(() => {
    if (user) {
      // if (isAdmLogin) {
      //   if (user.is_device_manager) history.push(next ? next : '/adm/registration');
      // } else {
      //   if (userSettings.data_loc_set) history.push(next ? next : '/');
      //   else {
      //     history.push('/profile');
      //   }
      // }
      // if (auth && !auth.user_loc) {
      // if (userSettings.data_loc_set === false) {
      //   //   const next_path = next ? next : isAdmLogin ? '/adm/registration' : '/';
      //   //   const qstr =
      //   //     next_path !== '/'
      //   //       ? qs.stringify({ next: next_path }, { indices: false, addQueryPrefix: true })
      //   //       : '';
      //   //   history.push(`/profile${qstr}`);
      // } else {
      if (isAdmLogin) {
        if (user.is_device_manager || user.is_device_user_viewer)
          history.push(next ? next : '/adm/registration');
        else if (user.is_sales_manager) history.push(next ? next : '/adm/devices');
        else if (user.is_staff) history.push(next ? next : '/adm/dashboard');
      } else {
        if (next) {
          const docsHost = cookies.get('original_host');
          // console.log(`docs_host: ${docsHost}`);
          if (docsHost) {
            if (next.includes('/priv/')) {
              // redirect to online-help
              window.location.href = `https://${docsHost}${next}`;
            } else {
              // Not a login for the online-help private page.
              const domain =
                window.location.hostname === 'localhost'
                  ? 'localhost'
                  : window.location.hostname.match(/\w*\.\w*$/gi)[0];
              cookies.remove('original_host', { domain: domain });
              history.push(next);
            }
          } else history.push(next);
        } else history.push('/');
      }
      // }

      // try {
      //   localStorage.setItem('user', JSON.stringify(user));
      //   localStorage.setItem('settings', JSON.stringify(userSettings));
      // } catch (e) {
      //   console.log('localStorage is not working');
      // }
    }
  }, [user]);
  // }, [history, user, next]);

  let windowObjectReference = null;
  let previousUrl = null;
  let state = null;
  let codeVerifier = null;

  const openSignInWindow = (path, socialMedia) => {
    // remove any existing event listeners
    window.removeEventListener('message', receiveMessage);

    // const url = `${appConfig.serverUrl}${path}${socialMedia}/`;
    const url = `${path}${socialMedia}/`;
    const netloc = `${window.location.protocol}//${window.location.host}`;
    const redirectURI = `${netloc}/login/social/response`;

    state = randomDataBase64url(32);
    codeVerifier = randomDataBase64url(32);
    const codeChallenge = base64urlencodeNoPadding(sha256(codeVerifier));
    const reuri = encodeURIComponent(redirectURI);
    const authorizationRequest = `${url}?state=${state}&redirectUri=${reuri}&codeChallenge=${codeChallenge}`;
    // const authorizationRequest = url;

    // window features
    const strWindowFeatures =
      'toolbar=no, menubar=no, width=950, height=900, top=100, left=100';
    const target = 'SignInWindow';

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
      windowObjectReference = window.open(
        authorizationRequest,
        target,
        strWindowFeatures,
      );
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(
        authorizationRequest,
        target,
        strWindowFeatures,
      );
      windowObjectReference.focus();
    } else {
      /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener('message', (event) => receiveMessage(event), false);
    // assign the previous URL
    previousUrl = url;
  };

  const receiveMessage = (event) => {
    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    if (event.origin !== window.location.origin) {
      return;
    }
    const { data } = event;
    // if we trust the sender and the source is our popup
    if (data.state === state) {
      // get the URL params and redirect to our server to use Passport to auth/login
      const { code } = data;
      dispatch(socialLoginOtp({ code, codeVerifier }));
    }
  };

  const findSupportLanguage = (value) => {
    let language = Object.keys(lngs).find((key) => key.includes(value));
    return language;
  };
  const language = lngs[findSupportLanguage(i18n.language.split('-')[0])];

  return (
    <>
      {!isAdmLogin && (
        <LoginForm
          form={form}
          language={language}
          lngs={lngs}
          onChange={onChange}
          onSelect={onSelect}
          onSubmit={onSubmit}
          onSocialLogin={onSocialLogin}
          error={error}
          app={app}
          odm={odm}
        />
      )}
      {isAdmLogin && (
        <DeviceLogin
          form={form}
          language={language}
          lngs={lngs}
          onChange={onChange}
          onSelect={onSelect}
          // onSubmit={onDeviceSubmit}
          onSubmit={onSubmit}
          onSocialLogin={onSocialLogin}
          error={error}
          app={app}
          odm={odm}
          match={match}
        />
      )}
    </>
  );
};

export default withRouter(React.memo(LoginContainer));
