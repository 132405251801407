import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import HButton from '../common/HButton';
import TextBox from '../leftpanel/TextBox';

import { useTranslation } from 'react-i18next';

import CheckBox from '../common/CheckBox';
import InputSelect from '../common/InputSelect';

import Calendar from './Calendar';

import { userFilter } from '../../modules/cases';
import { refresh } from '../../lib/api/auth';
import { theme } from '../../lib/odm';
import SVG from '../common/svg';
// import { userFilters } from '../../lib/api/cases';

// import { setFilters } from '../../modules/cases';
// import { caseStatus } from '../../lib/api/case';

//#region styled components
const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${fadeOut};
    `}
`;

const ModalBlock = styled.div`
  min-width: 318px;
  margin: 28px 20px;
  height: ${(props) => props.height};
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${slideUp};
    `}
`;

const TitleBlock = styled.div`
  .titleBox {
    min-width: 318px;
    height: 56px;
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 0;
    background: #eeeff0;
  }

  .titleText {
    /* width: 400px; */
    width: 80%;
    height: 25px;
    margin: 15px 0 0 19px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    float: left;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 16px 19px 0 0;
    float: right;
  }
`;

const InputBlock = styled.div`
  .email {
    width: 454px;
    height: 36px;
    margin: 24px 0 0 20px;
    padding-left: 16px;
    padding-right: 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }

  .email::-webkit-input-placeholder {
    color: #969ba1;
  }

  .email:focus::placeholder {
    color: transparent;
  }

  .alias {
    width: 454px;
    height: 36px;
    margin: 8px 0 0 20px;
    padding-left: 16px;
    padding-right: 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }

  .alias::-webkit-input-placeholder {
    color: #969ba1;
  }

  .alias:focus::placeholder {
    color: transparent;
  }

  .message {
    width: 454px;
    height: 54px;
    margin: 8px 0 0 20px;
    padding: 10px 16px 10px 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    color: #969ba1;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    resize: none;
  }

  .message::-webkit-input-placeholder {
    color: #969ba1;
  }

  .message:focus::placeholder {
    color: transparent;
  }
`;

const BottomLine = styled.div`
  display: table;
  width: 90%;
  height: 1px;
  margin: 24px 19px 0;
  background-color: #e7e9ea;
`;

const CaseStatus = styled.div``;
const Comments = styled.div``;
const OrderSent = styled.div`
  .DateRect {
    width: 136px;
    height: 38px;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: #ffffff;
    /* margin-left: 11px; */
  }

  .Placeholder {
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969ba1;
  }
`;

const Text = styled.span`
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #59616a;

  width: ${(props) => props.width};
  text-align: center;
`;

const CreatedBy = styled.div``;
const ButtonBlock = styled.div``;

//#endregion

const subTitleFont = {
  // width: '100%',
  margin: '24px 0 8px 19px',
  fontFamily: 'Source Sans Pro',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '0.2px',
  color: '#59616a',
};

const textFont = {
  fontFamily: 'Source Sans Pro',
  fontSize: '15px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#59616a',
  float: 'left',
};

// const Filters = ({ visible, formData, onModalClose, userFilter }) => {
const Filters = ({ visible, formData, onModalClose }) => {
  const dispatch = useDispatch();
  const { userFilters } = useSelector(({ cases }) => ({
    userFilters: cases.userFilters,
  }));

  const calendarActive =
    process.env.PUBLIC_URL + '/images/filters/icon-24-calendar-active.svg';

  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0];
  const [calendarFromTo, setCalendarFromTo] = useState('from');
  const [visibleCalendar, setVisibleCalendar] = useState(false);
  const [fromMouseEvt, setFromMouseEvt] = useState('leave');
  const [toMouseEvt, setToMouseEvt] = useState('leave');
  const textDateWidth = language === 'pt' ? '70px' : '35px';
  const textByWidth = '70px';

  //const [formData, setformData] = useState(null);
  //const [selectedStatus, setSelectedStatus] = useState(formData.status);
  //const [unreadComment, setUnreadComment] = useState(
  //formData.unreadCommentsOnly,
  //);

  //const [fromDate, setFromDate] = useState(formData.createDateFrom);
  //const [toDate, setToDate] = useState(formData.createDateTo);
  //const [selInstitution, setSelInstitution] = useState(formData.institution);
  //const [selPerson, setSelPerson] = useState(formData.person);

  const [userChangeDate, setUserChangeDate] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [authors, setAuthors] = useState([]);

  //var institutions = [];
  //var authors = [];

  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
  }
  //console.log(formData);

  const forceUpdate = useForceUpdate();

  // useEffect(() => {
  //   //setformData(formData);
  // }, [formData, visible]);

  // const { userFilters, error, loading } = useSelector(
  //   ({cases, loading}) => ({
  //     userFilters: cases.userFilters,
  //     loading: loading['cases/USER_FILTER'],
  //     error: cases.error
  //   }),
  // );

  function onCaseStatusSelected(id) {
    if (id === 'Any') {
      //setSelectedStatus([]);
      formData.status = [];
      //} else if (selectedStatus.indexOf(id) === -1) {
    } else if (formData.status.indexOf(id) === -1) {
      //setSelectedStatus(selectedStatus.concat(id));
      formData.status = formData.status.concat(id);
    } else {
      var tmp = formData.status.filter((x) => x !== id);
      if (tmp.length === 0) tmp = [];
      formData.status = tmp;
      //setSelectedStatus(tmp);
    }

    forceUpdate();
  }

  function onChecked(state) {
    //setUnreadComment(state);
    //formData.unreadCommentsOnly = state;
  }

  function onInstution(data) {
    formData.institution = data;
    formData.person = 'All';

    var tmp = ['All'];
    var t = null;

    if (data === 'All') {
      t = userFilters.users;
    } else {
      var inst = userFilters.institutions.filter((x) => x.name === data)[0];
      t = userFilters.users.filter((x) => inst.user_ids.includes(x.id));
    }

    for (var i = 0; i < t.length; i++) {
      tmp.push(t[i].name);
    }

    setAuthors(tmp);
    forceUpdate();
  }

  function onPerson(data) {
    formData.person = data;
    forceUpdate();
  }

  function onResetAll() {
    formData.status = [];
    formData.person = 'All';
    formData.institution = 'All';
    formData.createDateFrom = null;
    formData.createDateTo = null;
    // formData.userIds = '';
    formData.userIds = [];

    forceUpdate();
  }

  function onCalendarClosed(fromto, selectedDate) {
    let from = formData.createDateFrom; //dateRange[0];
    let to = formData.createDateTo; //dateRange[1];

    if (selectedDate !== null && selectedDate !== undefined) {
      if (selectedDate.length === 1) {
        if (fromto === 'from') {
          from = selectedDate[0];
          if (to !== null && from > to) to = from;
        } else {
          to = selectedDate[0];
        }
      } else if (selectedDate.length > 1) {
        from = selectedDate[0];
        to = selectedDate[selectedDate.length - 1];
      } else {
        if (fromto === 'from') from = null;
        else to = null;
      }
    }

    formData.createDateFrom = from;
    formData.createDateTo = to;
    setUserChangeDate(true);
    //setDateRange([from, to]);
    setVisibleCalendar(!visibleCalendar);

    forceUpdate();
  }

  function setUserIds() {
    // formData.userIds = '';
    formData.userIds = [];

    if (formData.person === 'All') {
      if (formData.institution !== 'All') {
        var inst = userFilters.institutions.filter(
          (x) => x.name === formData.institution,
        )[0];

        if (inst === null || inst === undefined) return;

        // const t = userFilters.users.filter((x) => inst.user_ids.includes(x.id));
        // for (var i = 0; i < t.length; i++) {
        //   // if (formData.userIds !== '') formData.userIds += '&';
        //   // formData.userIds += 'user=' + t[i].id;
        //   formData.userIds.push(t[i].id);
        // }
        Array.prototype.push.apply(formData.userIds, inst.user_ids);
      }
    } else {
      // formData.userIds =
      //   'uid=' +
      formData.userIds.push(
        userFilters.users.filter((x) => x.name === formData.person)[0].id,
      );
    }
  }

  useEffect(() => {
    var tInst = ['All'];
    var tPerson = ['All'];

    if (userFilters) {
      for (var i = 0; i < userFilters.institutions.length; i++) {
        //if (userFilters.institutions[i] != null)
        tInst.push(userFilters.institutions[i].name);
      }

      if (formData.institution === 'All') {
        for (var i = 0; i < userFilters.users.length; i++) {
          tPerson.push(userFilters.users[i].name);
        }
      } else {
        const inst = userFilters.institutions.filter(
          (x) => x.name === formData.institution,
        )[0];
        if (inst !== null && inst !== undefined) {
          const t = userFilters.users.filter((x) => inst.user_ids.includes(x.id));
          for (var i = 0; i < t.length; i++) {
            tPerson.push(t[i].name);
          }
        }
      }
    }

    setInstitutions(tInst);
    setAuthors(tPerson);

    console.log(formData);

    // if (
    //   userChangeDate === false &&
    //   (formData.createDateFrom !== dateRange[0] ||
    //     formData.createDateTo !== dateRange[1])
    // )
    //   setDateRange([formData.createDateFrom, formData.createDateTo]);
  }, [formData, userFilters]);

  //console.log("user filter loading: " + loading);

  // useEffect(() => {
  //   if (!loading && userFilters === null)
  //   {
  //     console.log("check");
  //     dispatch(userFilter());
  //   }
  // }, [dispatch, loading, userFilters]);

  // if (error) return (<>error</>);
  // if (loading) return null;
  if (visible === false) return null;

  function getColor(where) {
    var ret = '#878D93';
    if (where === 'from') {
      if (fromMouseEvt === 'enter') ret = theme.main_color_hover;
      else if (fromMouseEvt === 'down') ret = theme.main_color;
    } else if (where === 'to') {
      if (toMouseEvt === 'enter') ret = theme.main_color_hover;
      else if (toMouseEvt === 'down') ret = theme.main_color;
    }

    return ret;
  }

  //console.log(userFilters);

  return (
    <Fullscreen disappear={!visible}>
      <ModalBlock
        disappear={!visible}
      // height={language != 'de' && language != 'pl' ? '505px' : '540px'}
      >
        {/* <form onSubmit={onSubmit}> */}
        {/* <form> */}
        <TitleBlock>
          <div className="titleBox">
            <div className="titleText">{t('Filters')}</div>
            <div
              className="closeIcon"
              onClick={() => {
                setUserIds();
                onModalClose(false);
              }}
              title={t('Close')}
            >
              <SVG
                name="close"
                color="#878D93"
                colorOver={theme.main_color_hover}
                colorOut="#878D93"
                colorDown={theme.main_color}
              />
            </div>
            {/* <img
              className={'closeIcon'}              
              // src={closeActive}
              // onMouseOver={(e) => (e.currentTarget.src = closeHover)}
              // onMouseOut={(e) => (e.currentTarget.src = closeActive)}
              // onMouseDown={(e) => (e.currentTarget.src = closeSelected)}
              onClick={() => {
                setUserIds();
                //console.log(formData);
                //setformData(formData);
                // setSelectedStatus(formData.status);
                // setUnreadComment(formData.unreadCommentsOnly);
                // setDateRange([
                //   formData.createDateFrom,
                //   formData.createDateTo,
                // ]);
                // setSelInstitution(formData.institution);
                // setSelPerson(formData.person);
                //formData = formData;
                onModalClose(false);
              }}
            /> */}
          </div>
        </TitleBlock>

        <CaseStatus>
          <div style={subTitleFont}>{t('Case Status').toUpperCase()}</div>
          <div style={{ margin: '0 0 0 20px' }}>
            <TextBox
              name="All"
              id="Any"
              cursor="pointer"
              color="#59616a"
              sel_color="#59616a24"
              margin="8px 8px 0 0"
              selectedStatus={formData.status.length === 0 ? ['Any'] : formData.status}
              fat={true}
              onClick={onCaseStatusSelected}
            />

            <TextBox
              name="Open"
              id="Open"
              cursor="pointer"
              color="#2274ec"
              sel_color="#2274ec24"
              margin="8px 8px 0 0"
              selectedStatus={formData.status}
              fat={true}
              onClick={onCaseStatusSelected}
            />

            <TextBox
              name="In Progress"
              id="InProgress"
              cursor="pointer"
              color="#a78120"
              sel_color="#a7812024"
              margin="8px 8px 0 0"
              selectedStatus={formData.status}
              fat={true}
              onClick={onCaseStatusSelected}
            />

            <TextBox
              name="Completed"
              id="Completed"
              cursor="pointer"
              color="#50b019"
              sel_color="#50b01924"
              margin="8px 8px 0 0"
              selectedStatus={formData.status}
              fat={true}
              onClick={onCaseStatusSelected}
            />

            <TextBox
              name="Reopened"
              id="Reopened"
              cursor="pointer"
              // color="#e63b3b"
              // sel_color="#e63b3b24"
              color="#2274ec"
              sel_color="#2274ec24"
              margin="8px 8px 0 0"
              selectedStatus={formData.status}
              fat={true}
              onClick={onCaseStatusSelected}
            />

            <TextBox
              name="Closed"
              id="Closed"
              cursor="pointer"
              color="#a0a0a0"
              sel_color="#a0a0a024"
              margin="8px 8px 0 0"
              selectedStatus={formData.status}
              fat={true}
              onClick={onCaseStatusSelected}
            />
          </div>
        </CaseStatus>
        <BottomLine />

        {/* <Comments>
            <div style={subTitleFont}>{t('Comments')}</div>
            <div style={Object.assign({ marginLeft: '20px' }, textFont)}>
              {t('Show ONLY cases with unread comments')}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '20px',
              }}
            >
              <CheckBox
                id="1"
                initState={unreadComment}
                onChecked={onChecked}
              />
            </div>
          </Comments>
          <BottomLine style={{ marginTop: '20px' }} /> */}

        <OrderSent>
          <div style={Object.assign({}, subTitleFont, { margin: '24px 0 16px 19px' })}>
            {t('Order Sent').toUpperCase()}
          </div>
          <div style={{ marginLeft: '20px' }}>
            {/* Order sent From Area */}
            <div
              style={{
                height: '100%',
                marginTop: '8px',
                marginRight: '20px',
                display: 'flex',
                alignItems: 'center',
                float: 'left',
              }}
            >
              <Text width={textDateWidth}>{t('From')}</Text>

              <div
                className="DateRect"
                style={{
                  float: 'left',
                  userSelect: 'none',
                  cursor: 'pointer',
                  marginLeft: '11px',
                }}
                onClick={() => {
                  setCalendarFromTo('from');
                  setVisibleCalendar(true);
                }}
                onMouseEnter={() => setFromMouseEvt('enter')}
                onMouseLeave={() => setFromMouseEvt('leave')}
                onMouseDown={() => setFromMouseEvt('down')}
              >
                <div
                  className="Placeholder"
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '16px',
                    float: 'left',
                  }}
                >
                  {formData.createDateFrom === null
                    ? 'YYYY-MM-DD'
                    : formData.createDateFrom.getFullYear() +
                    '-' +
                    (formData.createDateFrom.getMonth() + 1) +
                    '-' +
                    formData.createDateFrom.getDate()}
                </div>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '105px',
                  }}
                  title={t('Calendar')}
                >
                  {console.log(getColor('from'))}
                  <SVG
                    name={'calendar_icon'}
                    color={getColor('from')}
                    colorOver={theme.main_color_hover}
                    colorOut={theme.main_color_hover}
                    colorDown={theme.main_color}
                  />
                  {/* <img src={calendarActive} /> */}
                </div>
              </div>
            </div>
            {/* Order sent To Area */}
            <div
              style={{
                height: '100%',
                marginTop: '8px',
                marginRight: '8px',
                marginBottom: '24px',
                display: 'flex',
                alignItems: 'center',
                float: 'left',
              }}
            >
              <Text width={textDateWidth}>{t('To')}</Text>
              <div
                className="DateRect"
                style={{
                  float: 'left',
                  userSelect: 'none',
                  cursor: 'pointer',
                  marginLeft: '11px',
                }}
                onClick={() => {
                  setCalendarFromTo('to');
                  setVisibleCalendar(true);
                }}
                onMouseEnter={() => setToMouseEvt('enter')}
                onMouseLeave={() => setToMouseEvt('leave')}
                onMouseDown={() => setToMouseEvt('down')}
              >
                <div
                  className="Placeholder"
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '16px',
                    float: 'left',
                  }}
                >
                  {formData.createDateTo === null
                    ? 'YYYY-MM-DD'
                    : formData.createDateTo.getFullYear() +
                    '-' +
                    (formData.createDateTo.getMonth() + 1) +
                    '-' +
                    formData.createDateTo.getDate()}
                </div>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '105px',
                  }}
                  title={t('Calendar')}
                >
                  <SVG
                    name={'calendar_icon'}
                    color={getColor('to')}
                    colorOver={theme.main_color_hover}
                    colorOut={theme.main_color_hover}
                    colorDown={theme.main_color}
                  />
                  {/* <img src={calendarActive} /> */}
                </div>
              </div>
            </div>
          </div>
        </OrderSent>
        <BottomLine />

        <CreatedBy>
          <div style={Object.assign({}, subTitleFont, { margin: '24px 0 16px 19px' })}>
            {`${t('Institution').toUpperCase()} / ${t('Person').toUpperCase()}`}
          </div>
          <div style={{ marginLeft: '20px' }}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                marginTop: '8px',
                alignItems: 'center',
                float: 'left',
              }}
            >
              <Text width={textByWidth}>{t('Institution')}</Text>
              <div
                style={{
                  width: '167px',
                  height: '40px',
                  float: 'left',
                  marginLeft: '11px',
                }}
              >
                <InputSelect
                  items={institutions}
                  onSelect={onInstution}
                  placeholder="All"
                  width={language === 'fr' ? '160px' : '167px'}
                  height="40px"
                  selItem={formData.institution}
                  reset="0"
                />
              </div>
            </div>

            <div
              style={{
                height: '100%',
                // marginLeft: language === 'fr' ? '10px' : '20px',
                marginTop: '8px',
                marginBottom: '24px',
                marginRight: '20px',
                display: 'flex',
                alignItems: 'center',
                float: 'left',
              }}
            >
              <Text width={textByWidth}>{t('Person')}</Text>
              <div
                style={{
                  width: '167px',
                  height: '40px',
                  float: 'left',
                  marginLeft: '11px',
                }}
              >
                <InputSelect
                  items={authors}
                  onSelect={onPerson}
                  placeholder="All"
                  width={language === 'fr' ? '160px' : '167px'}
                  height="40px"
                  selItem={formData.person}
                  reset="0"
                />
              </div>
            </div>
          </div>
        </CreatedBy>
        <BottomLine />

        <ButtonBlock>
          <HButton
            type="button"
            category="Primary"
            size="Medium"
            text={t('Apply').toUpperCase()}
            width="auto"
            margin="24px 20px 20px 0"
            float="right"
            onClick={() => {
              setUserIds();
              //formData = formData;
              // formData.status = selectedStatus;
              // formData.unreadCommentsOnly = unreadComment;
              // formData.createDateFrom = dateRange[0];
              // formData.createDateTo = dateRange[1];
              // formData.institution = selInstitution;
              // formData.person = selPerson;
              onModalClose(true);
            }}
          />
          <HButton
            type="button"
            category="Secondary"
            size="Medium"
            text={t('Reset All').toUpperCase()}
            width="auto"
            margin="24px 16px 20px 0"
            float="right"
            onClick={onResetAll}
          />
        </ButtonBlock>
        {/* </form> */}
      </ModalBlock>

      <Calendar
        visible={visibleCalendar}
        fromto={calendarFromTo}
        selDate={
          calendarFromTo === 'from'
            ? [formData.createDateFrom]
            : [formData.createDateTo]
        }
        onModalClose={onCalendarClosed}
      />
    </Fullscreen>
  );
};

export default Filters;
