import {
  BufferGeometry,
  DoubleSide,
  Float32BufferAttribute,
  Line,
  LineBasicMaterial,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  Quaternion,
  Raycaster,
  SphereGeometry,
  Vector2,
  Vector3,
  Group,
  // BoxGeometry, // DEBUG
  // EdgesGeometry, // DEBUG
  // LineSegments, // DEBUG

  // ADD TextSprite
  CanvasTexture,
  SpriteMaterial,
  Sprite,
  LinearFilter,
} from 'three/build/three.module.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';

var TransformControls = function (camera, domElement, scene) {
  const isSmallScreen = window.innerWidth < 1024;

  this.scene = scene;
  this.points = [];
  this.line = null;
  this.pointsCount = 0;
  this.isPreview = false;

  this.isDragging = false;
  this.dragStartPosition = new Vector3();

  this.mouseDownTime = 0;
  this.CLICK_THRESHOLD = 200;

  if (domElement === undefined) {
    console.warn(
      'THREE.TransformControls: The second parameter "domElement" is now mandatory.',
    );
    domElement = document;
  }

  Object3D.call(this);

  this.visible = false;
  this.domElement = domElement;

  var _gizmo = new TransformControlsGizmo();
  this.add(_gizmo);

  var _plane = new TransformControlsPlane();
  this.add(_plane);

  var scope = this;

  // Define properties with getters/setter
  // Setting the defined property will automatically trigger change event
  // Defined properties are passed down to gizmo and plane

  defineProperty('camera', camera);
  // defineProperty( 'object', undefined );
  defineProperty('enabled', true);
  defineProperty('axis', null);
  defineProperty('mode', 'translate');
  // defineProperty( 'translationSnap', null );
  // defineProperty( 'rotationSnap', null );
  // defineProperty( 'scaleSnap', null );
  // defineProperty( 'space', 'world' );
  defineProperty('size', 1);
  defineProperty('dragging', false);
  // defineProperty( 'showX', true );
  // defineProperty( 'showY', true );
  // defineProperty( 'showZ', true );

  var changeEvent = { type: 'change' };
  var mouseDownEvent = { type: 'mouseDown' };
  var mouseUpEvent = { type: 'mouseUp', mode: scope.mode };
  var objectChangeEvent = { type: 'objectChange' };

  // Reusable utility variables

  var raycaster = new Raycaster();

  function intersectObjectWithRay(object, raycaster, includeInvisible) {
    var allIntersections = raycaster.intersectObject(object, true);

    // console.log(allIntersections);

    for (var i = 0; i < allIntersections.length; i++) {
      if (allIntersections[i].object.visible || includeInvisible) {
        // console.log(allIntersections[i]);

        return allIntersections[i];
      }
    }

    return false;
  }

  var _tempVector = new Vector3();
  var _tempQuaternion = new Quaternion();

  var pointStart = new Vector3();
  var pointEnd = new Vector3();
  var offset = new Vector3();
  var rotationAxis = new Vector3();
  var startNorm = new Vector3();
  var endNorm = new Vector3();
  var rotationAngle = 0;

  var cameraPosition = new Vector3();
  var cameraQuaternion = new Quaternion();
  var cameraScale = new Vector3();

  var parentPosition = new Vector3();
  var parentQuaternion = new Quaternion();
  var parentQuaternionInv = new Quaternion();
  var parentScale = new Vector3();

  var worldPositionStart = new Vector3();

  var worldPosition = new Vector3();

  var eye = new Vector3();

  var positionStart = new Vector3();
  var quaternionStart = new Quaternion();

  // TODO: remove properties unused in plane and gizmo

  defineProperty('worldPosition', worldPosition);
  defineProperty('worldPositionStart', worldPositionStart);
  defineProperty('cameraPosition', cameraPosition);
  defineProperty('cameraQuaternion', cameraQuaternion);
  defineProperty('pointStart', pointStart);
  defineProperty('pointEnd', pointEnd);
  defineProperty('rotationAxis', rotationAxis);
  defineProperty('rotationAngle', rotationAngle);
  defineProperty('eye', eye);

  {
    domElement.addEventListener('touchstart', onTouchDown, false);
    domElement.addEventListener('pointerdown', onPointerDown);
    domElement.addEventListener('pointermove', onPointerHover);
    scope.domElement.ownerDocument.addEventListener('pointerup', onPointerUp);
  }

  this.dispose = function () {
    domElement.removeEventListener('touchstart', onTouchDown);
    domElement.removeEventListener('pointerdown', onPointerDown);
    domElement.removeEventListener('pointermove', onPointerHover);
    scope.domElement.ownerDocument.removeEventListener('pointermove', onPointerMove);
    scope.domElement.ownerDocument.removeEventListener('pointerup', onPointerUp);

    this.traverse(function (child) {
      if (child.geometry) child.geometry.dispose();
      if (child.material) child.material.dispose();
    });
  };

  this.update = function () {
    if (this.object) {
      this.object.updateMatrixWorld();
      this.object.updateMatrix();
    }
  };

  // Set current object
  this.attach = function (object) {
    this.object = object;
    this.visible = true;
    this.update();

    // CUBE TEST HELPER DEBUG
    // const testGeometry = new BoxGeometry(10, 10, 10);
    // const testMaterial = new MeshBasicMaterial({
    //   color: 0x00ff00,
    //   transparent: true,
    //   opacity: 0.5,
    // });
    // const testCube = new Mesh(testGeometry, testMaterial);
    // testCube.position.set(0, 0, 0);
    // testCube.name = 'testCube';

    // const edges = new EdgesGeometry(testGeometry);
    // const edgesMaterial = new LineBasicMaterial({ color: 0x000000 });
    // const edgesLine = new LineSegments(edges, edgesMaterial);
    // testCube.add(edgesLine);
    // this.object.add(testCube);
    // CUBE TEST HELPER DEBUG
  };

  // Detatch from object
  this.detach = function () {
    this.object = undefined;
    this.visible = false;
    this.axis = null;

    return this;
  };

  // Defined getter, setter and store for a property
  function defineProperty(propName, defaultValue) {
    var propValue = defaultValue;

    Object.defineProperty(scope, propName, {
      get: function () {
        return propValue !== undefined ? propValue : defaultValue;
      },

      set: function (value) {
        if (propValue !== value) {
          propValue = value;
          _plane[propName] = value;
          _gizmo[propName] = value;

          scope.dispatchEvent({ type: propName + '-changed', value: value });
          scope.dispatchEvent(changeEvent);
        }
      },
    });

    scope[propName] = defaultValue;
    _plane[propName] = defaultValue;
    _gizmo[propName] = defaultValue;
  }

  // updateMatrixWorld  updates key transformation variables
  this.updateMatrixWorld = function () {
    if (this.object !== undefined) {
      this.object.updateMatrixWorld();

      if (this.object.parent === null) {
        console.error(
          'TransformControls: The attached 3D object must be a part of the scene graph.',
        );
      } else {
        this.object.parent.matrixWorld.decompose(
          parentPosition,
          parentQuaternion,
          parentScale,
        );
      }
    }

    this.camera.updateMatrixWorld();
    this.camera.matrixWorld.decompose(cameraPosition, cameraQuaternion, cameraScale);

    eye.copy(cameraPosition).sub(worldPosition).normalize();

    Object3D.prototype.updateMatrixWorld.call(this);
  };

  this.pointerHover = function (pointer) {
    if (this.object === undefined || this.dragging === true) return;

    raycaster.setFromCamera(pointer, this.camera);

    var intersect = intersectObjectWithRay(_gizmo.picker[this.mode], raycaster);

    if (intersect) {
      //Test
      scope.axis = 'XYZE';
      this.axis = 'XYZE';
    } else {
      //Test
      scope.axis = 'E';
      this.axis = 'E';
    }
  };

  function getDPI() {
    return window.devicePixelRatio || 1;
  }

  function calculateDistance(point1, point2) {
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    const dz = point2.z - point1.z;

    const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
    const scaleFactor = 0.1;

    return distance * scaleFactor; // Return number, not string
  }

  function makeLabelTexture(message) {
    const dpi = getDPI() * 2;
    const canvas = document.createElement('canvas');
    canvas.width = 1024 * dpi;
    canvas.height = 512 * dpi;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const fontSize = Math.round((isSmallScreen ? 32 : 24) * dpi);
    ctx.font = `${fontSize}px Source Sans Pro`;
    ctx.fillStyle = '#000000';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Anti-aliasing
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    ctx.fillText(message, canvas.width / 2, canvas.height / 2);

    const texture = new CanvasTexture(canvas);
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.needsUpdate = true;
    return texture;
  }

  function createTextSprite(point1, point2, distance, object) {
    // Create sprite material with measurement text

    const formattedDistance = Number(distance).toFixed(2);
    const spriteMaterial = new SpriteMaterial({
      map: makeLabelTexture(distance.toFixed(2) + ' cm'),
      depthTest: false,
      depthWrite: false,
      sizeAttenuation: false,
      transparent: true,
    });

    // Create sprite and set properties
    const sprite = new Sprite(spriteMaterial);
    const baseScale = 0.15 / getDPI();
    const scale = isSmallScreen ? baseScale * 1.5 : baseScale;
    sprite.scale.set(scale * 1.5, scale, 1);
    sprite.center.set(0.5, 0.5);
    sprite.renderOrder = 999999;

    // Calculate midpoint between measurement points
    sprite.position.copy(point1).add(point2).multiplyScalar(0.5);
    sprite.position.y += 1.2;

    // Add sprite to appropriate parent
    if (object.isGroup) {
      object.add(sprite);
    } else if (object.parent) {
      object.parent.add(sprite);
    } else {
      console.warn('Cannot add measurement sprite - invalid object type');
      return null;
    }

    return sprite;
  }

  function createLineBetweenPoints(point1, point2, object, scene) {
    // Debug measurements
    console.log('Creating line between points:', { point1, point2 });
    const distance = calculateDistance(point1, point2);
    console.log('Distance calculated:', distance);

    // Create line geometry
    const geometry = new LineGeometry();
    geometry.setPositions([point1.x, point1.y, point1.z, point2.x, point2.y, point2.z]);

    // Create line material with enhanced visibility
    const material = new LineMaterial({
      color: 0x00ff00,
      linewidth: 4,
      resolution: new Vector2(window.innerWidth, window.innerHeight),
      dashed: false,
      transparent: true,
      opacity: 1,
      depthTest: false,
      depthWrite: false,
      toneMapped: false,
      alphaToCoverage: false,
      side: DoubleSide,
    });

    // Create and configure line
    const line = new Line2(geometry, material);
    line.computeLineDistances();
    line.renderOrder = 99999;
    line.name = 'measurementLine';
    line.frustumCulled = false;
    line.scale.set(1, 1, 1);

    // Calculate midpoint for text placement
    const midPoint = new Vector3().copy(point1).add(point2).multiplyScalar(0.5);

    // Add text label
    createTextSprite(point1, point2, distance, object);

    // Add line to scene/object
    object.add(line);
    console.log('Line added to object:', line);

    // Add to scene if provided
    if (scene) {
      scene.add(line);
      console.log('Line added to scene:', line);
    }

    // Update line resolution on window resize
    window.addEventListener('resize', () => {
      material.resolution.set(window.innerWidth, window.innerHeight);
    });

    return line;
  }

  this.setMeasureMode = function (enabled) {
    this.measureMode = enabled;
    // _gizmo.visible = !enabled;

    // Nettoyer les mesures précédentes
    if (enabled) {
      this.clearMeasurements();
    }
  };

  this.clearMeasurements = function () {
    // Réinitialiser le compteur de points
    this.pointsCount = 0;
    this.points = [];

    // Supprimer les lignes de mesure et les sprites
    if (this.object instanceof Group) {
      this.object.children = this.object.children.filter((child) => {
        return !(
          child.name === 'measurementLine' ||
          child.name === 'measurementCross' ||
          child.type === 'Sprite'
        );
        // child instanceof Line ||
        // child instanceof Line2);
      });
    }
  };

  this.pointsCount = 0;

  function createCross(point, object) {
    const crossSize = 1;
    const material = new LineMaterial({
      color: 0x0000ff,
      linewidth: 4,
      transparent: true,
      opacity: 1,
      depthTest: false,
      depthWrite: false,
    });
    material.resolution.set(window.innerWidth, window.innerHeight);

    // Create 6 lines at 60° intervals
    const lines = [];
    for (let i = 0; i < 6; i++) {
      const angle = (i * Math.PI) / 3; // 60° intervals
      const geometry = new LineGeometry();

      // Calculate start and end points
      const startX = point.x + (crossSize / 2) * Math.cos(angle);
      const startY = point.y + (crossSize / 2) * Math.sin(angle);
      const endX = point.x - (crossSize / 2) * Math.cos(angle);
      const endY = point.y - (crossSize / 2) * Math.sin(angle);

      geometry.setPositions([startX, startY, point.z, endX, endY, point.z]);

      const line = new Line2(geometry, material);
      line.computeLineDistances();
      line.renderOrder = 999;
      line.name = 'measurementCross';

      object.add(line);
      lines.push(line);
    }

    return lines[0].parent;
  }

  this.pointerDown = function (pointer) {
    if (!this.object || pointer.button !== 0) return;

    this.mouseDownTime = Date.now();
    console.log('Mouse down at:', this.mouseDownTime);

    raycaster.setFromCamera(pointer, this.camera);
    let transformOccurred = false;

    // 1. Check gizmo interaction first
    const gizmoIntersect = intersectObjectWithRay(
      _gizmo.picker[this.mode],
      raycaster,
      true,
    );

    if (gizmoIntersect) {
      this.axis = gizmoIntersect.object.name;
      const planeIntersect = intersectObjectWithRay(_plane, raycaster, true);

      if (planeIntersect) {
        positionStart.copy(this.object.position);
        quaternionStart.copy(this.object.quaternion);
        pointStart.copy(planeIntersect.point).sub(worldPositionStart);
        this.dragging = true;
        mouseDownEvent.mode = this.mode;
        this.dispatchEvent(mouseDownEvent);
        transformOccurred = true;
      }
    }

    // 2. Handle both measure mode and object drag
    if (!transformOccurred) {
      // Store intersection for both measure and drag
      const meshIntersect = raycaster.intersectObject(this.object, true)[0];
      if (meshIntersect) {
        this._lastIntersection = meshIntersect;

        if (!this.measureMode) {
          const planeIntersect = intersectObjectWithRay(_plane, raycaster, true);
          if (planeIntersect) {
            positionStart.copy(this.object.position);
            quaternionStart.copy(this.object.quaternion);
            pointStart.copy(planeIntersect.point).sub(worldPositionStart);
            this.dragging = true;
          }
        }
      }
    }
  };

  this.pointerMove = function (pointer) {
    var axis = this.axis;
    var mode = this.mode;
    var object = this.object;

    if (
      object === undefined ||
      axis === null ||
      this.dragging === false ||
      pointer.button !== -1
    )
      return;

    raycaster.setFromCamera(pointer, this.camera);

    var planeIntersect = intersectObjectWithRay(_plane, raycaster, true);

    if (!planeIntersect) return;

    pointEnd.copy(planeIntersect.point).sub(worldPositionStart);

    if (mode === 'rotate') {
      offset.copy(pointEnd).sub(pointStart);

      var ROTATION_SPEED = this.isPreview
        ? 0.08
        : 10 /
          worldPosition.distanceTo(
            _tempVector.setFromMatrixPosition(this.camera.matrixWorld),
          );

      if (axis === 'E') {
        rotationAxis.copy(offset).cross(eye).normalize();
        rotationAngle =
          offset.dot(_tempVector.copy(rotationAxis).cross(eye)) * ROTATION_SPEED;
      } else if (axis === 'XYZE') {
        rotationAxis.copy(offset).cross(eye).normalize();
        rotationAngle =
          offset.dot(_tempVector.copy(rotationAxis).cross(eye)) * ROTATION_SPEED;
        console.log({ rotationAngle });
      }

      rotationAxis.applyQuaternion(parentQuaternionInv);
      object.quaternion.copy(
        _tempQuaternion.setFromAxisAngle(rotationAxis, rotationAngle),
      );
      object.quaternion.multiply(quaternionStart).normalize();
      console.log({ rotationAxis });
    }

    this.dispatchEvent(changeEvent);
    this.dispatchEvent(objectChangeEvent);
  };

  this.pointerUp = function (pointer) {
    if (this.measureMode) {
      const clickDuration = Date.now() - this.mouseDownTime;
      console.log('🕒 Click duration:', clickDuration);

      if (clickDuration < this.CLICK_THRESHOLD) {
        // Get mesh information
        console.log('🔷 Mesh info:', {
          position: this.object.position,
          rotation: this.object.rotation,
          scale: this.object.scale,
        });

        const meshIntersects = [];
        this.object.traverse((child) => {
          if (child.isMesh) {
            const intersects = raycaster.intersectObject(child, true);
            meshIntersects.push(...intersects);
          }
        });

        // const intersection = raycaster.intersectObject(this.object, true)[0];
        const intersection = meshIntersects
          .filter((i) => i.object.type === 'Mesh')
          .sort((a, b) => a.distance - b.distance)[0];
        console.log('🎯 Intersection:', intersection);
        console.log('📍 Intersection point:', intersection?.point);

        if (intersection) {
          if (this.points.length >= 2) {
            this.clearMeasurements();
            this.points = [];
          }

          const matrix = this.object.matrixWorld.clone();
          console.log('🔄 Object matrix:', matrix);

          const inverseMatrix = matrix.clone().invert();
          console.log('🔄 Inverse matrix:', inverseMatrix);

          // Calculate relative position
          const meshPosition = this.object.position.clone();
          const worldPoint = intersection.point.clone();
          const relativePosition = worldPoint.clone().sub(meshPosition);

          console.log('📏 Position Analysis:', {
            meshPosition: meshPosition,
            worldPoint: worldPoint,
            relativePosition: relativePosition,
            distance: relativePosition.length(),
          });

          console.log('🔍 Scene hierarchy:', {
            object: this.object.uuid,
            children: this.object.children.length,
          });

          const localPoint = intersection.point.clone().applyMatrix4(inverseMatrix);
          console.log('📌 Local point:', localPoint);
          console.log('🌍 World point:', intersection.point);

          // Create visual elements
          const cross = createCross(localPoint, this.object);

          console.log('🔍 Cross validation:', {
            exists: !!cross,
            type: cross?.type,
            isObject3D: cross instanceof Object3D,
            parent: cross?.parent?.uuid,
          });

          // Add error check and matrix update
          if (cross && cross instanceof Object3D) {
            cross.updateMatrixWorld(true);
            console.log('✚ Cross created at:', {
              local: cross.position.clone(),
              world: cross.getWorldPosition(new Vector3()),
            });
          } else {
            console.warn('❌ Cross creation failed or invalid object returned');
          }

          this.points.push(localPoint);
          this.pointsCount++;
          console.log('📝 Points count:', this.pointsCount);

          if (this.points.length === 2) {
            const line = createLineBetweenPoints(
              this.points[0],
              this.points[1],
              this.object,
            );
            const distance = calculateDistance(this.points[0], this.points[1]);
            const sprite = createTextSprite(
              this.points[0],
              this.points[1],
              distance,
              this.object,
            );

            console.log('📏 Measurement complete:', {
              distance: distance,
              start: this.points[0],
              end: this.points[1],
            });
          }
        }
      }
    }

    this.mouseDownTime = 0;
    this.dragging = false;
    this.axis = null;
  };

  function getPointer(event) {
    var pointer = event.changedTouches ? event.changedTouches[0] : event;
    var rect = domElement.getBoundingClientRect();
    return {
      x: ((pointer.clientX - rect.left) / rect.width) * 2 - 1,
      y: (-(pointer.clientY - rect.top) / rect.height) * 2 + 1,
      button: event.button,
    };
  }

  // mouse / touch event handlers

  function onPointerHover(event) {
    if (!scope.enabled) return;

    switch (event.pointerType) {
      case 'mouse':
      case 'pen':
        scope.pointerHover(getPointer(event));
        break;
      default:
        break;
    }
  }

  function onTouchDown(event) {
    if (!scope.enabled) return;

    event.preventDefault();

    if (event.touches.length === 1) {
      scope.pointerHover(getPointer(event));
      scope.pointerDown(getPointer(event));
    } else if (event.touches.length >= 2) {
      scope.domElement.style.touchAction = '';
      scope.domElement.ownerDocument.removeEventListener('pointermove', onPointerMove);
    }
  }

  function onPointerDown(event) {
    if (!scope.enabled) return;

    scope.domElement.style.touchAction = 'none'; // disable touch scroll
    scope.domElement.ownerDocument.addEventListener('pointermove', onPointerMove);

    scope.pointerHover(getPointer(event));
    scope.pointerDown(getPointer(event));
  }

  function onPointerMove(event) {
    if (!scope.enabled) return;
    scope.pointerMove(getPointer(event));
  }

  function onPointerUp(event) {
    scope.domElement.style.touchAction = '';
    scope.domElement.ownerDocument.removeEventListener('pointermove', onPointerMove);

    if (scope.pointerUp) {
      scope.pointerUp(getPointer(event));
    }
  }

  // TODO: deprecate

  this.getMode = function () {
    return scope.mode;
  };

  this.setMode = function (mode) {
    scope.mode = mode;
  };

  this.setSize = function (size) {
    scope.size = size;
  };
};

TransformControls.prototype = Object.assign(Object.create(Object3D.prototype), {
  constructor: TransformControls,

  isTransformControls: true,
});

var TransformControlsGizmo = function () {
  'use strict';

  Object3D.call(this);

  this.type = 'TransformControlsGizmo';

  // shared materials

  var gizmoMaterial = new MeshBasicMaterial({
    depthTest: false,
    depthWrite: false,
    transparent: true,
    side: DoubleSide,
    fog: false,
    toneMapped: false,
  });

  var gizmoLineMaterial = new LineBasicMaterial({
    depthTest: false,
    depthWrite: false,
    transparent: true,
    linewidth: 1,
    fog: false,
    toneMapped: false,
  });

  // Make unique material for each axis/color

  var matInvisible = gizmoMaterial.clone();
  matInvisible.opacity = 0.15;

  var matHelper = gizmoMaterial.clone();
  matHelper.opacity = 0.33;

  var matLineGray = gizmoLineMaterial.clone();
  matLineGray.color.set(0x787878);

  var lineGeometry = new BufferGeometry();
  lineGeometry.setAttribute(
    'position',
    new Float32BufferAttribute([0, 0, 0, 1, 0, 0], 3),
  );

  var CircleGeometry = function (radius, arc) {
    var geometry = new BufferGeometry();
    var vertices = [];

    for (var i = 0; i <= 64 * arc; ++i) {
      vertices.push(
        0,
        Math.cos((i / 32) * Math.PI) * radius,
        Math.sin((i / 32) * Math.PI) * radius,
      );
    }

    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3));

    return geometry;
  };

  var gizmoRotate = {
    XYZE: [[new Line(CircleGeometry(1, 1), matLineGray), null, [0, Math.PI / 2, 0]]],
  };

  var helperRotate = {
    AXIS: [],
  };

  var pickerRotate = {
    XYZE: [[new Mesh(new SphereGeometry(0.7, 10, 8), matInvisible)]],
  };

  var pickerScale = {
    // X: [
    // 	[new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible), [0.5, 0, 0], [0, 0, - Math.PI / 2]]
    // ],
    // Y: [
    // 	[new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible), [0, 0.5, 0]]
    // ],
    // Z: [
    // 	[new Mesh(new CylinderGeometry(0.2, 0, 0.8, 4, 1, false), matInvisible), [0, 0, 0.5], [Math.PI / 2, 0, 0]]
    // ],
    // XY: [
    // 	[new Mesh(scaleHandleGeometry, matInvisible), [0.85, 0.85, 0], null, [3, 3, 0.2]],
    // ],
    // YZ: [
    // 	[new Mesh(scaleHandleGeometry, matInvisible), [0, 0.85, 0.85], null, [0.2, 3, 3]],
    // ],
    // XZ: [
    // 	[new Mesh(scaleHandleGeometry, matInvisible), [0.85, 0, 0.85], null, [3, 0.2, 3]],
    // ],
    // XYZX: [
    // 	[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [1.1, 0, 0]],
    // ],
    // XYZY: [
    // 	[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [0, 1.1, 0]],
    // ],
    // XYZZ: [
    // 	[new Mesh(new BoxGeometry(0.2, 0.2, 0.2), matInvisible), [0, 0, 1.1]],
    // ]
  };

  // Creates an Object3D with gizmos described in custom hierarchy definition.

  var setupGizmo = function (gizmoMap) {
    var gizmo = new Object3D();

    for (var name in gizmoMap) {
      for (var i = gizmoMap[name].length; i--; ) {
        var object = gizmoMap[name][i][0].clone();
        // var position = gizmoMap[name][i][1];
        var rotation = gizmoMap[name][i][2];
        // var scale = gizmoMap[name][i][3];
        var tag = gizmoMap[name][i][4];

        // name and tag properties are essential for picking and updating logic.
        object.name = name;
        object.tag = tag;

        if (rotation) {
          object.rotation.set(rotation[0], rotation[1], rotation[2]);
        }

        object.updateMatrix();

        var tempGeometry = object.geometry.clone();
        tempGeometry.applyMatrix4(object.matrix);
        object.geometry = tempGeometry;
        object.renderOrder = Infinity;

        object.position.set(0, 0, 0);
        object.rotation.set(0, 0, 0);
        object.scale.set(1, 1, 1);

        gizmo.add(object);
      }
    }

    return gizmo;
  };

  // Gizmo creation

  this.gizmo = {};
  this.picker = {};
  this.helper = {};

  this.add((this.gizmo['rotate'] = setupGizmo(gizmoRotate)));
  this.add((this.picker['rotate'] = setupGizmo(pickerRotate)));
  this.add((this.picker['scale'] = setupGizmo(pickerScale)));
  this.add((this.helper['rotate'] = setupGizmo(helperRotate)));

  // updateMatrixWorld will update transformations and appearance of individual handles

  this.updateMatrixWorld = function () {
    this.gizmo['rotate'].visible = this.mode === 'rotate';

    this.helper['rotate'].visible = this.mode === 'rotate';

    var handles = [];
    handles = handles.concat(this.picker[this.mode].children);
    handles = handles.concat(this.gizmo[this.mode].children);

    for (var i = 0; i < handles.length; i++) {
      var handle = handles[i];

      handle.visible = false;
      handle.rotation.set(0, 0, 0);
      handle.position.copy(this.worldPosition);

      var factor;

      factor =
        this.worldPosition.distanceTo(this.cameraPosition) *
        Math.min(
          (1.9 * Math.tan((Math.PI * this.camera.fov) / 360)) / this.camera.zoom,
          7,
        );

      handle.scale.set(1, 1, 1).multiplyScalar((factor * this.size) / 1.5);
    }

    Object3D.prototype.updateMatrixWorld.call(this);
  };
};

TransformControlsGizmo.prototype = Object.assign(Object.create(Object3D.prototype), {
  constructor: TransformControlsGizmo,

  isTransformControlsGizmo: true,
});

var TransformControlsPlane = function () {
  Mesh.call(
    this,
    new PlaneGeometry(100000, 100000, 2, 2),
    new MeshBasicMaterial({
      visible: false,
      wireframe: true,
      side: DoubleSide,
      transparent: true,
      opacity: 0.1,
      toneMapped: false,
    }),
  );
};

TransformControlsPlane.prototype = Object.assign(Object.create(Mesh.prototype), {
  constructor: TransformControlsPlane,

  isTransformControlsPlane: true,
});

export { TransformControls, TransformControlsGizmo, TransformControlsPlane };
