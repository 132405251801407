import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

const SocialLogin = ({ location }) => {
  const { t } = useTranslation();
  useEffect(() => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (window.opener) {
      // send them to the opening window
      window.opener.postMessage(params);
      // close the popup
      window.close();
    }
  }, [location.search]);

  return <p>{t('Please wait')}...</p>;
};

export default SocialLogin;
