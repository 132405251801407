import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  padding-left: ${(props) => props.paddingLeft || '15px'};
  padding-bottom: ${(props) => props.paddingBottom};

  /* ${(props) =>
    props.styleMore &&
    css`
      margin-top: 7.5px;
      margin-bottom: 7.5px;
    `} */
`;

const ValidateDiv = ({ ...rest }) => {
  // console.log(rest);
  return <Container {...rest}></Container>;
};

export default React.memo(ValidateDiv);
