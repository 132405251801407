import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import HButton from '../common/HButton';
import AddCommentDlg from './AddCommentDlg';
import { listComments, initializeForm, caseContent } from '../../modules/case';
import { editComment, deleteComment, uploadCancel } from '../../lib/api/case';
import { getLocaleDate, getLocaleTime } from '../common/Util';
import MessageBox from '../common/MessageBox';
import { useTranslation } from 'react-i18next';
import { getInitial } from '../common/Util';
import connections from '../../modules/connections';
import SVG from '../common/svg';
import { getODM } from '../../lib/odm';

const CaseDetailCommentsBox = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .commentTitle {
    width: 250px;
    height: 32px;
    margin: 53px 0 0 22px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }

  .commentButton {
    text-align: center;
  }
`;

const CaseDetailCommentBox = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .commentItemBlock {
    margin: 16px 20px 0 19px;
  }

  .commentItemBlock::after {
    content: '';
    display: table;
    clear: both;
  }

  .commentLeftTitlePicture {
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-left: 16px;
    border-radius: 16px;
    background-color: #787f86;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    float: left;
  }

  .commentLeftTitleName {
    /* width: 200px; */
    max-width: 200px
    height: 32px;
    line-height: 32px;
    margin-left: 16px;
    font-size: 13px;
    color: #59616a;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    float: left;
  }

  .commentLeftTitleDate {
    width: auto;
    height: 32px;
    line-height: 32px;
    margin-right: 8px;
    font-size: 13px;
    color: #59616a;
    float: right;
    text-align: right;
  }

  .commentLeftTitleTime {
    width: auto;
    height: 32px;
    line-height: 32px;
    margin-right: 56px;
    font-size: 13px;
    color: #969ba1;
    float: right;
    text-align: right;
  }

  .commentLeftContent {
    width: 100% - 60px;
    height: auto;
    margin: 12px 60px 0 19px;
    padding: 12px 20px 12px 20px;
    font-size: 15px;
    color: #59616a;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background: #eeeff0;
    white-space: pre-wrap;
  }

  .commentRightTitleDate {
    width: auto;
    height: 32px;
    line-height: 32px;
    margin-left: 56px;
    font-size: 13px;
    color: #59616a;
    float: left;
  }

  .commentRightTitleTime {
    width: auto;
    height: 32px;
    line-height: 32px;
    margin-left: 8px;
    font-size: 13px;
    color: #969ba1;
    float: left;
  }

  .commentRightTitlePicture {
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-right: 16px;
    border-radius: 16px;
    background-color: #787f86;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    float: right;
  }

  .commentRightTitleName {
    /* width: 200px; */
    max-width: 200px;
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    font-size: 13px;
    color: #59616a;
    float: right;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    text-align: right;
  }

  .commentRightContent {
    width: calc(100% - 119px);
    height: auto;
    margin: 12px 20px 0 59px;
    padding: 12px 20px 12px 20px;
    font-size: 15px;
    color: #59616a;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    white-space: pre-wrap;
  }

  .commentRightEdit {
    width: calc(100% - 119px);
    height: auto;
    margin: 12px 20px 0 59px;
    padding: 12px 20px 12px 20px;
    font-size: 15px;
    color: #59616a;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background: #ffffff;
    font-family: Source Sans Pro;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    resize: none;
  }

  .commentRightEdit:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.main_color};
  }

  .commentBottomBlock {
    margin: 8px 18px 0 0;
  }

  .commentBottomBlock::after {
    content: '';
    display: table;
    clear: both;
  }
  .deleteIcon {
    width: 24px;
    height: 24px;
    margin: 4px 20px 0 0;
    color: #59616a;
    float: right;
  }

  .editIcon {
    width: 24px;
    height: 24px;
    margin: 4px 12px 0 0;
    color: #59616a;
    float: right;
  }
`;

const StyledImage = styled.img`
  width: ${(props) => props.width || '32px'};
  height: ${(props) => props.height || '32px'};
  margin: 0;
  border: 0;
  padding: 0;
  object-fit: cover; /*contain*/
  border-radius: 60%;
`;

/*
const useDetectOutsideClick = (textArea, initialState) => {
  const [outsideClicked, setOutsideClicked] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      // If the active element exists and is clicked outside of
      if (textArea.current !== null && !textArea.current.contains(e.target)) {
        setOutsideClicked(!outsideClicked);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (outsideClicked) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [outsideClicked, textArea]);

  return [outsideClicked, setOutsideClicked];
};
*/

const CommentContent = ({ comment, odm }) => {
  const { comments } = useSelector(({ caseDetail }) => ({
    comments: caseDetail.comments,
  }));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [editVisible, setEditVisible] = useState(false);
  const [editText, setEditText] = useState('');
  const [editHeight, setEditHeight] = useState(48);

  const textAreaRef = useRef(null);
  //const [outsideClicked, setOutsideClicked] = useDetectOutsideClick(textAreaRef, false);

  /*
  useEffect(() => {
    if (!outsideClicked) {
      setEditVisible(false);
    }
  }, [outsideClicked]);
  */

  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  const [messageData, setMessageData] = useState(0);

  const onMessageBoxOk = async () => {
    setMessageVisible(false);
    try {
      const res = await deleteComment(messageData);
      dispatch(listComments(comments.id));
      dispatch(caseContent(comments.id));
      dispatch(initializeForm('deleteCtm'));
    } catch (e) {
      console.log(e);
    }
  };

  const onMessageBoxCancel = () => {
    setMessageVisible(false);
  };

  const onDeleteComment = (id) => {
    setMessageTitle(t('Warning'));
    setMessageContent(t('Delete comment?'));
    setMessageData(id);
    setMessageVisible(true);
  };

  const onEditComment = () => {
    setEditText(comment.text);
    setEditVisible(true);
    // setOutsideClicked(true);

    let str = comment.text;
    let length = str.split(/\r\n|\r|\n/).length;

    setEditHeight(length * 16);
  };

  const onSaveComment = async (id) => {
    comment.text = editText;
    setEditVisible(false);
    // setOutsideClicked(false);

    try {
      const res = await editComment({ id: comment.id, comment: editText });
      dispatch(listComments(comments.id));
      dispatch(caseContent(comments.id));
    } catch (e) {
      console.log(e);
    }
  };

  const onCancel = () => {
    setEditVisible(false);
    // setOutsideClicked(false);
  };

  const onEditChange = (e) => {
    setEditText(e.target.value);
  };

  if (editVisible) {
    return (
      <div>
        <div>
          <textarea
            ref={textAreaRef}
            name="commentText"
            type="text"
            className="commentRightEdit"
            value={editText}
            style={{
              height: editHeight + 'px',
            }}
            onChange={onEditChange}
          />
        </div>
        <div className={'commentBottomBlock'}>
          <HButton
            category="Primary"
            size="Small"
            text={t('Save')}
            width="auto"
            margin="0 0 0 0"
            float="right"
            onClick={() => onSaveComment(comment.id)}
          />
          <HButton
            category="Tertiary"
            size="Small"
            text={t('Cancel')}
            width="auto"
            margin="0 8px 0 0"
            float="right"
            onClick={onCancel}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div
          className={'commentRightContent'}
          style={{
            background: odm.main_color_focus,
          }}
        >
          {comment.text}
        </div>
        {comment.is_editable && (
          <div className={'commentBottomBlock'}>
            <div
              className={'deleteIcon'}
              onClick={() => onDeleteComment(comment.id)}
              title={t('Delete')}
            >
              <SVG
                name="delete"
                color="#878D93"
                colorOver={odm.main_color_hover}
                colorOut="#878D93"
                colorDown={odm.main_color}
              />
            </div>
            <div className={'editIcon'} onClick={() => onEditComment(comment.id)} title={t('Edit')}>
              <SVG
                name="edit"
                color="#878D93"
                colorOver={odm.main_color_hover}
                colorOut="#878D93"
                colorDown={odm.main_color}
              />
            </div>
          </div>
        )}
        <MessageBox
          visible={messageVisible}
          type="okcancel"
          title={messageTitle}
          content={messageContent}
          onOk={onMessageBoxOk}
          onCancel={onMessageBoxCancel}
        />
      </div>
    );
  }
};

const CommentItem = ({ comment, odm }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const commName = comment.author_is_unknown ? `(${t('Unknown')})` : comment.name;

  if (comment.author != true) {
    return (
      <CaseDetailCommentBox>
        <div className={'commentItemBlock'}>
          {/* picture */}
          {comment.picture == null && (
            // <div className={'commentLeftTitlePicture'}>{getInitial(comment.name)}</div>
            <div className={'commentLeftTitlePicture'}>{getInitial(commName.replace('(', ''))}</div>
          )}
          {comment.picture != null && (
            <div className={'commentLeftTitlePicture'}>
              <StyledImage src={comment.picture} />
            </div>
          )}
          {/* Name */}
          {/* <div className={'commentLeftTitleName'}>{comment.name}</div> */}
          <div className={'commentLeftTitleName'}>{commName}</div>
          {/* Date,Time */}
          <div className={'commentLeftTitleTime'}>
            {getLocaleTime(comment.updated_date, i18n.language)}
          </div>
          <div className={'commentLeftTitleDate'}>
            {getLocaleDate(comment.updated_date, i18n.language)}
          </div>
        </div>
        {/* Comment */}
        <div className={'commentLeftContent'}>{comment.text}</div>
      </CaseDetailCommentBox>
    );
  } else {
    return (
      <CaseDetailCommentBox>
        <div className={'commentItemBlock'}>
          {/* Date,Time */}
          <div className={'commentRightTitleDate'}>
            {getLocaleDate(comment.updated_date, i18n.language)}
          </div>
          <div className={'commentRightTitleTime'}>
            {getLocaleTime(comment.updated_date, i18n.language)}
          </div>
          {/* picture */}
          {comment.picture == null && (
            <div className={'commentRightTitlePicture'}>{getInitial(comment.name)}</div>
          )}
          {comment.picture != null && (
            <div className={'commentRightTitlePicture'}>
              <StyledImage src={comment.picture} />
            </div>
          )}
          {/* Name */}
          <div className={'commentRightTitleName'}>{comment.name}</div>
        </div>
        {/* Comment */}
        <CommentContent comment={comment} odm={odm} />
      </CaseDetailCommentBox>
    );
  }
};

const CaseDetailComments = ({
  OpenAddCommentDlg,
  CaseDetailFileList,
  setOpenAddCommentDlg,
  setCaseDetailFileList,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { comments, content, odm, error, loading } = useSelector(
    ({ caseDetail, loading, app }) => ({
      comments: caseDetail.comments,
      content: caseDetail.content,
      odm: app.odm,
      error: caseDetail.error,
      loading: loading['case/LIST_COMMENTS'],
    }),
  );

  // useEffect(() => {
  //   if (deleteCmt) {
  //     dispatch(listComments(comments.id));
  //     dispatch(initializeForm('deleteCtm'));
  //   }
  // }, [dispatch, deleteCmt]);

  // useEffect(() => {
  //   if (comment) {
  //     dispatch(listComments(comments.id));
  //     dispatch(initializeForm('comment'));
  //   }
  // }, [dispatch, comment]);

  // useEffect(() => {
  //   if (editCmt) {
  //     dispatch(listComments(comments.id));
  //     dispatch(initializeForm('editCmt'));
  //   }
  // }, [dispatch, editCmt]);

  const [addCommentVisible, setAddCommentVisible] = useState(false);
  const [saving, setSaving] = useState(false);

  const onAddComment = () => {
    setAddCommentVisible(true);
  };

  const onSaving = (disableSave) => {
    setSaving(disableSave);
  };

  const onSaved = (e) => {
    setAddCommentVisible(false);
    setOpenAddCommentDlg(false);
  };

  const onCancel = () => {
    setAddCommentVisible(false);
    setOpenAddCommentDlg(false);

    if (saving) {
      uploadCancel.forEach((cancel) => {
        cancel('Operation canceled by the user.');
      });
      uploadCancel.length = 0;
    }
  };

  useEffect(() => {
    if (OpenAddCommentDlg) setAddCommentVisible(true);
  }, [OpenAddCommentDlg]);

  return (
    <CaseDetailCommentsBox>
      <div className={'commentTitle'}>{t('Comments')}</div>
      {comments && (
        <div>
          {comments.comments.map((comment, key) => (
            <CommentItem key={key} comment={comment} odm={odm} />
          ))}
        </div>
      )}
      <div className={'commentButton'}>
        <HButton
          category="Secondary"
          size="Medium"
          text={t('Post a Comment').toUpperCase()}
          margin="72px 0 0 0"
          icon={<SVG name="reply" color={odm.main_color} />}
          onClick={onAddComment}
        />
      </div>
      <AddCommentDlg
        CaseDetailFileList={CaseDetailFileList}
        setCaseDetailFileList={setCaseDetailFileList}
        visible={addCommentVisible}
        caseId={comments.id}
        status={content.status}
        onSaved={onSaved}
        onCancel={onCancel}
        onSaving={onSaving}
      />
    </CaseDetailCommentsBox>
  );
};

export default React.memo(CaseDetailComments);
