import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as caseAPI from '../lib/api/case';
import { takeLatest, takeEvery, put } from 'redux-saga/effects';
// import Cookies from 'universal-cookie';
import * as authAPI from '../lib/api/auth';

// const cookies = new Cookies();

// Action Type
const [LIST_COMMENTS, LIST_COMMENTS_SUCCESS, LIST_COMMENTS_FAILURE] =
  createRequestActionTypes('case/LIST_COMMENTS');

// const [ADD_COMMENT, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAILURE] =
//   createRequestActionTypes('case/ADD_COMMENT');

// const [DELETE_COMMENT, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAILURE] =
//   createRequestActionTypes('case/DELETE_COMMENT');

// const [EDIT_COMMENT, EDIT_COMMENT_SUCCESS, EDIT_COMMENT_FAILURE] =
//   createRequestActionTypes('case/EDIT_COMMENT');

// Files
const [LIST_FILES, LIST_FILES_SUCCESS, LIST_FILES_FAILURE] =
  createRequestActionTypes('case/LIST_FILES');

const [DOWNLOAD_FILE, DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_FAILURE] =
  createRequestActionTypes('case/DOWNLOAD_FILE');

const [DOWNLOAD_2D_FILE, DOWNLOAD_2D_FILE_SUCCESS, DOWNLOAD_2D_FILE_FAILURE] =
  createRequestActionTypes('case/DOWNLOAD_2D_FILE');

// const [
//   DOWNLOAD_FILES_ALL,
//   DOWNLOAD_FILES_ALL_SUCCESS,
//   DOWNLOAD_FILES_ALL_FAILURE,
// ] = createRequestActionTypes('case/DOWNLOAD_FILES_ALL');

// const [UPLOAD_FILE, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE] =
//   createRequestActionTypes('case/UPLOAD_FILE');

const [UPLOAD_FILES_DONE, UPLOAD_FILES_DONE_SUCCESS, UPLOAD_FILES_DONE_FAILURE] =
  createRequestActionTypes('case/UPLOAD_FILES_DONE');

const [DELETE_FILE, DELETE_FILE_SUCCESS, DELETE_FILE_FAILURE] =
  createRequestActionTypes('case/DELETE_FILE');

// Case
// const [CASE_STATUS, CASE_STATUS_SUCCESS, CASE_STATUS_FAILURE] =
//   createRequestActionTypes('case/CASE_STATUS');

const INITIALIZE_FORM = 'case/INITIALIZE_FORM';
const FULLSCREEN = 'case/FULLSCREEN';

const [CASE_INFO, CASE_INFO_SUCCESS, CASE_INFO_FAILURE] =
  createRequestActionTypes('case/CASE_DETAIL');
const [CASE_CONTENT, CASE_CONTENT_SUCCESS, CASE_CONTENT_FAILURE] =
  createRequestActionTypes('case/CASE_CONTENT');
const [CASEPATCOMM_INFO, CASEPATCOMM_INFO_SUCCESS, CASEPATCOMM_INFO_FAILURE] =
  createRequestActionTypes('case/CASEPATCOMM_DETAIL');
const SET_POSTTOKEN = 'auth/SET_POSTTOKEN';
const REMOVE_POSTACCESS = 'auth/REMOVE_POSTACCESS';

// Action - Comment
export const listComments = createAction(LIST_COMMENTS);
// export const addComment = createAction(ADD_COMMENT, ({ id, comment }) => ({
//   id,
//   comment,
// }));
// export const deleteComment = createAction(DELETE_COMMENT);
// export const editComment = createAction(EDIT_COMMENT, ({ id, comment }) => ({
//   id,
//   comment,
// }));

// Action - File
export const listFiles = createAction(LIST_FILES);
export const downloadFile = createAction(DOWNLOAD_FILE, ({ id }) => id);
export const download2DFile = createAction(DOWNLOAD_2D_FILE, ({ id }) => id);
// export const downloadFilesAll = createAction(
//   DOWNLOAD_FILES_ALL,
//   ({ id, files_ids }) => ({ id, files_ids }),
// );
// export const uploadFile = createAction(UPLOAD_FILE, ({ id, formData }) => ({
//   id,
//   formData,
// }));
export const uploadFilesDone = createAction(UPLOAD_FILES_DONE, (id) => id);
export const deleteFile = createAction(DELETE_FILE, (id) => id);

// Action - Case
// export const caseStatus = createAction(CASE_STATUS);
export const initializeForm = createAction(INITIALIZE_FORM, (form) => form);
export const fullScreen = createAction(FULLSCREEN, (fullScreen) => fullScreen);
export const caseInfo = createAction(CASE_INFO, (_case) => _case);
export const caseContent = createAction(CASE_CONTENT);
export const casePatCommInfo = createAction(CASEPATCOMM_INFO, (data) => data);
export const setPostToken = createAction(SET_POSTTOKEN, ({ post_access }) => ({ post_access }));
const removePostAccess = createAction(REMOVE_POSTACCESS);

// Saga - Comment
const listCommentsSaga = createRequestSaga(LIST_COMMENTS, caseAPI.listComments);
// const addCommentSaga = createRequestSaga(ADD_COMMENT, caseAPI.addComment);
// const deleteCommentSaga = createRequestSaga(
//   DELETE_COMMENT,
//   caseAPI.deleteComment,
// );
// const editCommentSaga = createRequestSaga(EDIT_COMMENT, caseAPI.editComment);

// Sage - Files
const listFilesSaga = createRequestSaga(LIST_FILES, caseAPI.listFiles);
const donwloadFileSaga = createRequestSaga(DOWNLOAD_FILE, caseAPI.downloadFile);
const download2DFileSaga = createRequestSaga(DOWNLOAD_2D_FILE, caseAPI.downloadFile);
// const downloadFilesAllSaga = createRequestSaga(
//   DOWNLOAD_FILES_ALL,
//   caseAPI.downloadFilesAll,
// );
// const uploadFileSaga = createRequestSaga(UPLOAD_FILE, caseAPI.uploadFile);
const uploadFilesDoneSaga = createRequestSaga(UPLOAD_FILES_DONE, caseAPI.uploadFilesDone);
const deleteFileSaga = createRequestSaga(DELETE_FILE, caseAPI.deleteFile);

// Saga - Case
// const caseStatusSaga = createRequestSaga(CASE_STATUS, caseAPI.caseStatus);
const caseInfoSaga = createRequestSaga(CASE_INFO, caseAPI.caseDetail);
const caseContentSaga = createRequestSaga(CASE_CONTENT, caseAPI.caseDetailContent);
const casePatCommInfoSaga = createRequestSaga(CASEPATCOMM_INFO, caseAPI.casePatCommDetail);

function* caseDetailContentSuccessSaga({ payload }) {
  yield put({
    type: 'cases/UPDATE_CASE',
    payload,
  });
}
function* setPostTokenSaga({ payload }) {
  authAPI.setToken({ access: payload.post_access });
  yield put(removePostAccess());
}
function* casePatCommInfoSuccessSaga({ payload }) {
  yield put(setPostToken(payload));
}

// function caseInfoFailureSaga({ payload: e }) {
//   const refreshCookie = cookies.get('refresh');
//   if (e.response.status === 401 && !refreshCookie) {
//     window.location.href = '/';
//   }
// }

// function downloadFilesAllSuccessSaga({ payload, meta }) {
//   const url = window.URL.createObjectURL(new Blob([payload]));
//   const link = document.createElement('a');
//   link.href = url;
//   // get file name
//   let filename = '';
//   const disposition = meta.headers['content-disposition'];
//   if (disposition) {
//     var filenameRegex =
//       /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
//     var matches = filenameRegex.exec(disposition);
//     if (matches != null && matches[1]) {
//       filename = matches[1];
//     }
//   }
//   link.setAttribute('download', filename); //or any other extension
//   document.body.appendChild(link);
//   link.click();
// }

export function* caseDetailSaga() {
  yield takeLatest(LIST_COMMENTS, listCommentsSaga);
  // yield takeLatest(ADD_COMMENT, addCommentSaga);
  // yield takeLatest(DELETE_COMMENT, deleteCommentSaga);
  // yield takeLatest(EDIT_COMMENT, editCommentSaga);
  yield takeLatest(LIST_FILES, listFilesSaga);
  yield takeEvery(DOWNLOAD_FILE, donwloadFileSaga);
  yield takeEvery(DOWNLOAD_2D_FILE, download2DFileSaga);
  // yield takeLatest(DOWNLOAD_FILES_ALL, downloadFilesAllSaga);
  // yield takeLatest(DOWNLOAD_FILES_ALL_SUCCESS, downloadFilesAllSuccessSaga);
  // yield takeLatest(UPLOAD_FILE, uploadFileSaga);
  yield takeLatest(UPLOAD_FILES_DONE, uploadFilesDoneSaga);
  yield takeLatest(DELETE_FILE, deleteFileSaga);
  // yield takeLatest(CASE_STATUS, caseStatusSaga);
  yield takeLatest(CASE_INFO, caseInfoSaga);
  yield takeLatest(CASE_CONTENT, caseContentSaga);
  yield takeLatest(CASE_CONTENT_SUCCESS, caseDetailContentSuccessSaga);
  // yield takeLatest(CASE_INFO_FAILURE, caseInfoFailureSaga);
  yield takeLatest(CASEPATCOMM_INFO, casePatCommInfoSaga);
  yield takeLatest(CASEPATCOMM_INFO_SUCCESS, casePatCommInfoSuccessSaga);
  yield takeLatest(SET_POSTTOKEN, setPostTokenSaga);
}

// Init
const initialState = {
  content: null,
  comments: null,
  files: null,
  error: null,
  comment: null,
  deleteCmt: null,
  editCmt: null,
  // caseInfo: null,
  file2D: null,
  deleteFile: null,
  // upload: null,
  // uploadDone: null,
  fullScreen: false,
  initialMeshesTransform: [],
};

const caseDetail = handleActions(
  {
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      conn: null,
      error: null,
    }),
    [FULLSCREEN]: (state, { payload: fullScreen }) => ({
      ...state,
      fullScreen,
    }),
    [LIST_COMMENTS_SUCCESS]: (state, { payload: comments }) => ({
      ...state,
      comments,
      error: null,
    }),
    [LIST_COMMENTS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [LIST_FILES_SUCCESS]: (state, { payload: files }) => ({
      ...state,
      files,
      error: null,
    }),
    [LIST_FILES_FAILURE]: (state, { payload: error }) => ({ ...state, error }),

    // [ADD_COMMENT_SUCCESS]: (state, { payload: comment }) => ({
    //   ...state,
    //   comment,
    //   error: null,
    // }),
    // [ADD_COMMENT_FAILURE]: (state, { payload: error }) => ({ ...state, error }),

    // [DELETE_COMMENT_SUCCESS]: (state, { payload: deleteCmt }) => ({
    //   ...state,
    //   deleteCmt,
    //   error: null,
    // }),
    // [DELETE_COMMENT_FAILURE]: (state, { payload: error }) => ({
    //   ...state,
    //   error,
    // }),
    [DOWNLOAD_FILE_SUCCESS]: (state, { payload: file3D }) => ({
      ...state,
      file3D,
      error: null,
    }),
    [DOWNLOAD_FILE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [DOWNLOAD_2D_FILE_SUCCESS]: (state, { payload: file2D }) => ({
      ...state,
      file2D,
      error: null,
    }),
    [DOWNLOAD_2D_FILE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    // [EDIT_COMMENT_SUCCESS]: (state, { payload: editCmt }) => ({
    //   ...state,
    //   editCmt,
    //   error: null,
    // }),
    // [EDIT_COMMENT_FAILURE]: (state, { payload: error }) => ({
    //   ...state,
    //   error,
    // }),

    // [DOWNLOAD_FILES_ALL_SUCCESS]: (state, { payload }) => ({
    //   ...state,
    //   error: null,
    // }),
    // [DOWNLOAD_FILES_ALL_FAILURE]: (state, { payload: error }) => ({
    //   ...state,
    //   error,
    // }),

    // [CASE_STATUS_SUCCESS]: (state, { payload: status }) => ({
    //   ...state,
    //   status,
    //   error: null,
    // }),
    // [CASE_STATUS_FAILURE]: (state, { payload: error }) => ({ ...state, error }),

    // [UPLOAD_FILE_SUCCESS]: (state, { payload: upload }) => ({
    //   ...state,
    //   upload,
    //   error: null,
    // }),
    // [UPLOAD_FILE_FAILURE]: (state, { payload: error }) => ({
    //   ...state,
    //   error,
    // }),
    [UPLOAD_FILES_DONE_SUCCESS]: (state, { payload: uploadDone }) => ({
      ...state,
      uploadDone,
      error: null,
    }),
    [UPLOAD_FILES_DONE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CASE_INFO_SUCCESS]: (state, { payload: caseInfo }) => ({
      ...state,
      // caseInfo,
      content: caseInfo.content,
      files: caseInfo.files,
      comments: caseInfo.comments,
      error: null,
    }),
    [CASE_INFO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CASE_CONTENT_SUCCESS]: (state, { payload: content }) => ({
      ...state,
      content,
      error: null,
    }),
    [CASE_CONTENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CASEPATCOMM_INFO_SUCCESS]: (state, { payload: caseInfo }) => ({
      ...state,
      content: caseInfo.content,
      files: caseInfo.files,
      error: null,
    }),
    [CASEPATCOMM_INFO_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [DELETE_FILE_SUCCESS]: (state, { payload: deleteFile }) => ({
      ...state,
      deleteFile,
      error: null,
    }),
    [DELETE_FILE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REMOVE_POSTACCESS]: (state) => {
      if (state.case && state.case.access) delete state.auth.access;
      return state;
    },
  },
  initialState,
);

export default caseDetail;
