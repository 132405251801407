import React from 'react';
import qs from 'qs';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { listCases } from '../../modules/cases';
import SVG from '../common/svg';
import { useTranslation } from 'react-i18next';

const MainBlock = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: normal;
  font-size: 15px;

  width: calc(100% - 2px);
  height: 47px;
  background: white;
  display: grid;
  grid-template-columns: auto 40px 40px 16px;
  grid-template-rows: 48px;
  gap: 0;

  border-top: solid 1px #eeeff0;
  border-left: solid 1px #eeeff0;
  border-right: solid 1px #eeeff0;

  .areaPageInfo {
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    justify-self: left;
    margin-left: 20px;

    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #969ba1;
  }

  .areaPrevPageButton {
    grid-column: 2;
    grid-row: 1;
    margin: 0px;
    align-self: center;
    justify-self: center;
  }

  .areaNextPageButton {
    grid-column: 3;
    grid-row: 1;
    margin: 0px;
    align-self: center;
    justify-self: center;
  }

  img {
    background: none;
    outline: none;
    border: none;
    width: 24px;
    height: 24px;
    visibility: visible;
    display: flex;
    align-items: center;
    float: right;
  }
`;

const Pagination = ({ filters, page }) => {
  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  const { t, i18n } = useTranslation();
  const _curPage = useSelector((state) => state.lastPage);
  let history = useHistory();
  const dispatch = useDispatch();
  /*  console.log("page >> " + page.number + "  LastPage >> ");*/

  const onClickPrev = () => {
    if (page.has_previous) {
      dispatch(listCases({ filters, page: page.number - 1 }));
    }
  };

  const onClickNext = () => {
    if (page.has_next) {
      dispatch(listCases({ filters, page: page.number + 1 }));
    }
  };

  return (
    <MainBlock>
      {page.total_num > 0 && (
        <>
          <div className={'areaPageInfo'}>
            {GetPageFrom(page)} - {GetPageTo(page)} / {page.total_num}
          </div>
          <div
            className={'areaPrevPageButton'}
            onClick={() => onClickPrev()}
            title={t('Previous page')}
          >
            <SVG
              name="arrow_left"
              color={page.has_previous ? '#878D93' : '#C3C6C9'}
              colorOver={page.has_previous ? odm.main_color_hover : '#C3C6C9'}
              colorOut={page.has_previous ? '#878D93' : '#C3C6C9'}
              colorDown={page.has_previous ? odm.main_color : '#C3C6C9'}
            />
          </div>
          <div
            className={'areaNextPageButton'}
            onClick={() => onClickNext()}
            title={t('Next Page')}
          >
            <SVG
              name="arrow_right"
              color={page.has_next ? '#878D93' : '#C3C6C9'}
              colorOver={page.has_previous ? odm.main_color_hover : '#C3C6C9'}
              colorOut={page.has_previous ? '#878D93' : '#C3C6C9'}
              colorDown={page.has_previous ? odm.main_color : '#C3C6C9'}
            />
          </div>
        </>
      )}
    </MainBlock>
  );
};

export default Pagination;

function GetPageFrom(page) {
  return (page.number - 1) * page.num_per_page + 1;
}

function GetPageTo(page) {
  var _tempLast = page.number * page.num_per_page;
  return _tempLast > page.total_num ? page.total_num : _tempLast;
}
