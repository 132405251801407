import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
    createRequestActionTypes,
} from '../lib/createRequestSaga';
import * as leftpanelAPI from '../lib/api/leftPanel';
import { takeLatest } from 'redux-saga/effects';

const SELECTED_MENU = 'leftpanel/SELECTED_MENU';
const SELECTED_CASE_STATUS = 'leftpanel/SELECTED_CASE_STATUS';
const COLLAPSED = 'leftpanel/COLLAPSED';
const COLLAPSED_AUTO = 'leftpanel/COLLAPSED_AUTO';
const [
    LIST_ALARM,
    LIST_ALARM_SUCCESS,
    LIST_ALARM_FAILURE
] = createRequestActionTypes('leftpanel/LIST_ALARM');

export const selectedMenu = createAction(SELECTED_MENU); //string
//export const selectedCaseStatus = createAction(SELECTED_CASE_STATUS); //string
export const collapsed = createAction(COLLAPSED); // boolean
export const collapsedAuto = createAction(COLLAPSED_AUTO); // boolean
export const listAlarms = createAction(LIST_ALARM);

{/* Saga */ }
const listAlarmSaga = createRequestSaga(LIST_ALARM, leftpanelAPI.listAlarms);
export function* alarmSaga() {
    yield takeLatest(LIST_ALARM, listAlarmSaga);
}

const initialState = {
    menu: "Tableau de bord",
    //caseStatus: "All",
    collapsed: false,
    collapsedAuto: false,
    alarms: null,
    error: null
};

const leftpanel = handleActions(
    {
        [LIST_ALARM_SUCCESS]: (state, { payload: alarms }) => ({
            ...state,
            alarms,
            error: null
        }),
        [LIST_ALARM_FAILURE]: (state, { payload: error }) => ({
            ...state,
            error,
            alarms: null
        }),
        [SELECTED_MENU]: (state, action) => (
            {
                ...state,
                menu: action.payload
            }
        ),
        // [SELECTED_CASE_STATUS]: (state, action) => (
        //     {
        //         ...state,
        //         caseStatus: action.payload
        //     }
        // ),
        [COLLAPSED]: (state, action) => (
            {
                ...state,
                collapsed: action.payload
            }
        ),
        [COLLAPSED_AUTO]: (state, action) => (
            {
                ...state,
                collapsedAuto: action.payload
            }
        )
    },
    initialState,
);

export default leftpanel;
