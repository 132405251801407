import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import CaseDetail from './CaseDetail';

const CaseDetailNotesBox = styled.div`
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .noteTitle {
    width: 250px;
    height: 32px;
    margin: 53px 0 0 22px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }

  .noteBox {
    width: calc(100% - 39px);
    height: auto;
    margin: 8px 20px 0 19px;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background: #eeeff0;
  }  

  .noteContents {
    font-size: 15px;
    margin: 10px 20px 10px 19px;
    display: flex;
    justify-content: Left;
    align-items: center;
    word-wrap: break-word;
    word-break: break-all;    
    color: #59616A;
  }
`;

const CaseDetailNotes = ({ content }) => {
  const { t } = useTranslation();

  return (
    <CaseDetailNotesBox>
      {content.note && (
        <div>
          <div className={'noteTitle'}>{t('Note')}</div>
          <div className={'noteBox'}>
            <div className={'noteContents'}>{content.note}</div>
          </div>
        </div>
      )}
    </CaseDetailNotesBox>
  );
};

export default CaseDetailNotes;
