import React, { useState } from 'react';
import SocialSignupContainer from '../containers/auth/SocialSignupContainer';
import styled from 'styled-components';

const Body = styled('div')`
  min-width: 991px;
  min-height: 100vh;
  margin: auto;
  background: #eeeff0;
  /* overflow-x: hidden;
  overflow-y: auto; */
`;

const SocialSignupPage = () => {
  return (
    <Body>
      <SocialSignupContainer />
    </Body>
  );
};

export default SocialSignupPage;
