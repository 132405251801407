export function getInitial(fullname) {
  if (fullname === '') {
    return '';
  }

  const names = fullname.split(' ');

  // Korean
  var pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  if (pattern_kor.test(fullname)) {
    if (names.length === 1) {
      return names[0].charAt(0);
    } else {
      return names[1].charAt(0);
    }
  }

  // Japanese
  var pattern_jap =
    /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;
  if (pattern_jap.test(fullname)) {
    return names[0].charAt(0);
  }

  // Chinese
  var pattern_chi = /[\u4e00-\u9fa5]/;
  if (pattern_chi.test(fullname)) {
    return names[0].charAt(0);
  }

  // Others
  if (names.length === 1) {
    const first = fullname.toUpperCase();
    return first.charAt(0);
  } else {
    const first = names[0].toUpperCase();
    const last = names[1].toUpperCase();
    return first.charAt(0) + last.charAt(0);
  }
}

export function GetLocale(lang) {
  if (lang === 'de') {
    return 'de-DE';
  } else if (lang === 'es') {
    return 'es-ES';
  } else if (lang === 'fr') {
    return 'fr-FR';
  } else if (lang === 'ja') {
    return 'ja-JP';
  } else if (lang === 'ko') {
    return 'ko-KR';
  } else {
    return 'en-US';
  }
}

export function getLocaleDate(dateString, lang) {
  var date = new Date(dateString);
  if (isNaN(date)) return '';
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString(GetLocale(lang), options);
}

export function getLocaleTime(dateString, lang) {
  var date = new Date(dateString);
  if (isNaN(date)) return '';
  return date.toLocaleTimeString(GetLocale(lang));
}

export function getStatusString(code) {
  if (code === 'OPEN' || code === 'READ') return 'Open';
  else if (code === 'PROC') return 'In Progress';
  else if (code === 'COMP') return 'Completed';
  else if (code === 'REOP') return 'Reopened';
  else if (code === 'CLSD') return 'Closed';
}

export default { getInitial, getLocaleDate, getLocaleTime, getStatusString };
