import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  .menu-container {
    position: relative;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }

  .menu-trigger {
    display: flex;
    background: ${(props) => props.background || '#ffffff'};

    //border: solid 1px #e7e9ea;
    border: ${(props) => props.border || 'solid 1px #e7e9ea'};
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    align-items: center;
    vertical-align: middle;
    /* justify-content: space-between; */
    /* transition: box-shadow 0.4s ease; */

    /* Strictly for positioning */

    &:hover {
      border: ${(props) => props.border || 'solid 1px #eeeff0'};
      background: ${(props) => props.hoverColor || '#f5f6f6'};
    }

    &:active {
      border: ${(props) =>
        props.border || 'solid 1px ' + props.theme.main_color};
      background: ${(props) => props.background || '#ffffff'};
    }

    &:disabled {
      border: ${(props) => props.border || 'solid 1px #e7e9ea'};
      background: ${(props) => props.hoverColor || '#f5f6f6'};
    }

    span {
      width: auto;
      height: auto;
      margin-left: 10px;
      padding-right: 40px;
      color: ${(props) => props.fontColor || '#59616a'};
      font-family: 'Source Sans Pro';
      font-size: ${(props) => props.fontSize || '15px'};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* vertical-align: middle; */
      /* &:hover {
        color: #c3c6c9; 
        //span이 아닌 button의 hover이벤트를 받아야하는데 어떻게 할지...
      } */
    }

    img {
      position: absolute;
      right: 0;
      margin: ${(props) => props.arrowMargin || '6px 6px 6px 0'};
      width: 24px;
      height: 24px;
    }
  }

  .menu {
    position: absolute;
    //right: 25;
    //top: 44px;
    background: #ffffff; //${(props) => props.background || '#ffffff'};
    border: ${(props) => props.border || 'solid 1px #e7e9ea'};
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    //border: solid 1px #e7e9ea;
    opacity: 0;
    visibility: hidden;
    //transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    display: block;
    //margin: 2px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      &:first-child {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
    li {
      height: 40px;

      &:hover {
        background: #eeeff0; //${(props) => props.menuHoverColor || '#eeeff0'};
      }
    }
    li div {
      font-family: Source Sans Pro;
      font-size: 15px; //${(props) => props.fontSize || '15px'};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #59616a; //${(props) => props.fontColor || '#59616a'};
      //overflow: hidden;
      //text-overflow: ellipsis;
      //white-space: normal;
      /* vertical-align: middle;
      display: table-cell; */
      height: 100%;
      //white-space:nowrap;

      padding: 0px 0px 0px 15px;
      //margin-right: 20px;
      //vertical-align: center;
      display: block;
    }

    li span {
    }
  }

  .active {
    opacity: 1;
    visibility: visible;
    //transform: translateX(10);
  }
`;

const InputSelect = ({
  items,
  placeholder,
  onSelect,
  onCancel,
  width,
  height,
  selItem,
  border,
  background,
  hoverColor,
  menuHoverColor,
  arrowImagePath,
  fontColor,
  fontSize,
  arrowMargin,
  reset,
}) => {
  const { t } = useTranslation();

  const useDetectOutsideClick = (el, initialState) => {
    const [isActive, setIsActive] = useState(initialState);

    useEffect(() => {
      const onClick = (e) => {
        // If the active element exists and is clicked outside of
        if (el.current !== null && !el.current.contains(e.target)) {
          setIsActive(!isActive);
        }
      };

      // If the item is active (ie open) then listen for clicks outside
      if (isActive) {
        window.addEventListener('click', onClick);
      }

      return () => {
        window.removeEventListener('click', onClick);
      };
    }, [isActive, el]);

    return [isActive, setIsActive];
  };

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [selectedItem, setSelectedItem] = useState('');

  const onClick = () => {
    setIsActive(!isActive);
  };

  var menuTop = parseInt(height) + 4 + 'px';
  var menuWidth = width === '100%' ? '100%' : parseInt(width) - 2 + 'px';

  useEffect(() => {
    if (selItem !== undefined) setSelectedItem(selItem);
  }, [selItem]);

  useEffect(() => {
    if (reset > 0) setSelectedItem('');
  }, [reset]);

  return (
    <Container
      border={border}
      background={background}
      hoverColor={hoverColor}
      fontColor={fontColor}
      fontSize={fontSize}
      menuHoverColor={menuHoverColor}
      arrowMargin={arrowMargin}
    >
      <div className="menu-container" style={{ width: width, height: height }}>
        <button
          className="menu-trigger"
          style={{ width: width }}
          onClick={onClick}
          type="button"
        >
          <span>{selectedItem == '' ? placeholder : t(selectedItem)}</span>
          <img
            src={
              process.env.PUBLIC_URL +
              (arrowImagePath === undefined
                ? '/images/login/icon-24-arrow-down-active.svg'
                : arrowImagePath)
            }
            alt="Arrow-down"
          />
        </button>
        <nav
          ref={dropdownRef}
          className={`menu ${isActive ? 'active' : 'inactive'}`}
          style={{ top: menuTop }}
        >
          <ul>
            {items &&
              items.map((item, index) => (
                <li key={index}>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedItem(item);
                      onSelect(item);
                      onClick();
                    }}
                  >
                    <table
                      style={{
                        tableLayout: 'fixed',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t(item)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </Container>
  );
};
export default InputSelect;
