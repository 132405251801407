import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Logo from './Logo';
// import { useDispatch, useSelector } from 'react-redux';
// import { activate } from '../../../modules/auth';
import LinkButton from '../styled/LinkButton';
import { withRouter } from 'react-router-dom';
import { activate, requestActivate } from '../../../lib/api/auth';
import { deviceConfirmEmail } from '../../../lib/api/devices';
import MobileTop from './MobileTop';
import { TabletOrDesktop } from '../../../lib/responsive';

const Body = styled('div')`
  min-width: 360px;
  width: 100%;
  height: 100%;
  margin: 0;
  background: #eeeff0;
`;

const Container = styled.div`
  display: flex;
  min-height: 798px;
  height: 100%;
  background-color: #eeeff0;
  justify-content: center;
  /* user-select: none; */

  .FormContainer2 {
    margin-top: ${(props) => (props.mobile ? '80px' : '277px')};
    text-align: center;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
`;

const Text = styled.div`
  display: flex;
  margin-left: ${(props) => (props.mobile ? 'auto' : '0px')};
  margin-right: ${(props) => (props.mobile ? 'auto' : '0px')};
  margin-top: ${(props) => props.marginTop};
  width: ${(props) => (props.mobile ? '288px' : '416px')};
  height: ${(props) => props.height};
  background-color: transparent;
  flex-direction: row;

  font-family: 'Source Sans Pro';
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => props.lineHeight || 'normal'};
  letter-spacing: ${(props) => props.letterSpacing || 'normal'};
  text-align: ${(props) => (props.mobile ? 'center' : 'left')};
  justify-content: ${(props) => (props.mobile ? 'center' : 'left')};
  color: ${(props) => props.color || '#ff6d22'};
`;

const Notification = ({
  location,
  history,
  match,
  title,
  content,
  width,
  height,
  src,
  homeLink,
}) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const [homeLinkB, setHomeLinkB] = useState(homeLink);
  const [reSend, setReSend] = useState(false);
  const [titleM, setTitleM] = useState(title);
  const [contentM, setContentM] = useState(content);
  const { uid } = match.params;
  const isTabletOrDesktop = TabletOrDesktop();

  const onActivate = async (uidb64, token) => {
    try {
      await activate({ uidb64, token }).then((result) => {
        setTitleM(t('Your account is now activated'));
        setContentM(t('Now you can log in.'));
        setHomeLinkB(true);
      });
    } catch (e) {
      if (e.response.data.detail === 'account activation link invalid') {
        setTitleM(t('Account activation link invalid'));
        setContentM(
          t(
            'If your account has not been activated yet, Please log in to get the account activation link again.',
          ),
        );
        setHomeLinkB(true);
      }
    }
  };

  const onConfirmEmail = async (rdidb64, token, sid) => {
    try {
      if (sid) {
        const srv_loc = Buffer.from(sid, 'base64').toString('utf8');
        await deviceConfirmEmail({ rdidb64, token, srv_loc }).then((result) => {
          setTitleM(t('Your email is now confirmed'));
          setContentM(t('Now you can continue to register your device.'));
        });
      } else {
        setTitleM(t('Email confirmation link invalid'));
        setContentM(t('No server location'));
      }
    } catch (e) {
      if (e.response.data.detail === 'device email confirmation link invalid') {
        setTitleM(t('Email confirmation link invalid'));
        setContentM(
          t(
            'If your email has not been confirmed yet, Please click confirm email to get the email confirmation link again.',
          ),
        );
      } else if (e.response.data.detail === 'server is wrong') {
        setTitleM(t('Email confirmation link invalid'));
        setContentM(t('Server location parameter is invalid.'));
      }
    }
  };

  const onResend = async () => {
    try {
      await requestActivate({ uid }).then((result) => {
        setTitleM(t("We've re-sent an email to you with an activation link"));
        setContentM(
          t(
            'Please check your inbox. If you haven’t received an email within the next 2 minutes please check your spam folder',
          ),
        );
        setReSend(false);
        setHomeLinkB(true);
      });
    } catch (e) {
      setTitleM(t('Failed to send mail'));
      setContentM(t('Please try again later.'));
    }
  };

  useEffect(() => {
    const { uidb64 } = match.params;
    const { rdidb64 } = match.params;
    const { token } = match.params;
    if (uidb64 !== undefined && token !== undefined) {
      // dispatch(
      //   activate({ uidb64, token }),
      // );
      onActivate(uidb64, token);
    } else if (rdidb64 !== undefined && token !== undefined) {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      onConfirmEmail(rdidb64, token, params.sid);
    }

    if (uid) {
      setReSend(true);
      setTitleM(t('This account requires activation'));
      setContentM(
        t(
          'If you want to receive the account activation link again, click the Resend button below.',
        ),
      );
    }
  }, []);

  return (
    <Body>
      {isTabletOrDesktop && <Logo></Logo>}
      {!isTabletOrDesktop && <MobileTop></MobileTop>}
      <Container mobile={!isTabletOrDesktop}>
        <div className="FormContainer2">
          <Text
            mobile={!isTabletOrDesktop}
            // width={width}
            // height={height}
            color="#59616a"
            fontWeight="600"
            fontSize="25px"
          >
            {t(titleM)}
          </Text>
          <Text
            mobile={!isTabletOrDesktop}
            // height="48px"
            color="#969ba1"
            fontSize="15px"
            lineHeight="1.6"
            marginTop="16px"
          >
            {t(contentM)}
          </Text>

          {homeLinkB && (
            <div className="link">
              <LinkButton
                width={isTabletOrDesktop ? '448px' : '320px'}
                onClick={() => history.push('/')}
              >
                {t('Home').toUpperCase()}
              </LinkButton>
            </div>
          )}
          {reSend && (
            <div className="link">
              <LinkButton
                width={isTabletOrDesktop ? '448px' : '320px'}
                onClick={onResend}
              >
                {t('Resend').toUpperCase()}
              </LinkButton>
            </div>
          )}
        </div>
      </Container>
    </Body>
  );
};

Notification.defaultProps = {
  width: '416px',
  height: '',
};

export default withRouter(Notification);
