import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { socialLoginValidateState } from '../../lib/api/auth';
import Logo from './styled/Logo';
import qs from 'qs';
import { useSelector } from 'react-redux';

const Container = styled.div`
  /* display: flex; */
  min-width: 991px;
  min-height: 798px;
  width: 100%;
  height: 100%;
  background-color: #eeeff0;
  /* justify-content: center;
  align-items: center; */
  /* user-select: none; */

  .FormContainer2 {
    margin-left: auto;
    margin-right: auto;
    width: 416px;
    margin-top: 277px;
  }
`;

const Text = styled.div`
  display: flex;
  margin-left: ${(props) => props.marginLeft || '48px'};
  margin-top: ${(props) => props.marginTop};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: transparent;
  flex-direction: row;

  font-family: Source Sans Pro;
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => props.lineHeight || 'normal'};
  letter-spacing: ${(props) => props.letterSpacing || 'normal'};
  text-align: ${(props) => props.textAlign || 'center'};
  justify-content: ${(props) => props.textAlign || 'center'};
  color: ${(props) => props.color || '#ff6d22'};
`;

// const SocialLoginComplete = ({ match }) => {
const SocialLoginComplete = ({ location }) => {
  const { t } = useTranslation();
  // const { state } = match.params;
  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const reqType = location.pathname.includes('/login-social') ? 'api' : null;

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));
  const width = '500px';
  const height = '64px';
  const [titleM, setTitleM] = useState(null);
  const [contentM, setContentM] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (params.state) validateState(params.state, reqType);
    else {
      setLoading(false);
      setTitleM(t('Invalid access'));
      //   setContentM('No state parameter found.');
    }
  }, []);

  const validateState = async (state, type) => {
    try {
      setLoading(true);
      await socialLoginValidateState({ state }).then((res) => {
        console.log(res);
        setTitleM(t('Account connected successfully!'));
        const msg = t(
          'You can now close this browser tab and return to 3DiscClinic',
        ).replace('3DiscClinic', type === 'api' ? 'your App' : odm.hclinic_name);
        setContentM(msg);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      setTitleM(t('Invalid access'));
      //   setContentM('Failed..');
    }
  };

  return (
    <Container>
      {!loading && (
        <>
          <Logo></Logo>
          <div className="FormContainer2">
            <Text
              width={width}
              height={height}
              color="#59616a"
              fontWeight="600"
              fontSize="25px"
              marginLeft="0"
              textAlign="left"
            >
              {t(titleM)}
            </Text>
            <Text
              width="416px"
              height="48px"
              color="#969ba1"
              fontSize="15px"
              lineHeight="1.6"
              marginTop="16px"
              marginLeft="0"
              textAlign="left"
            >
              {t(contentM)}
            </Text>
          </div>
        </>
      )}
    </Container>
  );
};

export default SocialLoginComplete;
