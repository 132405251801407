import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import HButton from '../common/HButton';
import StatusSelect from './StatusSelect';
import Cookies from 'universal-cookie';
import { startCursorLoading } from '../../modules/cursorLoading';

const cookies = new Cookies();

const CaseDetailTopBox = styled.div`
  width: 100%;
  height: auto;

  .caseDetailTopBlock {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 16px;
  }

  .caseDetailTopBlock::after{
    content: "";
    display: table;
    clear: both;
  }

  .caseDetailDownload {
    float: left;
    margin-right: 16px;
    margin-bottom: 8px;
  }

  .caseDetailStatus {
    float: left;
    /* margin-left: 16px; */
    margin-bottom: 16px;
  }
  .caseDetailAccept {
    float: right;
    margin-left: 16px;
    margin-bottom: 16px;
  }

  .caseDetailReject {
    float: right;
    margin-bottom: 16px;
  }
}
`;

const CaseDetailTop = ({ content, files, user, downloadFilesAll, cursorLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [resetCombo, setResetCombo] = useState(1);

  const onClickDownloadAllFiles = () => {
    // // const uri = `/heclore/v1/cases/${content.id}/files/download-all/`;
    // const events_token = cookies.get('events');
    // if (events_token) {
    //   const uidb64 = user.idb64;
    //   const uri = `/heclore/v1/cases/${files.id}/files/download/${uidb64}/${events_token}/`;
    //   window.location.href = uri;
    // } else {
    //   console.log("The Cookie 'events' not exist. Please refresh.");
    // }
    dispatch(startCursorLoading('caseDownLoading'));
    downloadFilesAll(files.id);
  };

  const getStatusString = () => {
    if (content.status.code === 'OPEN' || content.status.code === 'READ')
      return t('Open');
    else if (content.status.code === 'PROC') return t('In Progress');
    else if (content.status.code === 'COMP') return t('Completed');
    else if (content.status.code === 'REOP') return t('Reopened');
    else if (content.status.code === 'CLSD') return t('Closed');
  };

  const onClickAccepCase = () => {};
  const onClickRejectCase = () => {};

  return (
    <CaseDetailTopBox>
      <div className={'caseDetailTopBlock'}>
        <div className={'caseDetailDownload'}>
          <HButton
            category="Tertiary"
            size="Medium"
            text={t('Download All Files').toUpperCase()}
            width="176px"
            onClick={onClickDownloadAllFiles}
            disabled={cursorLoading}
            mouseWait={cursorLoading}
          />
        </div>
        <div className={'caseDetailStatus'}>
          <StatusSelect content={content} width="150px" height="40px" />
        </div>
        {/*
        <div className={'caseDetailAccept'}>
          <HButton
            category="Primary"
            size="Medium"
            text={t('Accept Case').toUpperCase()}
            width="123px"
            onClick={onClickAccepCase}
          />
        </div>
        <div className={'caseDetailReject'}>
          <HButton
            category="Secondary"
            size="Medium"
            text={t('Reject Case').toUpperCase()}
            width="123px"
            onClick={onClickRejectCase}
          />
        </div>
        */}
      </div>
    </CaseDetailTopBox>
  );
};

export default CaseDetailTop;
