import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Logo from './styled/Logo';
import Input from './styled/Input';
import Button from './styled/Button';
import DropdownList from './styled/DropdownList';
import { NavLink, withRouter } from 'react-router-dom';
import ErrorList from './styled/ErrorList';
import LinkButton from './styled/LinkButton';
import MobileTop from './styled/MobileTop';
import { TabletOrDesktop } from '../../lib/responsive';

const Container = styled.div`
  min-width: ${(props) => props.mobile || '991px'};
  display: ${(props) => props.flex || 'flex'};
  width: 100%;
  min-height: 100vh;
  background: #eeeff0;
`;
const Left = styled.div`
  /* position: absolute; */
  min-width: 448px;
  max-width: 448px;
  /* background: url(${(props) => props.imageDataUri}); */
  /* background: url('/images/login/rectangle-copy.png'); */
  background: url(${(props) => props.mainImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .nav_logo_locale {
    /* color: rgba(48, 57, 69, 0.66); */
    color: white;
    font-weight: 400;
    vertical-align: baseline;
    /* vertical-align: sub; */
    font-size: 1rem;
    /* padding-left: 5px; */
    /* padding: 25px 0 0 5px; */
  }
`;
const Right = styled.div`
  width: 100%;

  .top {
    display: flex;
    align-items: center; //vertical center
    width: 100%;
    height: 96px;
    /* background: red; */
  }
  .top-left {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 32px;
  }
  .top-right {
    p {
      margin: 0 16px 0 0;
      /* width: 146px; */
      height: 19px;
      font-family: 'Source Sans Pro';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #969ba1;
    }
    display: flex;
    align-items: center;
    justify-content: flex-end; //horizontal right
    flex-grow: 1;
    margin-right: 36px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* background: green; */
  /* height: 702px; */
  width: 448px;
  /* margin-left: 278px;
  margin-right: 266px; */
  ${(props) =>
    props.error
      ? css`
          margin-top: 41px;
        `
      : props.mobile
      ? css`
          margin-top: 80px;
        `
      : css`
          margin-top: 141px;
        `}

  a {
    text-decoration: none;
  }

  div {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Source Sans Pro';
  }

  .title {
    width: 416px;
    height: 32px;
    margin: 0px 0 0 16px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }

  .subTitle {
    width: 416px;
    height: 19px;
    margin: 4px 0 0 16px;
    font-size: 15px;
    color: #969ba1;
  }

  .forgot {
    /* width: 170px; */
    height: 19px;
    margin: 16px 16px 0 16px;
    font-size: 15px;
    outline: none;
    color: ${(props) => props.theme.main_color};
  }
  .mobileForgot {
    display: flex;
    justify-content: center;
    margin: 16px 16px 0 16px;
    height: 19px;
    font-size: 15px;
    outline: none;
    color: ${(props) => props.theme.main_color};
    font-family: 'Source Sans Pro';
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: normal;
    font-style: normal;
  }

  .oAuth {
    display: flex;
    width: 100%;
    height: 19px;
    margin-top: 44px;
    align-items: center;
    justify-content: center;
    /* margin: 44px 0 0 85px; */
    font-size: 15px;
    text-align: center;
    color: #59616a;

    hr {
      /* display: flex; */
      align-items: center;
      width: 48px;
      height: 1px;
      background-color: #c3c6c9;
      border: none;
    }
    .hr1 {
      margin: 11px 17px 7px 0;
    }
    .hr2 {
      margin: 11px 0 7px 16px;
    }
  }
`;

const Text = styled.div`
  display: flex;
  margin-left: ${(props) => props.marginLeft || '48px'};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: transparent;
  flex-direction: row;
  justify-content: center;

  font-family: 'Source Sans Pro';
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => props.lineHeight || 'normal'};
  letter-spacing: ${(props) => props.letterSpacing || 'normal'};
  text-align: center;
  color: ${(props) => props.color || '#ff6d22'};
`;

const SocialLoginButon = styled.button`
  display: flex;
  width: ${(props) => props.width || '216px'};
  height: 48px;
  white-space: nowrap;
  margin: ${(props) => props.margin};
  padding: 4px;
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background: #ffffff;
  outline: none;
  cursor: pointer;

  text-align: left;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #59616a;

  &:hover {
    color: #59616a;
    background: #eeeff0;
  }
  &:active {
    color: ${(props) => props.theme.main_color};
    background: ${(props) => props.theme.main_color_focus};
    border: solid 1px ${(props) => props.theme.main_color};
  }
  &:disabled {
    color: #c3c6c9;
    background: #f5f6f6;
  }

  img {
    background: transparent;
    width: 40px;
    height: 40px;
    margin: 0;
    border: 0;
    padding: 0;
    object-fit: contain;
  }

  p {
    display: flex;
    width: 131px;
    height: 20px;
    margin: 9px 28px 11px 8px;
  }
`;

const MobileTitle = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  /* height: 32px; */
  font-size: 25px;
  font-family: 'Source Sans Pro';
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  color: #59616a;
`;

const MobileSubTitle = styled.div`
  display: flex;
  justify-content: center;
  /* width: 416px; */
  height: 19px;
  margin: 4px 0 0 16px;
  font-size: 15px;
  color: #969ba1;
`;

const MobileAccount = styled.div`
  margin: 24px 0 16px 0;
  /* width: 146px; */
  height: 19px;
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #969ba1;
`;

const MobileAccountDiv = styled.div`
  text-align: center;
`;

const MobileSocialButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`;

const LoginForm = ({
  form,
  language,
  lngs,
  onChange,
  onSelect,
  onSubmit,
  onSocialLogin,
  error,
  history,
  app,
  odm,
}) => {
  const { t } = useTranslation();
  const HCloud =
    odm.app_name === 'HeronCloud' || odm.app_name === '3DiscCloud' ? true : false;
  const isTabletOrDesktop = TabletOrDesktop();
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const isLocal = window.location.hostname === 'localhost';
  let domain = 'localhost:3000';
  if (!isLocal) domain = window.location.hostname.match(/\w*\.\w*$/gi)[0];

  return (
    <>
      {isTabletOrDesktop && (
        <Container>
          <Left
            // imageDataUri={HCloud ? imageDataUri : imageDataUri2}
            mainImage={
              HCloud
                ? process.env.PUBLIC_URL + `/images/login/rectangle-copy.png`
                : process.env.PUBLIC_URL + `/images/login/rectangle-copy2.png`
            }
          >
            {/* <img
          style={{
            height: '100%',
            objectFit: 'cover',
            minWidth: '448px',
            maxWidth: '448px',
            position: 'absolute',
            zIndex: '1'
          }}
          src={imageDataUri}
          alt=""
        /> */}
            <Logo
              darkMode={true}
              src={
                process.env.PUBLIC_URL +
                `/${odm.images}/login/logo-heron-cloud-light.svg`
              }
            />
            {HCloud && (
              <Text marginTop="254px" width="352px" height="19px">
                <Text
                  marginLeft="0"
                  width="auto"
                  fontSize="15px"
                  letterSpacing="0.4px"
                  fontWeight="600"
                >
                  {/* {'Heron'} */}
                  {odm.app_name.replace('Cloud', '')}
                </Text>
                <Text
                  marginLeft="0"
                  color="#ffffff"
                  fontSize="15px"
                  letterSpacing="0.4px"
                  fontWeight="600"
                >
                  {'Cloud'}
                </Text>
              </Text>
            )}
            {!HCloud && (
              <Text marginTop="254px" marginLeft="0px">
                <Logo
                  src={process.env.PUBLIC_URL + `/${odm.images}/login/logo-login-s.svg`}
                  cursor={'default'}
                  margin={'0'}
                />
              </Text>
            )}
            <Text
              marginTop="16px"
              width="352px"
              // height="64px"
              color={HCloud ? '#ffffff' : '#59616a'}
              fontSize="25px"
              fontWeight="600"
            >
              {t('The design collaboration cloud for dental teams')}
            </Text>
            <Text
              marginTop="16px"
              width="352px"
              // height="96px"
              fontSize="15px"
              color={HCloud ? '#d2d4d7' : '#878d93'}
              lineHeight="1.6"
            >
              {t(
                'HeronCloud is an innovative platform that connects 3DISC users with dental labs and allows collaboration, case tracking, and seamless communication between dentist and dental labs',
              )
                .replace('HeronCloud', odm.app_name)
                .replace('3DISC', odm.company_name)}
            </Text>
            {HCloud && (
              <LinkButton
                // margin={true}
                margin={'23px 148px 0 148px'}
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = 'https://3disc.com/contact/?s=2';
                  link.setAttribute('target', '_blank ');
                  // document.body.appendChild(link);
                  link.click();
                }}
              >
                {t('Request a demo').toUpperCase()}
              </LinkButton>
            )}

            <Text
              marginTop={HCloud ? '163px' : '222px'}
              width="352px"
              height="20px"
              color={HCloud ? '#969ba1' : '#b4b8bc'}
              fontSize="15px"
              lineHeight="1.33"
            >
              {app &&
                `© ${odm.copyright_year} ${odm.company_name} ${odm.app_name} ${app.ver
                  .split('.')
                  .slice(0, 3)
                  .join('.')}`}
            </Text>
          </Left>
          <Right>
            <div className="top">
              <div className="top-left">
                {language && (
                  <DropdownList
                    width="108px"
                    height="36px"
                    // defaultValue={languageName}
                    // items={{
                    //   de: 'Deutsch',
                    //   en: 'English',
                    //   es: 'español',
                    //   fr: 'français',
                    //   ja: '日本語',
                    // }}
                    defaultValue={language}
                    items={lngs}
                    onSelect={onSelect}
                  />
                )}
                {app && HCloud && (
                  <LinkButton
                    width="161px"
                    margin="0px 0px 0px 5px"
                    onClick={() =>
                      window.location.assign(
                        `https://${app.server_loc === 'EU' ? 'us.' : 'eu.'}${domain}`,
                      )
                    }
                  >
                    {t(
                      'Connect to ' + (app.server_loc === 'EU' ? 'US' : 'EU') + ' Site',
                    ).toUpperCase()}
                  </LinkButton>
                )}
              </div>

              <div className="top-right">
                <p>{t('Don’t have an account?')}</p>
                <LinkButton width="151px" onClick={() => history.push('/signup')}>
                  {t('Create Account').toUpperCase()}
                </LinkButton>
              </div>
            </div>
            <ContentContainer>
              <Content error={error}>
                <ErrorList error={error} />
                <div className="title">{t(`Sign in to ${odm.app_name}`)}</div>
                <div className="subTitle">{t('Enter your details below')}</div>
                <form onSubmit={onSubmit}>
                  <Input
                    type="email"
                    name="username"
                    placeholder={t('Email Address')}
                    margin="28px 0 0 0"
                    onChange={onChange}
                    value={form.username}
                    tabIndex={1}
                    required
                    autoFocus
                    isLoginForm={true}
                  />
                  <Input
                    name="password"
                    placeholder={t('Password')}
                    type="password"
                    margin="8px 0 0 0 0"
                    onChange={onChange}
                    value={form.password}
                    required
                    tabIndex={2}
                    isLoginForm={true}
                  />
                  <Button margin="16px 0 0 0">{t('Sign in').toUpperCase()}</Button>
                </form>
                <NavLink to="/password_reset" className="forgot">
                  {t('Forgot your password')}?
                </NavLink>

                <div className="oAuth">
                  <hr className="hr1" />
                  {t('Or sign in with one click')}
                  <hr className="hr2" />
                </div>

                <div style={{ display: 'flex' }}>
                  <SocialLoginButon
                    margin={'20px 0'}
                    name="facebook"
                    onClick={onSocialLogin}
                  >
                    <img
                      alt="Facebook"
                      src={process.env.PUBLIC_URL + '/images/login/ic-logo-fbook.svg'}
                    />
                    <p>{t('Facebook')}</p>
                  </SocialLoginButon>
                  <SocialLoginButon
                    margin={'20px 0 0 16px'}
                    name="google"
                    onClick={onSocialLogin}
                  >
                    <img
                      style={{
                        width: '20px',
                        height: '20px',
                        marginTop: '10px',
                        marginLeft: '10px',
                        marginRight: '10px',
                      }}
                      alt="Google"
                      src={process.env.PUBLIC_URL + '/images/login/ic-logo-google.svg'}
                    />
                    <p>{t('Google')}</p>
                  </SocialLoginButon>
                </div>
              </Content>
            </ContentContainer>
          </Right>
        </Container>
      )}

      {!isTabletOrDesktop && (
        <Container flex="block" mobile={isTabletOrDesktop ? '991px' : '0'}>
          <MobileTop language={language} lngs={lngs} onSelect={onSelect}></MobileTop>
          <ContentContainer>
            <Content error={error} mobile={true}>
              <ErrorList error={error} />
              <MobileTitle style={{ fontWeight: 600 }}>
                {t(`Sign in to ${odm.app_name}`)}
              </MobileTitle>
              <MobileSubTitle>{t('Enter your details below')}</MobileSubTitle>
              <form style={{ textAlign: 'center' }} onSubmit={onSubmit}>
                <Input
                  type="email"
                  width={'288px'}
                  name="username"
                  placeholder={t('Email Address')}
                  margin="28px 0 0 0"
                  onChange={onChange}
                  value={form.username}
                  tabIndex={1}
                  required
                  autoFocus
                  isLoginForm={true}
                />
                <Input
                  width={'288px'}
                  name="password"
                  placeholder={t('Password')}
                  type="password"
                  margin="8px 0 0 0 0"
                  onChange={onChange}
                  value={form.password}
                  required
                  tabIndex={2}
                  isLoginForm={true}
                />
                <Button width={'320px'} margin="16px 0 0 0">
                  {t('Sign in').toUpperCase()}
                </Button>
              </form>
              <NavLink to="/password_reset" className="mobileForgot">
                {t('Forgot your password')}?
              </NavLink>

              <div className="oAuth">
                <hr className="hr1" />
                {t('Or sign in with one click')}
                <hr className="hr2" />
              </div>

              <MobileSocialButtons>
                <SocialLoginButon width="320px" name="facebook" onClick={onSocialLogin}>
                  <img
                    alt="Facebook"
                    src={process.env.PUBLIC_URL + '/images/login/ic-logo-fbook.svg'}
                  />
                  <p>{t('Facebook')}</p>
                </SocialLoginButon>
                <SocialLoginButon
                  width="320px"
                  margin="8px 0 0 0"
                  name="google"
                  onClick={onSocialLogin}
                >
                  <img
                    style={{
                      width: '20px',
                      height: '20px',
                      marginTop: '10px',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                    alt="Google"
                    src={process.env.PUBLIC_URL + '/images/login/ic-logo-google.svg'}
                  />
                  <p>{t('Google')}</p>
                </SocialLoginButon>
              </MobileSocialButtons>
              <MobileAccount>{t('Don’t have an account?')}</MobileAccount>
              <MobileAccountDiv>
                <LinkButton width="151px" onClick={() => history.push('/signup')}>
                  {t('Create Account').toUpperCase()}
                </LinkButton>
              </MobileAccountDiv>
              <Text
                marginTop={'36px'}
                marginLeft={'0'}
                marginBottom={'20px'}
                height="20px"
                color={HCloud ? '#969ba1' : '#b4b8bc'}
                fontSize="15px"
                lineHeight="1.33"
              >
                {app &&
                  `© ${odm.copyright_year} ${odm.company_name} ${odm.app_name} ${app.ver
                    .split('.')
                    .slice(0, 3)
                    .join('.')}`}
              </Text>
            </Content>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default withRouter(React.memo(LoginForm));
