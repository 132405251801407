import React from 'react';
import styled from 'styled-components';
import InputSelect from '../../components/common/InputSelect';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/common/Spinner';
import { getODM, theme } from '../../lib/odm';

const ThisMonthLayout = styled.div`
  width: auto;
  height: 270px;
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background-color: ${(props) => props.bkgColor};

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 55px 16px 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'Filter Filter'
    'Space Space'
    'List List';

  .Filter {
    grid-area: Filter;
  }

  .List {
    overflow-y: auto;
    scrollbar-width: 8px;
    scrollbar-color: #ffffff45 transparent;
    grid-area: List;
  }

  .List::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  .List::-webkit-scrollbar-thumb {
    background-color: #ffffff45;
    border-radius: 4px;
    background-clip: padding-box;
    border: 1px solid transparent;
  }

  .NoData {
    font-family: Source Sans Pro;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
  }
`;

const ResotrationItem = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  /* grid-template-rows: 32px; */
  grid-auto-rows: min-content;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'RestoNum RestoName';

  margin-bottom: 8px;

  font-family: Source Sans Pro;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;

  .RestoNum {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    grid-area: RestoNum;
  }
  .RestoName {
    display: flex;
    font-weight: normal;
    margin-left: 17px;
    grid-area: RestoName;
    align-items: center;
    //align-items: center;
  }

  .RestoName2 {
    line-height: 17px;
    max-height: 34px;
    text-overflow: ellipsis;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const ThisMonth = ({ restos, onFilterChanged, period, loading }) => {
  const { t } = useTranslation();

  function GetODMColor(type) {
    var odm_name = getODM().name;

    if (odm_name == 'HCLOUD') {
      if (type == 'bkg') return '#FF762F';
      else if (type == 'hover') return '#E5621F';
      else if (type == 'border') return 'solid 1px #FF6D22';
    } else if (odm_name == 'EZSCAN') {
      if (type == 'bkg') return '#B42E37';
      else if (type == 'hover') return '#9C1D25';
      else if (type == 'border') return 'solid 1px #AF212A';
    } else if (odm_name == 'IMESICORE') {
      if (type == 'bkg') return '#B42E37';
      else if (type == 'hover') return '#9C1D25';
      else if (type == 'border') return 'solid 1px #AF212A';
    } else if (odm_name == 'GRO3X') {
      if (type == 'bkg') return '#E18F0F';
      else if (type == 'hover') return '#CB7D03';
      else if (type == 'border') return 'solid 1px #DF8800';
    } else if (odm_name == 'WHIPMIX') {
      if (type == 'bkg') return '#0F7AC9';
      else if (type == 'hover') return '#0369B5';
      else if (type == 'border') return 'solid 1px #0072C6';
    }
  }

  function GetItemWidth(txt) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    console.log('width: ' + context.measureText(txt).width);
    return context.measureText(txt).width;
  }

  return (
    <ThisMonthLayout bkgColor={GetODMColor('bkg')}>
      <div className="Filter">
        <InputSelect
          items={[t('Work week'), t('Last 7 days'), t('Work month'), t('Last 30 days')]}
          onSelect={onFilterChanged}
          placeholder=""
          width="100%"
          height="55px"
          padding="1px"
          selItem={period}
          border={GetODMColor('border')}
          background={theme.main_color}
          hoverColor={GetODMColor('hover')}
          menuHoverColor="#e5621f"
          fontSize="20px"
          fontColor="white"
          arrowImagePath="/images/dashboard/icon-24-arrow-down-active-white.svg"
          arrowMargin="6px 16px 6px 0"
          reset="0"
        />
      </div>
      <div className="List">
        <div className="scroller">
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '130px',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <>
              {restos.length > 0 ? (
                <>
                  {restos.map((item, index) => (
                    <ResotrationItem key={index} txtLen={GetItemWidth(item.name)}>
                      <div className="RestoNum">{item.count}</div>
                      <div className="RestoName">
                        <div className="RestoName2">{item.name}</div>
                      </div>
                    </ResotrationItem>
                  ))}
                </>
              ) : (
                // show No Data if necessary
                <div className="NoData">{t('No activity yet')}</div>
              )}
            </>
          )}
        </div>
      </div>
    </ThisMonthLayout>
  );
};

export default ThisMonth;
