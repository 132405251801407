import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import SVG from '../common/svg';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DownloadBoxfadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const DownloadBoxfadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const DownloadBoxFullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${DownloadBoxfadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${DownloadBoxfadeOut};
    `}
`;

const DownloadBoxModalBlock = styled.div`
  width: 100%;
  height: 100%;
  background: #eeeff0;

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 8px 19px 8px 0;
    float: right;
  }

  .contentPicture {
    width: 100%;
    height: calc(100% - 40px);
    border-style: none;
    object-fit: contain;
  }

  .contentPDF {
    width: 100%;
    height: 100%;
    border-style: none;
  }

  .noPdfReader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;

    text-align: center;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59646a;
  }
`;

const DownloadBox = ({ visible, uri, filename, onOk }) => {
  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const { t } = useTranslation();

  const { odm } = useSelector(({ app }) => ({
    odm: app.odm,
  }));

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  /*
  const closeActive = process.env.PUBLIC_URL + '/images/common/icon-24-close-active.svg';
  const closeHover = process.env.PUBLIC_URL + '/images/common/icon-24-close-hover.svg';
  const closeSelected = process.env.PUBLIC_URL + '/images/common/icon-24-close-selected.svg';
  */

  const getContent = () => {
    if (filename.toUpperCase().includes('.PNG') || filename.toUpperCase().includes('.JPG')) {
      return <img className={'contentPicture'} src={uri} />;
    } else if (filename.toUpperCase().includes('.PDF')) {
      return (
        <object data={uri} className={'contentPDF'}>
          <div className={'noPdfReader'}>
            <p>{t("It appears you don't have a PDF viewer for this browser") + '.'}</p>
            <p>
              <a href={uri}>{t('You can click here to download the PDF file') + '.'}</a>
            </p>
          </div>
        </object>
      );
    }
  };

  if (!animate && !localVisible) return null;

  return (
    <DownloadBoxFullscreen disappear={!visible}>
      <DownloadBoxModalBlock disappear={!visible}>
        {/*
        <img
          className={'closeIcon'}
          src={closeActive}
          onMouseOver={(e) => (e.currentTarget.src = closeHover)}
          onMouseOut={(e) => (e.currentTarget.src = closeActive)}
          onMouseDown={(e) => (e.currentTarget.src = closeSelected)}
          onClick={onOk}
        />
        */}
        <div className={'closeIcon'} onClick={onOk}>
          <SVG
            className={'closeIcon'}
            name="close"
            color="#878D93"
            colorOver={odm.main_color_hover}
            colorOut="#878D93"
            colorDown={odm.main_color}
          />
        </div>
        {getContent()}
      </DownloadBoxModalBlock>
    </DownloadBoxFullscreen>
  );
};

export default DownloadBox;
