import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import PulseLoader from "react-spinners/PulseLoader";

const Spinner = () => {
    return (
        <PulseLoader color="#787F86" size={10} />
    );
};

export default Spinner;
