import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Logo from './styled/Logo';
import Input from './styled/Input';
import Button from './styled/Button';
import { useTranslation } from 'react-i18next';
import ErrorList from './styled/ErrorList';
import DropdownList from './styled/DropdownList';
import countries from 'i18n-iso-countries';
import German from 'i18n-iso-countries/langs/de.json';
import English from 'i18n-iso-countries/langs/en.json';
import Spanish from 'i18n-iso-countries/langs/es.json';
import French from 'i18n-iso-countries/langs/fr.json';
import Japanese from 'i18n-iso-countries/langs/ja.json';
import Korean from 'i18n-iso-countries/langs/ko.json';
import Italian from 'i18n-iso-countries/langs/it.json';
import Polish from 'i18n-iso-countries/langs/pl.json';
import Portuguese from 'i18n-iso-countries/langs/pt.json';

import { useDispatch } from 'react-redux';
import { initializeForm } from '../../modules/auth';
import ValidateDiv from '../../components/auth/styled/ValidateText';
import { getIpInfo } from '../../lib/api/app';

const Container = styled.div`
  .validateCss {
    margin-top: 4px;
    margin-bottom: 8px;
  }
  div {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .ContentContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .Content {
    display: flex;
    flex-direction: column;
    width: 448px;
    /* margin-left: 496px; */
  }
`;

const Title = styled.div`
  width: 328px;
  height: 32px;
  margin-left: 16px;
  ${(props) =>
    props.error
      ? css`
          margin-top: 0px;
        `
      : css`
          margin-top: 102px;
        `}
  font-size: 25px;
  font-weight: 600;
  color: #59616a;
`;

const SubTitle = styled.div`
  width: 500px;
  height: 24px;
  margin: 16px 0 24px 16px;
  font-size: 15px;
  line-height: 1.6;
  color: #969ba1;
`;

const Avatar = styled.div`
  display: flex;
  min-width: 424px;
`;

const P = styled.p`
  display: inline-block;
  width: 344px;
  height: 48px;
  margin-top: 16px;
  margin-left: 16px;
  font-size: 15px;
  line-height: 1.6;
  color: #59616a;
  display: flex;
  align-items: center;

  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

const SocialSignupForm = ({ form, language, onChange, onSelect, onSubmit, error, onFocusOut }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let languageName = English;
  if (language === 'de') languageName = German;
  else if (language === 'en') languageName = English;
  else if (language === 'es') languageName = Spanish;
  else if (language === 'fr') languageName = French;
  else if (language === 'ja') languageName = Japanese;
  else if (language === 'ko') languageName = Korean;
  else if (language === 'it') languageName = Italian;
  else if (language === 'pl') languageName = Polish;
  else if (language === 'pt') languageName = Portuguese;
  countries.registerLocale(languageName);

  const [countryCode, setCountryCode] = useState();
  const countryRef = useRef(null);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);

    const ipInfo = async () => {
      const res = await getIpInfo();
      if (res.data) setCountryCode(res.data.country_code);
    };
    ipInfo();

    return () => {
      dispatch(initializeForm('authError'));
    };
  }, []);

  useEffect(() => {
    if (countryCode) {
      const country_name = countries.getName(countryCode, language);
      countryRef.current.setValue(country_name);
      onSelect(countryCode, 'country');
    }
  }, [countryCode]);

  return (
    <Container>
      <Logo />
      <div className="ContentContainer">
        <div className="Content">
          <ErrorList error={error}></ErrorList>
          <Title style={{ fontWeight: '600' }} error={error}>
            {t('Create New Account')}
          </Title>
          <SubTitle>{t('Enter your details below and click “Create Account”.')}</SubTitle>

          <form onSubmit={onSubmit}>
            <Input
              type="email"
              name="username"
              margin="0"
              value={form.username}
              tabIndex={1}
              required
              readOnly={true}
              bgkColor={'#f5f6f6'}
            />
            <Input
              type="text"
              name="first_name"
              margin="0"
              value={form.first_name}
              tabIndex={4}
              required
              readOnly={true}
              bgkColor={'#f5f6f6'}
            />
            <DropdownList
              name="role"
              width="448px"
              height="48px"
              placeholder={t('Institution Type') + '*'}
              margin="8px 0 0 0"
              img_margin="12px 12px 12px 0"
              search={true}
              items={['Dental Clinic', 'Dental Lab', 'Other']}
              onSelect={onSelect}
              zIndex={2}
              tabIndex={6}
              required
            />
            <ValidateDiv name="roleValidate"></ValidateDiv>
            <Input
              name="institution"
              margin="0"
              placeholder={t('Institution Name')}
              value={form.institution}
              onChange={onChange}
              maxLength={100}
              tabIndex={7}
            />
            <DropdownList
              name="country"
              width="448px"
              height="48px"
              maxHeight="216px"
              placeholder={t('Country') + '*'}
              defaultValue={countries.getName(countryCode, language)}
              margin="8px 0 0 0"
              img_margin="12px 12px 12px 0"
              search={true}
              items={Object.fromEntries(
                Object.entries(countries.getNames(language, { select: 'official' })).sort(function (
                  [, a],
                  [, b],
                ) {
                  return a.localeCompare(b);
                }),
              )}
              // itmes={countries.getNames(language, { select: 'official' })}
              onSelect={onSelect}
              zIndex={1}
              tabIndex={8}
              required
              ref={countryRef}
            />
            <ValidateDiv name="countryValidate"></ValidateDiv>
            <Input
              type="tel"
              name="phone"
              margin="0"
              placeholder={t('Phone')}
              value={form.phone}
              onChange={onChange}
              maxLength={20}
              tabIndex={9}
            />
            <Input
              as="textarea"
              style={{ resize: 'none', overflow: 'hidden' }}
              name="address"
              height="66px"
              margin="0"
              padding="14px 15px 14px 15px"
              placeholder={t('Address')}
              value={form.address}
              onChange={onChange}
              tabIndex={10}
              maxLength={200}
            />
            <Button data-action="submit" margin="24px 0 224px 0" tabIndex={12}>
              {t('Create Account').toUpperCase()}
            </Button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default React.memo(SocialSignupForm);
