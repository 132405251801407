import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeField, initializeForm } from '../../modules/auth';
import { validate } from '../../lib/api/auth';
import { withRouter } from 'react-router-dom';
import SocialSignupForm from '../../components/auth/SocialSignupForm';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import MessageBox from '../../components/common/MessageBox';

const SocialSignupContainer = ({ location }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0];
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { form, odm } = useSelector(({ auth, app }) => ({
    form: auth.signup,
    odm: app.odm,
  }));
  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const { data } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isHCloud = odm.name === 'HCLOUD';

  if (!userInfo && data) {
    const jsonStr = Buffer.from(data.replace(/_/g, '/').replace(/-/g, '+'), 'base64').toString(
      'utf8',
    );
    setUserInfo(JSON.parse(jsonStr));
  }

  const postUrl = (path, params) => {
    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    for (var key in params) {
      var hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  };

  const onMessageBoxOk = () => {
    setMessageVisible(false);

    const { role, institution, country, phone, address } = form;

    if ([role, country].includes('')) {
      setError(t('Please enter all required items.'));
      window.scrollTo(0, 0);
      return;
    }

    const params = {};
    if (role) params['role'] = role;
    if (institution) params['institution'] = institution;
    if (country) params['country'] = country;
    if (phone) params['phone'] = phone;
    if (address) params['address'] = address;

    let path = null;
    if (userInfo.facebook_login) path = `/api/v1/complete/facebook/`;
    else if (userInfo.google_login) path = `/api/v1/complete/google-oauth2/`;
    if (path) {
      const uri = `${path}`;
      postUrl(uri, params);
    }
  };

  const onMessageBoxCancel = () => {
    setMessageVisible(false);
  };

  const onChange = (e) => {
    const { value, name } = e.target;

    dispatch(
      changeField({
        form: 'signup',
        key: name,
        value,
      }),
    );
  };

  const onFocusOut = (e) => {
    const { value, name } = e.target;
    validate_signup(value, 'signup', name);
  };

  const validate_signup = async (value, formName, field) => {
    const formData = new FormData();
    formData.append('role', '');
    formData.append('institution', '');
    formData.append('country', '');
    formData.append('phone', '');
    formData.append('address', '');

    if (field) formData.set(field, value);

    try {
      let res = await validate({ formData, form: formName, field });

      if (res.statusText !== 'OK') {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const node = document.getElementsByName(field + 'Validate')[0];
      if (JSON.stringify(res.data) === '{}') {
        if (node.childNodes.length > 0) node.removeChild(node.childNodes[0]);
        node.classList.remove('validateCss');
      } else {
        if (res.data.error_message) {
          let text = '';
          res.data.error_message.forEach((el) => {
            text += '<div>' + t(el) + '</div>';
          });

          let small = document.createElement('small');
          // small.setAttribute('class', 'message');
          small.innerHTML = "<font color='red'>" + `${text}` + '</font>';

          if (node.childNodes.length === 0) node.appendChild(small);
          else node.replaceChild(small, node.childNodes[0]);
          node.classList.add('validateCss');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSelect = (value, name) => {
    dispatch(
      changeField({
        form: 'signup',
        key: name,
        value,
      }),
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isHCloud) {
      setMessageVisible(true);
      setMessageTitle(t('Info'));
      setMessageContent(
        t(
          'Data storage region is assigned depending on the country you select. This option cannot be modified after you click OK. Do you want to continue?',
        ),
      );
    } else {
      onMessageBoxOk();
    }
  };

  // const startSocialLogin = async (socialMedia) => {
  //   const res = await getSocialLoginUrl({ socialMedia });
  //   const url = res.data.url.replace('127.0.0.1:8000', 'localhost:3000');
  //   window.location.href = url;
  // };

  useEffect(() => {
    // startSocialLogin(match.params.socialMedia);

    dispatch(initializeForm('signup'));

    if (userInfo) {
      if (userInfo.fb_details) {
        if (userInfo.fb_details.email) {
          dispatch(
            changeField({
              form: 'signup',
              key: 'username',
              value: userInfo.fb_details.email,
            }),
          );
        }
        if (userInfo.fb_details.username) {
          dispatch(
            changeField({
              form: 'signup',
              key: 'first_name',
              value: userInfo.fb_details.username,
            }),
          );
        }
      } else if (userInfo.gg_details) {
        if (userInfo.gg_details.email) {
          dispatch(
            changeField({
              form: 'signup',
              key: 'username',
              value: userInfo.gg_details.email,
            }),
          );
        }
        if (userInfo.gg_details.fullname) {
          dispatch(
            changeField({
              form: 'signup',
              key: 'first_name',
              value: userInfo.gg_details.fullname,
            }),
          );
        }
      }
    }
  }, []);

  return (
    <>
      {userInfo && (
        <SocialSignupForm
          form={form}
          language={language}
          onChange={onChange}
          onSelect={onSelect}
          onSubmit={onSubmit}
          error={error}
          onFocusOut={onFocusOut}
        />
      )}

      <MessageBox
        visible={messageVisible}
        type="okcancel"
        title={messageTitle}
        content={messageContent}
        onOk={onMessageBoxOk}
        onCancel={onMessageBoxCancel}
      />
    </>
  );
};

export default withRouter(SocialSignupContainer);
