import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Settings from '../../components/settings/Settings';
// import { listSettings, toggle, saveSettings } from '../../modules/settings';
import { useRefreshOnAuthError } from '../../lib/common';
import { saveSettings } from '../../lib/api/settings';
import { check, getMigrationInfo } from '../../modules/user';

const SettingsContainer = () => {
  const dispatch = useDispatch();
  // const { userChecking, settings, error, loading, authError } = useSelector(
  //   ({ settings, loading, auth }) => ({
  const { userChecking, loading, migrationInfo, migrationInfoError, settings, user, odm } =
    useSelector(({ loading, user, app }) => ({
      userChecking: loading['user/CHECK'],
      // settings: settings.settings,
      // error: settings.error,
      // loading: loading['settings/LIST_SETTINGS'],
      loading: loading['user/MIGRATION_INFO'],
      user: user.user,
      settings: user.settings,
      migrationInfo: user.migrationInfo,
      migrationInfoError: user.migrationInfoError,
      odm: app.odm,
    }));

  // const onToogle = useCallback((text) => dispatch(toggle(text)), [dispatch]);
  const onSaveSettings = useCallback(
    async (settings) => {
      // const {
      //   show_task_filter,
      //   show_taskcol_oppuser,
      //   use_auto_refresh,
      //   post_publish_email,
      //   post_edit_email,
      //   comment_add_email,
      //   comment_edit_email,
      // } = settings;
      // dispatch(
      //   saveSettings({
      //     show_task_filter,
      //     show_taskcol_oppuser,
      //     use_auto_refresh,
      //     post_publish_email,
      //     post_edit_email,
      //     comment_add_email,
      //     comment_edit_email,
      //   }),
      // );
      try {
        await saveSettings(settings);

        dispatch(check({ callFrom: user.is_device_manager ? 'adm' : null }));
      } catch (e) {
        console.log(e);
      }
    },
    [dispatch],
  );

  // useEffect(() => {
  //   // console.log('Reload!!');
  //   if (error) {
  //     // console.log(error);
  //     return;
  //   }
  //   if (!userChecking) {
  //     dispatch(listSettings());
  //   }
  // }, [dispatch, userChecking, error]);

  // useEffect(() => {
  //   if (settings['detail'] === 'settings modified') {
  //     dispatch(listSettings());
  //   }
  // }, [dispatch, settings]);
  useEffect(() => {
    if (migrationInfoError) {
      return;
    }
    if (!userChecking) {
      dispatch(getMigrationInfo());
    }
  }, [migrationInfoError, userChecking]);

  useRefreshOnAuthError();

  return (
    <div>
      <Settings
        settings={settings}
        migrationInfo={migrationInfo}
        userChecking={userChecking}
        loading={loading}
        // error={error}
        // onToggle={onToogle}
        onSaveSettings={onSaveSettings}
        odm={odm}
      ></Settings>
    </div>
  );
};

export default withRouter(SettingsContainer);
