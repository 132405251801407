import { createAction, handleActions } from 'redux-actions';

const START_CURSOR_LOADING = 'cursor/START_LOADING';
const FINISH_CURSOR_LOADING = 'cursor/FINISH_LOADING';

export const startCursorLoading = createAction(
  START_CURSOR_LOADING,
  (requestType) => requestType,
);

export const finishCursorLoading = createAction(
  FINISH_CURSOR_LOADING,
  (requestType) => requestType,
);

const initialState = {};

const cursorLoading = handleActions(
  {
    [START_CURSOR_LOADING]: (state, action) => ({ ...state, [action.payload]: true }),
    [FINISH_CURSOR_LOADING]: (state, action) => ({ ...state, [action.payload]: false }),
  },
  initialState,
);

export default cursorLoading;
