import React, { useEffect } from 'react';
import LeftPanel from '../../containers/leftpanel/LeftPanelContainer';
import { useSelector, useDispatch } from 'react-redux';
import { initializeForm } from '../../modules/auth';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Input from './styled/Input';
import DropdownList from './styled/DropdownList';
import Button from './styled/Button';
import ImageButton from './styled/ImageButton';
import countries from 'i18n-iso-countries';
import German from 'i18n-iso-countries/langs/de.json';
import English from 'i18n-iso-countries/langs/en.json';
import Spanish from 'i18n-iso-countries/langs/es.json';
import French from 'i18n-iso-countries/langs/fr.json';
import Japanese from 'i18n-iso-countries/langs/ja.json';
import Korean from 'i18n-iso-countries/langs/ko.json';
import Italian from 'i18n-iso-countries/langs/it.json';
import Polish from 'i18n-iso-countries/langs/pl.json';
import Portuguese from 'i18n-iso-countries/langs/pt.json';

import LinkButton from './styled/LinkButton';
import { withRouter } from 'react-router';
import Spinner from '../common/Spinner';
import MessageBox from '../common/MessageBox';
import ValidateDiv from '../../components/auth/styled/ValidateText';
import HButton from '../common/HButton';
// import { userProfileSuccess, setUserProfileSuccess } from '../../modules/auth'

const MainBlock = styled.div`
  /* user-select: none; */
  .Normal {
    width: 100%;
    min-width: 1100px !important;
    height: 100%;
    margin: auto;
    background: #eeeff0;
    display: grid;
    grid-template-columns: 256px auto;
    grid-template-rows: 100vh;
    gap: 0px;
  }

  .Collapsed {
    width: 100%;
    min-width: 1100px !important;
    height: 100%;
    background: #eeeff0;
    margin: auto;
    display: grid;
    grid-template-columns: 64px auto;
    grid-template-rows: 100vh;
    gap: 0px;
  }

  .loading {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  div {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .ScrollBox {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
const RightDiv = styled.div`
  display: flex;
  flex-direction: column; /* vertical */
  width: 100%;
  height: 100%;

  .validateCss {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 542px;
    margin-top: 93px;
    margin-left: 40px;
  }

  .fake {
    user-select: none;
    display: flex;
    align-items: center;
    position: relative;
    width: 542px;
    height: 45px;
    margin-bottom: 8px;
    top: -34px;
    left: -19px;
  }

  .fakeDiv {
    display: none;
    border: none;
    background: transparent;
    width: auto;
    padding: 0 0 0 16px;
    /* height: 19px; */

    outline: none;
    /* z-index: -1; */

    font-family: 'Source Sans Pro';
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .fakeImage {
    z-index: 1;
    position: relative;
    width: 20px;
    height: 20px;
    left: 10px;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row; /* horizontal */
  width: 100%;
  height: 40px;
  margin-top: 60px;
  align-items: center;

  .title {
    flex: 1 1 135px; /* flex-grow, flex-shrink, flex-basis */
    height: 32px;
    margin-left: 48px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }
  .buttons {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-right: 40px;
  }
`;

const Avatar = styled.div`
  min-width: 424px;
  margin-left: 8px;
  display: flex;
`;

const P = styled.p`
  display: inline-block;
  width: 344px;
  height: 48px;
  margin-top: 16px;
  margin-left: 16px;
  font-size: 15px;
  line-height: 1.6;
  color: #59616a;
  display: flex;
  align-items: center;

  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

const EditProfile = ({
  migLoading,
  loading,
  user,
  language,
  form,
  onChange,
  onSelect,
  onChangeImage,
  imgSrc,
  onSubmit,
  history,
  disabled,
  imgRef,
  coverImgRef,
  onMouseOver,
  onMouseOut,
  onDeleteClick,
  messageConfirmCheck,
  messageVisible,
  messageTitle,
  messageType,
  messageContent,
  onConfirmBoxOk,
  onCancel,
  onFocusOut,
  isUserLoc,
  isHCloud,
}) => {
  const { t } = useTranslation();
  const leftpanel = useSelector((state) => state.leftpanel);
  const social_auth = user.social_auth;
  const social_provider = user.social_provider === 'google' ? true : false;
  const dispatch = useDispatch();

  let languageName = English;
  if (language === 'de') languageName = German;
  else if (language === 'en') languageName = English;
  else if (language === 'es') languageName = Spanish;
  else if (language === 'fr') languageName = French;
  else if (language === 'ja') languageName = Japanese;
  else if (language === 'ko') languageName = Korean;
  else if (language === 'it') languageName = Italian;
  else if (language === 'pl') languageName = Polish;
  else if (language === 'pt') languageName = Portuguese;
  countries.registerLocale(languageName);

  const getMainClass = () => {
    if (leftpanel.collapsed || leftpanel.collapsedAuto) return 'Collapsed';
    else return 'Normal';
  };

  useEffect(() => {
    return () => {
      dispatch(initializeForm('error'));
    };
  }, [dispatch]);

  return (
    <MainBlock>
      <div className={getMainClass()}>
        <LeftPanel />
        {(loading || migLoading) && (
          <div className={'loading'}>
            <Spinner />
          </div>
        )}
        {(!loading || migLoading) && (
          <div className={'ScrollBox'}>
            <RightDiv>
              <Top>
                <div className="title">{t('Profile')}</div>
                <div className="buttons">
                  {!social_auth && (
                    <LinkButton
                      width="165px"
                      height="40px"
                      onClick={() => history.push('/changePassword')}
                    >
                      {t('Change Password').toUpperCase()}
                    </LinkButton>
                  )}
                  <HButton
                    category="Primary"
                    size="Medium"
                    text={t('Save Changes').toUpperCase()}
                    // width="136px"
                    form="submit"
                    type=""
                    margin="0 0 0 16px"
                  />
                </div>
              </Top>
              <form id="submit" onSubmit={onSubmit}>
                <div className="fake">
                  <div className="fakeDiv" tabIndex={-1} readOnly>
                    {form.email}
                  </div>

                  {social_auth && social_provider && (
                    <img
                      className="fakeImage"
                      alt="Google"
                      src={process.env.PUBLIC_URL + '/images/signup/ic-logo-google.svg'}
                    />
                  )}
                  {social_auth && !social_provider && (
                    <img
                      className="fakeImage"
                      alt="Facebook"
                      src={process.env.PUBLIC_URL + '/images/signup/ic-logo-fbook.svg'}
                    />
                  )}
                  <Input
                    // style={{ position: 'absolute' }}
                    style={!social_auth ? { marginLeft: '20px' } : {}}
                    type="email"
                    name="username"
                    width="510px"
                    margin={'0'}
                    marginTop={'0'}
                    value={form.email}
                    tabIndex={1}
                    required
                    readOnly={true}
                    // color={'#c3c6c9'}
                    bgkColor={'#f5f6f6'}
                  />
                </div>
                {!social_auth && (
                  <>
                    <Input
                      type="text"
                      name="first_name"
                      width="510px"
                      margin="0"
                      placeholder={t('First Name') + '*'}
                      value={form.first_name ? form.first_name : ''}
                      onChange={onChange}
                      onBlur={onFocusOut}
                      tabIndex={-1}
                      required
                    />
                    <ValidateDiv name="first_nameValidate"></ValidateDiv>
                  </>
                )}
                {!social_auth && (
                  <>
                    <Input
                      type="text"
                      name="last_name"
                      width="510px"
                      margin="0"
                      placeholder={t('Last Name') + '*'}
                      value={form.last_name ? form.last_name : ''}
                      onChange={onChange}
                      onBlur={onFocusOut}
                      tabIndex={3}
                      required
                    />
                    <ValidateDiv name="last_nameValidate"></ValidateDiv>
                  </>
                )}
                {social_auth && (
                  <Input
                    type="text"
                    name="first_last_name"
                    width="510px"
                    margin="0"
                    padding="15px 15px 14px"
                    value={form.first_name + ' ' + form.last_name}
                    onChange={onChange}
                    tabIndex={3}
                    readOnly={true}
                    // color={'#c3c6c9'}
                    bgkColor={'#f5f6f6'}
                  />
                )}
                {form.in_group || form.involved_in_case ? (
                  <Input
                    type="text"
                    name="role"
                    width="510px"
                    margin="0"
                    value={form.role}
                    tabIndex={4}
                    required
                    readOnly={true}
                    bgkColor={'#f5f6f6'}
                  />
                ) : (
                  <DropdownList
                    name="role"
                    width="542px"
                    height="48px"
                    defaultValue={form.role}
                    margin="8px 0 0 0"
                    img_margin="12px 12px 12px 0"
                    search={true}
                    items={['Dental Clinic', 'Dental Lab', 'Other']}
                    onSelect={onSelect}
                    zIndex={2}
                    tabIndex={4}
                    required
                  />
                )}
                <ValidateDiv name="roleValidate"></ValidateDiv>
                <Input
                  type="text"
                  name="institution"
                  width="510px"
                  margin="0"
                  placeholder={t('Institution Name')}
                  value={form.institution ? form.institution : ''}
                  onChange={onChange}
                  tabIndex={5}
                />
                {isHCloud && isUserLoc ? (
                  <Input
                    type="text"
                    name="country"
                    width="510px"
                    margin="0"
                    value={countries.getName(form.country, language)}
                    tabIndex={6}
                    required
                    readOnly={true}
                    bgkColor={'#f5f6f6'}
                  />
                ) : (
                  <DropdownList
                    name="country"
                    width="542px"
                    height="48px"
                    maxHeight="216px"
                    defaultValue={countries.getName(form.country, language)}
                    margin="8px 0 0 0"
                    img_margin="12px 12px 12px 0"
                    search={true}
                    items={Object.fromEntries(
                      Object.entries(
                        countries.getNames(language, { select: 'official' }),
                      ).sort(function ([, a], [, b]) {
                        return a.localeCompare(b);
                      }),
                    )}
                    onSelect={onSelect}
                    zIndex={1}
                    tabIndex={6}
                    required
                  />
                )}
                <ValidateDiv name="countryValidate"></ValidateDiv>
                <Input
                  type="tel"
                  name="phone"
                  width="510px"
                  margin="0"
                  placeholder={t('Phone')}
                  value={form.phone ? form.phone : ''}
                  onChange={onChange}
                  tabIndex={7}
                />
                <Input
                  as="textarea"
                  style={{ resize: 'none', overflow: 'hidden' }}
                  name="address"
                  width="510px"
                  height="66px"
                  margin="0"
                  padding="14px 15px 14px 15px"
                  placeholder={t('Address')}
                  value={form.address ? form.address : ''}
                  onChange={onChange}
                  tabIndex={8}
                  maxLength={200}
                />
                <Avatar>
                  <ImageButton
                    social_auth={social_auth}
                    style={{ marginTop: 8 }}
                    fontSize={'24px'}
                    src={imgSrc}
                    onChangeImage={onChangeImage}
                    tabIndex={9}
                    disabled={disabled}
                    imgRef={imgRef}
                    coverImgRef={coverImgRef}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onDeleteClick={onDeleteClick}
                  />
                  {!social_auth && (
                    <P>
                      {t(
                        'Upload an image so your connections can easily recognize your cases (Optional)',
                      )}
                    </P>
                  )}
                </Avatar>
              </form>
              <MessageBox
                form={form}
                language={language}
                onSelect={onSelect}
                messageConfirmCheck={messageConfirmCheck}
                visible={messageVisible}
                type={messageType}
                title={messageTitle}
                content={messageContent}
                onOk={onConfirmBoxOk}
                onCancel={onCancel}
              />
            </RightDiv>
          </div>
        )}
      </div>
    </MainBlock>
  );
};

export default withRouter(EditProfile);
