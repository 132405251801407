import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import { NavLink, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';
import { Link } from 'react-router-dom';
import { getInitial } from '../common/Util';
import MenuItem from './MenuItem';
import { TabletOrDesktop } from '../../lib/responsive';

const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};

const Container = styled.div`
  display: flex;
  margin: ${(props) => (props.mobile ? '0 0 0 auto' : '0')}; // right align

  .menu-container {
    position: relative;
    display: flex;
  }

  .menu-trigger {
    display: flex;
    justify-content: center;
    /* justify-content: center; */
    background: #ffffff;

    border: solid 0px #e7e9ea;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    justify-content: ${(props) => props.center && 'center'};
    align-items: center;
    vertical-align: middle;
    /* justify-content: space-between; */
    /* transition: box-shadow 0.4s ease; */

    /* Strictly for positioning */

    &:hover {
      //border: solid 1px #eeeff0;
      background: #f5f6f6;
    }

    &:active {
      //border: solid 1px #ff6d22;
      background: #ffffff;
    }

    &:disabled {
      border: solid 1px #e7e9ea;
      background: #f5f6f6;
    }

    span {
      width: auto;
      height: 19px;
      margin-left: 10px;
      padding-right: 40px;
      color: #59616a;
      font-family: 'Source Sans Pro';
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .menu {
    position: absolute;
    /* right: 25; */
    //top: 44px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e7e9ea;
    opacity: 0;
    visibility: hidden;
    //transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

    ///display: block;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      &:first-child {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
    li {
      height: 40px;

      &:hover {
        background: #eeeff0;
      }
    }
    li div {
      font-family: Source Sans Pro;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #59616a;
      text-decoration: none;
      padding: 10px 20px 10px 15px;
      display: block;
      position: absolute;
      right: 0;
      cursor: pointer;
      text-align: end;
    }
  }

  .active {
    z-index: 20000;
    opacity: 1;
    visibility: visible;
    //transform: translateY(0);
  }

  .picture {
    width: 32px;
    height: 32px;
    min-width: 32px;
    line-height: 32px;
    /* margin-left: 5px;
    margin-top: 0px; */
    margin: 16px;
    border-radius: 18px;
    background-color: #787f86;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    float: left;
  }
`;

const StyledImage = styled.img`
  width: ${(props) => props.width || '32px'};
  height: ${(props) => props.height || '32px'};
  margin: ${(props) => !props.margin && '16px'};
  border: 0;
  padding: 0;
  object-fit: cover; /*contain*/
  border-radius: 60%;
`;

const LoggedUser = ({ img, name, width, height, collapsed, onSelect }) => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const onClick = () => setIsActive(!isActive);
  const isTabletOrDesktop = TabletOrDesktop();
  const [menuTop, setMenuTop] = useState(0);
  // var menuRight = isTabletOrDesktop ? 'auto' : '4px';
  var menuRight = isTabletOrDesktop ? 'auto' : '0px';
  var menuWidth = parseInt(width) - 2;
  if (collapsed) menuWidth = menuWidth * 0.75;
  menuWidth += 'px';

  useEffect(() => {
    if (dropdownRef.current.clientHeight) {
      setMenuTop(isTabletOrDesktop ? -dropdownRef.current.clientHeight - 2 - 4 + 'px' : '60px');
    }
  }, []);

  return (
    <Container mobile={!isTabletOrDesktop} center={collapsed}>
      {/* {!isTabletOrDesktop && <MenuItem id="cases" name="Cases" link="/cases" />} */}
      <div
        className="menu-container"
        style={{ width: collapsed === false ? width : '62px', height: height }}
      >
        <button
          className="menu-trigger"
          style={{
            width: collapsed === false ? width : '62px',
            justifyContent: collapsed === false ? 'left' : 'center',
            padding: collapsed === false ? '0px 0px 0px 16px' : '0px',
          }}
          onClick={onClick}
          type="button"
        >
          {img !== null && (
            <StyledImage
              src={img}
              alt="portrait"
              margin={collapsed}
              // style={{
              //   marginLeft: collapsed === false ? '16px' : '0px',
              // }}
            />
          )}

          {img === null && <div className="picture">{getInitial(name)}</div>}

          <span style={{ display: collapsed === false ? 'block' : 'none' }}>{name}</span>
          <img
            src={process.env.PUBLIC_URL + '/images/login/icon-24-arrow-down-active.svg'}
            alt="Arrow-down"
            style={{
              position: 'absolute',
              right: '0',
              margin: '6px 19px 6px 0',
              width: '24px',
              height: '24px',
              display: collapsed === false ? 'block' : 'none',
            }}
          />
        </button>
        <nav
          ref={dropdownRef}
          className={`menu ${isActive ? 'active' : 'inactive'}`}
          style={{ width: menuWidth, top: menuTop, right: menuRight }}
        >
          <ul>
            {isTabletOrDesktop && (
              <>
                {user.is_device_rental_manager && (
                  <li
                    key="0"
                    onClick={(e) => {
                      onSelect('device');
                      onClick();
                    }}
                  >
                    <Link to="/devices" style={{ textDecoration: 'none' }}>
                      <div style={{ width: '85%', textAlign: 'end' }}>{t('Device Management')}</div>
                    </Link>
                  </li>
                )}

                <li
                  key="1"
                  onClick={(e) => {
                    onSelect('groups');
                    onClick();
                  }}
                >
                  <Link to="/groups" style={{ textDecoration: 'none' }}>
                    <div style={{ width: '85%', textAlign: 'end' }}>{t('Group')}</div>
                  </Link>
                </li>

                <li
                  key="2"
                  onClick={(e) => {
                    onSelect('settings');
                    onClick();
                  }}
                >
                  <Link to="/settings" style={{ textDecoration: 'none' }}>
                    <div style={{ width: '85%', textAlign: 'end' }}>{t('Personal Settings')}</div>
                  </Link>
                </li>

                <li
                  key="3"
                  onClick={(e) => {
                    onSelect('profile');
                    onClick();
                  }}
                >
                  <Link to="/profile" style={{ textDecoration: 'none' }}>
                    <div style={{ width: '85%', textAlign: 'end' }}>{t('Edit Profile')}</div>
                  </Link>
                </li>
              </>
            )}

            <li
              key="4"
              onClick={(e) => {
                onSelect('Sign out');
                onClick();
              }}
            >
              <div style={{ width: '85%' }}>{t('Sign out')}</div>
            </li>

            {/* {items.map((item) => (
              <li key={item}>
                <div                  
                  onClick={(e) => {
                    e.preventDefault();                    
                    setSelectedItem(item);
                    onSelect(item);
                    onClick();
                  }}
                >
                  {item}
                </div>
              </li>
            ))} */}
          </ul>
        </nav>
      </div>
    </Container>
  );
};
export default LoggedUser;
