import qs from 'qs';
import client from './client';

export const getTemplateExcelLink = () =>
  client
    .get('v1/cs/devices/registrations/template/', {
      responseType: 'blob',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Scanner Registration Sample.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('Error downloading the file:', error);
    });

export const listDevicesExcel = ({ userRegistered } = {}) =>
  client.get('v1/cs/devices/excel/', {
    params: {
      user_registered_dev: userRegistered !== undefined ? userRegistered : true,
    },
  });

export const setFeature = ({ featureData }) =>
  client.post('v1/cs/devices/features/', featureData);

export const listDevices = ({ filters, page, registered }) => {
  const conds = {};
  // if (filters && filters.model) conds.model = filters.model;
  // if (filters && filters.searchString) conds.search = filters.searchString;
  // if (filters && filters.orderBy) conds.sort = filters.orderBy;
  Object.entries(filters).forEach(([key, value]) => {
    if (key === 'searchString') conds.search = value;
    else if (key === 'orderBy') conds.sort = value;
    else conds[key] = value;
  });
  // if (filters && filters.registeredDevices) var register = filters.registeredDevices;
  if (page) conds.page = page;
  if (registered) conds['user-registeredDevices'] = true;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get(
    // 'v1/cs/devices/' + (queryString ? `?${queryString}` : '') + (register ? register : ''),
    'v1/cs/devices/' + (queryString ? `?${queryString}` : ''),
  );
};

export const addDevices = ({ devices }) => client.post('v1/cs/devices/', devices);

export const resetRegistrations = ({ ids }) => {
  const params = { data: { ids } };
  return client.delete('v1/cs/devices/registrations/reset/', params);
};

export const deviceRegistrationInfo = (id) => client.get(`v1/cs/devices/${id}/`);

export const removeDevices = ({ ids }) => {
  const params = { data: { ids } };
  return client.delete('v1/cs/devices/remove/', params);
};

export const blockDevices = ({ block, ids }) => {
  const params = { block: block, ids };
  return client.post('/v1/cs/devices/block/', params);
};

export const listUserRegistrations = ({ filters, page }) => {
  const conds = {};
  Object.entries(filters).forEach(([key, value]) => {
    if (key === 'searchString') conds.search = value;
    else if (key === 'orderBy') conds.sort = value;
    else conds[key] = value;
  });
  if (page) conds.page = page;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get(
    'v1/cs/user-registrations/' + (queryString ? `?${queryString}` : ''),
  );
};

export const listUsers = ({ filters, page }) => {
  const conds = {};
  if (filters && filters.instType) {
    conds.role =
      filters.instType === 'Clinic' || filters.instType === 'Lab'
        ? `Dental ${filters.instType}`
        : filters.instType;
  }
  if (filters && filters.searchString) conds.search = filters.searchString;
  if (filters && filters.orderBy) conds.sort = filters.orderBy;
  if (page) conds.page = page;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get('v1/cs/users/' + (queryString ? `?${queryString}` : ''));
};

export const listUsersExcel = () => client.get('v1/cs/users/excel/');

export const getUser = (uid) => client.get(`v1/cs/users/${uid}/`);

export const tempLogin = (uid) => client.get(`/v1/cs/users/${uid}/login/`);

export const listCases = ({ filters, page }) => {
  const conds = {};
  if (filters && filters.searchString) conds.search = filters.searchString;
  if (filters && filters.connId) conds.connId = filters.connId;
  if (filters && filters.orderBy) conds.sort = filters.orderBy;
  if (page) conds.page = page;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get('v1/cs/cases/' + (queryString ? `?${queryString}` : ''));
};

export const getGroup = (gid) => client.get(`v1/cs/group/${gid}/`);

export const listConns = ({ filters, page }) => {
  const conds = {};
  if (filters && filters.searchString) conds.search = filters.searchString;
  if (filters && filters.orderBy) conds.sort = filters.orderBy;
  if (page) conds.page = page;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get('v1/cs/connections/' + (queryString ? `?${queryString}` : ''));
};

export const getStats = () => client.get('v1/cs/stats/');

export const createTicket = (data) => client.post('v1/cs/devices/tickets/new/', data);

export const listTickets = ({ filters, page }) => {
  const conds = {};
  if (filters && filters.searchString) conds.search = filters.searchString;
  if (filters && filters.orderBy) conds.sort = filters.orderBy;
  if (page) conds.page = page;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get('v1/cs/devices/tickets/' + (queryString ? `?${queryString}` : ''));
};

export const listLabs = ({ filters, page }) => {
  const conds = {};
  if (filters && filters.searchString) conds.search = filters.searchString;
  if (filters && filters.orderBy) conds.sort = filters.orderBy;
  if (page) conds.page = page;
  const queryString = qs.stringify(conds, { indices: false });
  return client.get('v1/cs/labs/' + (queryString ? `?${queryString}` : ''));
};

export const labRegister = ({ formData }) =>
  client.post('/v1/cs/labs/register/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const removeLabs = ({ ids }) => {
  const params = { ids };
  return client.post('v1/cs/labs/remove/', params);
};

export const editLab = ({ id, formData }) =>
  client.post(`v1/cs/labs/${id}/edit/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const listLab = ({ id }) => client.get(`v1/cs/labs/${id}/`);
