import * as THREE from 'three';

export var loadPly = (function () {
  var loadBinaryPly = function (buffer) {
    var array_buffer = new Uint8Array(buffer);
    var str = [];

    for (var i = 0; i < buffer.byteLength / 2; i++) {
      str += String.fromCharCode(array_buffer[i]); // implicitly assumes little-endian
    }
    for (var i = buffer.byteLength / 2; i < buffer.byteLength; i++) {
      str += String.fromCharCode(array_buffer[i]); // implicitly assumes little-endian
    }

    var header = parseHeader(str);
    // console.log(header);
    const geometry = parseBinary(buffer, header);
    // console.log(geometry);

    return geometry;
  };

  return function (buffer) {
    try {
      // console.log("load as binary ply");
      return loadBinaryPly(buffer);
    } catch (ex) {
      console.log(ex);
    }
  };
})();

function parseHeader(data) {
  var patternHeader = /ply([\s\S]*)end_header\r?\n/;
  var headerText = '';
  var headerLength = 0;
  var result = patternHeader.exec(data);

  if (result !== null) {
    headerText = result[1];
    headerLength = result[0].length;
  }

  var header = {
    comments: [],
    elements: [],
    headerLength: headerLength,
  };

  var lines = headerText.split('\n');
  var currentElement;
  var lineType, lineValues;

  function make_ply_element_property(propertValues) {
    //propertValues : float, x / float, y /  ... / uchar, alpha

    var property = { type: propertValues[0] }; //float

    if (property.type === 'list') {
      property.name = propertValues[3];
      property.countType = propertValues[1];
      property.itemType = propertValues[2];
    } else {
      property.name = propertValues[1]; //x
    }

    //console.log(property);  //type: "float", name: "x"
    return property;
  }

  for (var i = 0; i < lines.length; i++) {
    var line = lines[i];
    line = line.trim();

    if (line === '') continue;

    lineValues = line.split(/\s+/);
    lineType = lineValues.shift();
    line = lineValues.join(' ');

    switch (lineType) {
      case 'format':
        header.format = lineValues[0];
        header.version = lineValues[1];
        break;

      case 'comment':
        header.comments.push(line);
        break;

      case 'element':
        if (currentElement !== undefined) {
          header.elements.push(currentElement);
        }
        currentElement = {};
        currentElement.name = lineValues[0];
        currentElement.count = parseInt(lineValues[1]);
        currentElement.properties = [];
        break;

      case 'property':
        //lineValues : float, x / float, y /  ... / uchar, alpha
        currentElement.properties.push(make_ply_element_property(lineValues));
        // console.log(currentElement.properties);
        break;

      default:
        console.log('unhandled', lineType, lineValues);
    }
  }

  if (currentElement !== undefined) {
    header.elements.push(currentElement);
    // console.log(currentElement);
  }
  // console.log(currentElement.properties);

  return header;
}
//(ArrayBuffer, Text)
function parseBinary(data, header) {
  var buffer = {
    indices: [],
    vertices: [],
    normals: [],
    uvs: [],
    faceVertexUvs: [],
    colors: [],
  };

  var little_endian = header.format === 'binary_little_endian';
  var body = new DataView(data, header.headerLength);

  var result,
    loc = 0;

  for (
    var currentElement = 0;
    currentElement < header.elements.length;
    currentElement++
  ) {
    for (
      var currentElementCount = 0;
      currentElementCount < header.elements[currentElement].count;
      currentElementCount++
    ) {
      result = binaryReadElement(
        body,
        loc,
        header.elements[currentElement].properties,
        little_endian,
      );
      var element = result[0]; //element
      loc += result[1]; //read
      handleElement(buffer, header.elements[currentElement].name, element);
    }
  }

  // console.log('vertices:' + buffer.vertices);
  return postProcess(buffer);
}

function binaryReadElement(dataview, at, properties, little_endian) {
  var element = {};
  var result,
    read = 0;

  for (var i = 0; i < properties.length; i++) {
    if (properties[i].type === 'list') {
      var list = [];

      result = binaryRead(dataview, at + read, properties[i].countType, little_endian);
      var n = result[0];
      read += result[1];

      for (var j = 0; j < n; j++) {
        result = binaryRead(dataview, at + read, properties[i].itemType, little_endian);
        list.push(result[0]);
        read += result[1];
      }

      element[properties[i].name] = list;
    } else {
      result = binaryRead(dataview, at + read, properties[i].type, little_endian);
      read += result[1];
      element[properties[i].name] = result[0];
    }
  }

  return [element, read];
}

function binaryRead(dataview, at, type, little_endian) {
  switch (type) {
    // corespondences for non-specific length types here match rply:
    case 'int8':
    case 'char':
      return [dataview.getInt8(at), 1];
    case 'uint8':
    case 'uchar':
      return [dataview.getUint8(at), 1];
    case 'int16':
    case 'short':
      return [dataview.getInt16(at, little_endian), 2];
    case 'uint16':
    case 'ushort':
      return [dataview.getUint16(at, little_endian), 2];
    case 'int32':
    case 'int':
      return [dataview.getInt32(at, little_endian), 4];
    case 'uint32':
    case 'uint':
      return [dataview.getUint32(at, little_endian), 4];
    case 'float32':
    case 'float':
      return [dataview.getFloat32(at, little_endian), 4];
    case 'float64':
    case 'double':
      return [dataview.getFloat64(at, little_endian), 8];
  }
}

function handleElement(buffer, elementName, element) {
  if (elementName === 'vertex') {
    buffer.vertices.push(element.x, element.y, element.z);

    if ('nx' in element && 'ny' in element && 'nz' in element) {
      buffer.normals.push(element.nx, element.ny, element.nz);
    }

    if ('s' in element && 't' in element) {
      buffer.uvs.push(element.s, element.t);
    }

    if ('red' in element && 'green' in element && 'blue' in element) {
      //color
      buffer.colors.push(
        element.red / 255.0,
        element.green / 255.0,
        element.blue / 255.0,
      );
    }
  } else if (elementName === 'face') {
    var vertex_indices = element.vertex_indices || element.vertex_index; // issue #9338
    var texcoord = element.texcoord;

    if (vertex_indices.length === 3) {
      buffer.indices.push(vertex_indices[0], vertex_indices[1], vertex_indices[2]);

      if (texcoord && texcoord.length === 6) {
        buffer.faceVertexUvs.push(texcoord[0], texcoord[1]);
        buffer.faceVertexUvs.push(texcoord[2], texcoord[3]);
        buffer.faceVertexUvs.push(texcoord[4], texcoord[5]);
      }
    } else if (vertex_indices.length === 4) {
      buffer.indices.push(vertex_indices[0], vertex_indices[1], vertex_indices[3]);
      buffer.indices.push(vertex_indices[1], vertex_indices[2], vertex_indices[3]);
    }
  }
}

function postProcess(buffer) {
  var geometry = new THREE.BufferGeometry();

  // mandatory buffer data
  if (buffer.indices.length > 0) {
    geometry.setIndex(buffer.indices);
    // console.log(buffer.indices);
  }

  geometry.setAttribute(
    'position',
    new THREE.Float32BufferAttribute(buffer.vertices, 3),
  );

  // optional buffer data
  if (buffer.normals.length > 0) {
    geometry.setAttribute(
      'normal',
      new THREE.Float32BufferAttribute(buffer.normals, 3),
    );
  } else {
    // if no normals, compute nomals
    geometry.computeVertexNormals();
  }

  // if ( buffer.uvs.length > 0 ) {
  //   geometry.setAttribute('uv', new THREE.Float32BufferAttribute( buffer.uvs, 2 ) );
  // }

  if (buffer.colors.length > 0) {
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(buffer.colors, 3));
  }

  // if ( buffer.faceVertexUvs.length > 0 ) {
  //   geometry = geometry.toNonIndexed();
  //   geometry.setAttribute('uv', new THREE.Float32BufferAttribute( buffer.faceVertexUvs, 2 ) );
  // }
  geometry.computeBoundingSphere();

  return geometry;
}
