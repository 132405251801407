import qs from 'qs';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { listGroupUsers } from '../../modules/groups';
import Groups from '../../components/groups/Groups';
import { acceptGroupUser } from '../../lib/api/groups';
import { useRefreshOnAuthError, useAutoRefreshEvent } from '../../lib/common';

const GroupsContainer = ({ match, location, history }) => {
  const { sguidb64, token } = match.params;
  const dispatch = useDispatch();
  const { app, user, userSettings, userChecking, loading_newgroup, group_res, error } = useSelector(
    ({ app, user, groups, loading }) => ({
      app: app.app,
      user: user.user,
      userSettings: user.settings,
      userChecking: loading['user/CHECK'],
      loading_newgroup: loading['group/NEW_GROUP'],
      group_res: groups.group_res,
      error: groups.error,
    }),
  );
  const [accept, setAccept] = useState(null);

  const event = useAutoRefreshEvent();

  useEffect(() => {
    if (event) {
      const msg = JSON.parse(event);
      if (!window.actionIDs.has(msg.action_id)) {
        if (
          msg.id === 'sharegroup_modified' ||
          msg.id === 'sharegroup_deleted' ||
          msg.id === 'sguser_requested' ||
          msg.id === 'sguser_accepted' ||
          msg.id === 'sguser_rejected' ||
          msg.id === 'sguser_deleted'
        ) {
          dispatch(listGroupUsers());
        }
      }
    }
  }, [event]);

  useEffect(() => {
    if (!userChecking) {
      dispatch(listGroupUsers());
    }
  }, [dispatch, userChecking]);

  const acceptUser = async (sguidb64, token, sid) => {
    try {
      if (sid) {
        const srv_loc = Buffer.from(sid, 'base64').toString('utf8');
        // if (srv_loc === app.server_loc) {
        const res = await acceptGroupUser({ sguidb64, token, srv_loc });
        setAccept({ result: true, detail: res.data.detail });
        // } else {
        //   setAccept({ result: false, detail: 'Server not same' });
        // }
      } else {
        setAccept({ result: false, detail: 'no server location' });
      }
    } catch (e) {
      setAccept({ result: false, error: e });
    }
  };

  useEffect(() => {
    if (sguidb64 && token && !userChecking) {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      acceptUser(sguidb64, token, params.sid);
    }
  }, [userChecking]);

  const createNewConnction = (email, alias, message) => {
    alert(email + ' ' + alias + ' ' + message);
  };

  const acceptInvitation = (id) => {
    alert(id);
  };

  const addNewCase = (id) => {
    alert(id);
  };

  const searchEnterPress = (filter) => {
    alert(filter);
  };

  useRefreshOnAuthError();

  return (
    <>
      <Groups match={match.params} history={history} accept={accept} />
    </>
  );
};

export default withRouter(GroupsContainer);
