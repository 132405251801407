import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import HButton from '../common/HButton';
import TextBox from '../leftpanel/TextBox';

import { useTranslation } from 'react-i18next';

import CheckBox from '../common/CheckBox';
import InputSelect from '../common/InputSelect';
import { selectedMenu } from '../../modules/leftpanel';

import DateMenu from './CalendarDateMenu.js';
import DateView from './CalendarDateView.js';
import Navigator from './CalendarNavigator.js';
import SVG from '../common/svg';
import { theme } from '../../lib/odm';
import { TabletOrDesktop } from '../../lib/responsive';

//#region styled components
const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBlock = styled.div`
  /* width: 526px; */
  width: ${(props) => props.width};
  height: 505px;
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${slideUp};
    `}
`;

const Content = styled.div`
  /* width: 526px; */
  width: ${(props) => props.width};
  height: 359px;
  display: flex;
  justify-content: center;
  //border: solid 1px #eeeff0;
`;

const TitleBlock = styled.div`
  .titleBox {
    width: ${(props) => props.width};
    height: 56px;
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 0;
    background: #eeeff0;
  }

  .titleText {
    /* width: 400px; */
    width: 250px;
    height: 25px;
    margin: 15px 0 0 19px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    float: left;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 16px 19px 0 0;
    float: right;
  }
`;

const BottomLine = styled.div`
  display: table;
  width: ${(props) => props.lineWidth};
  height: 1px;
  margin-left: 20px;
  background-color: #e7e9ea;
`;

const ButtonBlock = styled.div``;

const LeftMenu = styled.div`
  float: left;
  width: 200px;
  height: 359px;
  //margin-left: 19px;
  //padding-left: 19px;
  //padding-top: 60px;
`;

const VertDivideLine = styled.div`
  float: left;
  width: 1px;
  height: 320px;
  margin-top: 20px;
  background-color: #e7e9ea;
`;

const CalendarTool = styled.div`
  width: 320px;
  height: 359px;
  float: left;
`;

// const DateMenu = styled.div`
//   width: 160px;
//   height: 40px;

//   border-radius: 4px;
//   background-color: #ffffff;

//   font-family: Source Sans Pro;
//   font-size: 14px;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.14;
//   letter-spacing: 0.2px;

//   display: flex;
//   justify-content: center;
//   align-items: center;

//   color: #59616a;

//   &:hover {
//     color: #e5621f;
//     background: #ffffff;
//   }

//   &:active {
//     background: #ff6d22;
//     color: #ffffff;
//   }
// `;

//#endregion

const subTitleFont = {
  width: '100%',
  margin: '24px 421px 16px 19px',
  fontFamily: 'Source Sans Pro',
  fontSize: '14px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '0.2px',
  color: '#59616a',
};

const textFont = {
  fontFamily: 'Source Sans Pro',
  fontSize: '15px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#59616a',
  float: 'left',
};

const Calendar = ({ visible, fromto, selDate, onModalClose }) => {
  const closeActive = process.env.PUBLIC_URL + '/images/common/icon-24-close-active.svg';
  const closeHover = process.env.PUBLIC_URL + '/images/common/icon-24-close-hover.svg';
  const closeDisabled = process.env.PUBLIC_URL + '/images/common/icon-24-close-disabled.svg';
  const closeSelected = process.env.PUBLIC_URL + '/images/common/icon-24-close-selected.svg';
  const calendarActive = process.env.PUBLIC_URL + '/images/filters/icon-24-calendar-active.svg';

  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState('');
  const [selectedDate, setSelectedDate] = useState([]);
  const [curDate, setCurDate] = useState(new Date());
  const isTabletOrDesktop = TabletOrDesktop();
  //const [day, setDay] = useState(1);
  const mobileWidth = isTabletOrDesktop ? '526px' : '320px';

  var monDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  function dayOfWeek(date) {
    var r = date.getDay() - 1;
    if (r < 0) r = 6;

    return r;
    // if ((date.getFullYear() % 4 === 0 && date.getFullYear() % 100 != 0) || (date.getFullYear() % 400 == 0))
    // {
    //   monDay[1] = 29;
    // }

    // var total_day = 0;

    // for (var i=1; i<date.getMonth(); i++)
    // {
    //   total_day += monDay[i-1];
    // }
    // total_day += date.getDate() - 1;
    // return (4 + total_day) % 7;
  }

  function setSelectedDays(menu) {
    var today = new Date();

    if (menu === 'Today') {
      setSelectedDate([today, today]);
    } else if (menu === 'Yesterday') {
      var yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      setSelectedDate([yesterday, yesterday]);
    } else if (menu === 'This week') {
      const dow = dayOfWeek(today);

      var firstWeek = new Date();

      console.log(today.getDate());
      firstWeek.setDate(today.getDate() - dow);

      var selInfo = [];

      for (var i = 0; i < 7; i++) {
        var d = new Date();
        d.setDate(firstWeek.getDate() + i);
        selInfo[i] = d;
      }

      setSelectedDate(selInfo);
    } else if (menu === 'Last week') {
      var firstWeek = new Date();
      var date = today.getDate() - dayOfWeek(today) - 7;

      firstWeek.setDate(date);

      var selInfo = [];

      for (var i = 0; i < 7; i++) {
        var d = new Date(firstWeek);
        d.setDate(firstWeek.getDate() + i);
        selInfo[i] = d;
      }

      setSelectedDate(selInfo);
    } else if (menu === 'This month') {
      var firstMonth = new Date();
      firstMonth.setDate(today.getDate() - (today.getDate() - 1));

      var selInfo = [];

      for (var i = 0; i < monDay[today.getMonth()]; i++) {
        var d = new Date();
        d.setDate(firstMonth.getDate() + i);
        selInfo[i] = d;
      }

      setSelectedDate(selInfo);
    } else if (menu === 'Last month') {
      var firstLastMonth = new Date();
      firstLastMonth.setDate(today.getDate() - today.getDate());
      firstLastMonth.setDate(firstLastMonth.getDate() - (firstLastMonth.getDate() - 1));

      var d = new Date();
      d.setMonth(firstLastMonth.getMonth());
      d.setDate(firstLastMonth.getDate() + 1);

      var selInfo = [];

      for (var i = 0; i < monDay[firstLastMonth.getMonth()]; i++) {
        var d = new Date();
        d.setMonth(firstLastMonth.getMonth());
        d.setDate(i + 1);
        selInfo[i] = d;
      }

      setSelectedDate(selInfo);
    }
  }

  function onDateMenuClick(menu) {
    var d = new Date();

    setSelectedDays(menu);

    if (menu === 'Last month') {
      d.setMonth(d.getMonth() - 1);
    }

    setCurDate(d);
    setSelectedMenu(menu);
  }

  useEffect(() => {
    //console.log(selDate);
    setCurDate(selDate[0] === null ? new Date() : selDate[0]);
    setSelectedDate(selDate[0] === null ? [new Date()] : selDate);
  }, [selDate]);

  function onReset() {
    setCurDate(new Date());
    setSelectedDate([]);
    setSelectedMenu('');
  }

  function onDateChanged(date) {
    setCurDate(date);
  }

  function onSelectedDate(selDate) {
    setSelectedDate(selDate);
  }

  if (visible === false) return null;

  const menuInfo = ['Yesterday', 'Today', 'This week', 'Last week', 'This month', 'Last month'];

  return (
    <Fullscreen disappear={!visible}>
      <ModalBlock disappear={!visible} width={mobileWidth}>
        <TitleBlock width={mobileWidth}>
          <div className="titleBox">
            <div className="titleText">{t('Pick a date')}</div>
            <div
              className="closeIcon"
              onClick={() => {
                onModalClose(false);
              }}
            >
              <SVG
                name="close"
                color="#878D93"
                colorOver={theme.main_color_hover}
                colorOut="#878D93"
                colorDown={theme.main_color}
              />
            </div>
            {/* <img
              className={'closeIcon'}
              src={closeActive}
              onMouseOver={(e) => (e.currentTarget.src = closeHover)}
              onMouseOut={(e) => (e.currentTarget.src = closeActive)}
              onMouseDown={(e) => (e.currentTarget.src = closeSelected)}
              onClick={() => onModalClose()}
            /> */}
          </div>
        </TitleBlock>

        <Content width={mobileWidth}>
          {isTabletOrDesktop && (
            <>
              <LeftMenu>
                <div style={{ width: '100%', height: '60px' }} />
                {menuInfo.map((item) => (
                  <div key={item}>
                    <DateMenu
                      id={item}
                      name={t(item).toUpperCase()}
                      selectedMenu={selectedMenu}
                      onClick={onDateMenuClick}
                    />
                  </div>
                ))}
              </LeftMenu>
              <VertDivideLine />
            </>
          )}

          <CalendarTool>
            <Navigator date={curDate} onChanged={onDateChanged} />
            <DateView date={curDate} selectedDate={selectedDate} onSelectedDate={onSelectedDate} />
          </CalendarTool>
        </Content>

        <BottomLine lineWidth={isTabletOrDesktop ? '469px' : '279px'} />

        <div style={{ display: 'table', clear: 'both' }} />

        <ButtonBlock>
          <HButton
            type="button"
            category="Primary"
            size="Medium"
            text={t('Save Dates').toUpperCase()}
            width="auto"
            margin="30px 20px 0 0"
            float="right"
            onClick={() => {
              onModalClose(fromto, selectedDate);
            }}
          />
          <HButton
            type="button"
            category="Secondary"
            size="Medium"
            text={t('Reset').toUpperCase()}
            width="auto"
            margin="30px 16px 0 0"
            float="right"
            onClick={() => {
              onReset();
            }}
          />
        </ButtonBlock>
      </ModalBlock>
    </Fullscreen>
  );
};

export default Calendar;
