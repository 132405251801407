import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import HButton from '../common/HButton';
import TextBox from '../leftpanel/TextBox';

import { useTranslation } from 'react-i18next';

import CheckBox from '../common/CheckBox';
import InputSelect from '../common/InputSelect';
import { selectedMenu } from '../../modules/leftpanel';
import { getODM } from '../../lib/odm';

//#region styled components
const DateMenu = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;

  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: #59616a;
  margin-left: 19px;
`;

const CalendarDateMenu = ({ name, id, selectedMenu, onClick }) => {
  function getBkgColor() {
    /* if (selectedMenu === id)
    {
      return '#ff6d22';
    } */

    if (mouseState === 'enter') {
      return '#ffffff';
    }

    if (mouseState === 'down') {
      return getODM().main_color;
    }

    if (mouseState === 'up') {
      return '#ffffff';
    }

    return '#ffffff';
  }

  function getFontColor() {
    if (selectedMenu === 'down') {
      return '#ffffff';
    }

    if (mouseState === 'enter') {
      return getODM().main_color;
    } else if (mouseState === 'down') {
      return '#ffffff';
    }

    return '#59616a';
  }

  const { t } = useTranslation();
  const [mouseState, setMouseState] = useState('leave');

  return (
    <DateMenu
      style={{ backgroundColor: getBkgColor(), color: getFontColor() }}
      onClick={() => onClick(id)}
      onMouseDown={() => setMouseState('down')}
      onMouseUp={() => setMouseState('up')}
      onMouseEnter={() => setMouseState('enter')}
      onMouseLeave={() => setMouseState('leave')}
    >
      {name}
    </DateMenu>
  );
};

export default CalendarDateMenu;
