import { createAction, handleActions } from 'redux-actions';
import { produce } from 'immer';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as csAPI from '../lib/api/cs';
import { takeLatest, put } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

const INITIALIZE_FORM = 'cs/INITIALIZE_FORM';
const INIT_USER = 'cs/INIT_USER';
const [LIST_DEVICES, LIST_DEVICES_SUCCESS, LIST_DEVICES_FAILURE] =
  createRequestActionTypes('cs/LIST_DEVICES');
const [LIST_DEVICES_EXCEL, LIST_DEVICES_EXCEL_SUCCESS, LIST_DEVICES_EXCEL_FAILURE] =
  createRequestActionTypes('cs/LIST_DEVICES_EXCEL');
const [
  LIST_USER_REGISTRATIONS,
  LIST_USER_REGISTRATIONS_SUCCESS,
  LIST_USER_REGISTRATIONS_FAILURE,
] = createRequestActionTypes('cs/LIST_USER_REGISTRATIONS');
const [LIST_USERS, LIST_USERS_SUCCESS, LIST_USERS_FAILURE] =
  createRequestActionTypes('cs/LIST_USERS');
const [LIST_USERS_EXCEL, LIST_USERS_EXCEL_SUCCESS, LIST_USERS_EXCEL_FAILURE] =
  createRequestActionTypes('cs/LIST_USERS_EXCEL');
const [USER, USER_SUCCESS, USER_FAILURE] = createRequestActionTypes('cs/USER');
const [LIST_CASES, LIST_CASES_SUCCESS, LIST_CASES_FAILURE] =
  createRequestActionTypes('cs/LIST_CASES');
const INIT_GROUP = 'cs/INIT_GROUP';
const [GROUP, GROUP_SUCCESS, GROUP_FAILURE] = createRequestActionTypes('cs/GROUP');
const [LIST_CONNS, LIST_CONNS_SUCCESS, LIST_CONNS_FAILURE] =
  createRequestActionTypes('cs/LIST_CONNS');
const [STATS, STATS_SUCCESS, STATS_FAILURE] = createRequestActionTypes('cs/STATS');
const [LIST_TICKETS, LIST_TICKETS_SUCCESS, LIST_TICKETS_FAILURE] =
  createRequestActionTypes('cs/LIST_TICKETS');
const [LIST_LABS, LIST_LABS_SUCCESS, LIST_LABS_FAILURE] =
  createRequestActionTypes('cs/LIST_LABS');
const [REGISTER_LAB, REGISTER_LAB_SUCCESS, REGISTER_LAB_FAILURE] =
  createRequestActionTypes('cs/REGISTER_LAB');
const [EDIT_LAB, EDIT_LAB_SUCCESS, EDIT_LAB_FAILURE] =
  createRequestActionTypes('cs/EDIT_LAB');
const CHANGE_FIELD = 'cs/CHANGE_FIELD';
const [LIST_LAB, LIST_LAB_SUCCESS, LIST_LAB_FAILURE] =
  createRequestActionTypes('cs/LIST_LAB');

export const initializeForm = createAction(INITIALIZE_FORM, (form) => form);
export const initUser = createAction(INIT_USER);
export const listDevicesExcel = createAction(LIST_DEVICES_EXCEL);
export const listDevices = createAction(
  LIST_DEVICES,
  ({ filters, page, registered }) => ({
    filters,
    page,
    registered,
  }),
);
export const listUserRegistrations = createAction(
  LIST_USER_REGISTRATIONS,
  ({ filters, page }) => ({
    filters,
    page,
  }),
);
export const listUsers = createAction(LIST_USERS, ({ filters, page }) => ({
  filters,
  page,
}));
export const listUsersExcel = createAction(LIST_USERS_EXCEL);
export const getUser = createAction(USER, (uid) => uid);
export const listCases = createAction(LIST_CASES, ({ filters, page }) => ({
  filters,
  page,
}));
export const initGroup = createAction(INIT_GROUP);
export const getGroup = createAction(GROUP, (gid) => gid);
export const listConns = createAction(LIST_CONNS, ({ filters, page }) => ({
  filters,
  page,
}));
export const getStats = createAction(STATS);
export const listTickets = createAction(LIST_TICKETS, ({ filters, page }) => ({
  filters,
  page,
}));
export const listLabs = createAction(LIST_LABS, ({ filters, page }) => ({
  filters,
  page,
}));
export const register_Lab = createAction(REGISTER_LAB, ({ formData }) => ({
  formData,
}));
export const edit_Lab = createAction(EDIT_LAB, ({ id, formData }) => ({
  id,
  formData,
}));
export const changeField = createAction(CHANGE_FIELD, ({ form, key, value }) => ({
  form,
  key,
  value,
}));
export const listLab = createAction(LIST_LAB, ({ id }) => ({
  id,
}));

const listDevicesExcelSaga = createRequestSaga(
  LIST_DEVICES_EXCEL,
  csAPI.listDevicesExcel,
);
const listDevicesSaga = createRequestSaga(LIST_DEVICES, csAPI.listDevices);
const listUserRegistrationsSaga = createRequestSaga(
  LIST_USER_REGISTRATIONS,
  csAPI.listUserRegistrations,
);
const listUsersSaga = createRequestSaga(LIST_USERS, csAPI.listUsers);
const listUsersExcelSaga = createRequestSaga(LIST_USERS_EXCEL, csAPI.listUsersExcel);
const userSaga = createRequestSaga(USER, csAPI.getUser);
const listCasesSaga = createRequestSaga(LIST_CASES, csAPI.listCases);
const groupSaga = createRequestSaga(GROUP, csAPI.getGroup);
const listConnsSaga = createRequestSaga(LIST_CONNS, csAPI.listConns);
const statsSaga = createRequestSaga(STATS, csAPI.getStats);
const listTicketsSaga = createRequestSaga(LIST_TICKETS, csAPI.listTickets);
const listLabsSaga = createRequestSaga(LIST_LABS, csAPI.listLabs);
const registerLabSaga = createRequestSaga(REGISTER_LAB, csAPI.labRegister);
const editLabSaga = createRequestSaga(EDIT_LAB, csAPI.editLab);
const listLabSaga = createRequestSaga(LIST_LAB, csAPI.listLab);

function* registerLabSuccessSaga({ payload }) {
  yield put(push('/adm/labs'));
  yield put(initializeForm('lab'));
}

export function* csSaga() {
  yield takeLatest(LIST_DEVICES_EXCEL, listDevicesExcelSaga);
  yield takeLatest(LIST_DEVICES, listDevicesSaga);
  yield takeLatest(LIST_USER_REGISTRATIONS, listUserRegistrationsSaga);
  yield takeLatest(LIST_USERS, listUsersSaga);
  yield takeLatest(LIST_USERS_EXCEL, listUsersExcelSaga);
  yield takeLatest(USER, userSaga);
  yield takeLatest(LIST_CASES, listCasesSaga);
  yield takeLatest(GROUP, groupSaga);
  yield takeLatest(LIST_CONNS, listConnsSaga);
  yield takeLatest(STATS, statsSaga);
  yield takeLatest(LIST_TICKETS, listTicketsSaga);
  yield takeLatest(LIST_LABS, listLabsSaga);
  yield takeLatest(REGISTER_LAB, registerLabSaga);
  yield takeLatest(REGISTER_LAB_SUCCESS, registerLabSuccessSaga);
  yield takeLatest(EDIT_LAB, editLabSaga);
  yield takeLatest(EDIT_LAB_SUCCESS, registerLabSuccessSaga);
  yield takeLatest(LIST_LAB, listLabSaga);
}

const initialState = {
  // page: null,
  devices: null,
  // deviceModels: null,
  user_regs: null,
  users: null,
  user: null,
  cases: null,
  group: null,
  conns: null,
  stats: null,
  tickets: null,
  labs: null,
  lab: {
    name: '',
    email: '', //(hcd lab account)
    company_name: '',
    company_address: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    lab_logo: '',
    country: '',
    is_active: true,
  },
  error: null,
  users_excel: null,
  devices_excel: null,
};

const cs = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      auth: null,
      authError: null,
    }),
    [INIT_USER]: (state) => ({
      ...state,
      user: null,
    }),
    [LIST_DEVICES_EXCEL_SUCCESS]: (state, { payload: devices_excel }) => ({
      ...state,
      devices_excel,
      error: null,
    }),
    [LIST_DEVICES_EXCEL_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_DEVICES_SUCCESS]: (state, { payload: devices }) => ({
      ...state,
      // devices: devices.devices,
      // page: devices.page,
      // deviceModels: devices.deviceModels,
      devices,
      error: null,
    }),
    [LIST_DEVICES_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_USER_REGISTRATIONS_SUCCESS]: (state, { payload: user_regs }) => ({
      ...state,
      user_regs,
      error: null,
    }),
    [LIST_USER_REGISTRATIONS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_USERS_SUCCESS]: (state, { payload: users }) => ({
      ...state,
      // users: users.users,
      // page: users.page,
      users,
      error: null,
    }),
    [LIST_USERS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_USERS_EXCEL_SUCCESS]: (state, { payload: users_excel }) => ({
      ...state,
      users_excel: users_excel.users_excel,
      error: null,
    }),
    [LIST_USERS_EXCEL_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [USER_SUCCESS]: (state, { payload: user }) => ({
      ...state,
      user,
      error: null,
    }),
    [USER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_CASES_SUCCESS]: (state, { payload: cases }) => ({
      ...state,
      // cases: cases.cases,
      // page: cases.page,
      cases,
      error: null,
    }),
    [LIST_CASES_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [INIT_GROUP]: (state) => ({
      ...state,
      group: null,
    }),
    [GROUP_SUCCESS]: (state, { payload: group }) => ({
      ...state,
      group,
      error: null,
    }),
    [GROUP_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_CONNS_SUCCESS]: (state, { payload: conns }) => ({
      ...state,
      // conns: conns.conns,
      // page: conns.page,
      conns,
      error: null,
    }),
    [LIST_CONNS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [STATS_SUCCESS]: (state, { payload: stats }) => ({
      ...state,
      stats,
      error: null,
    }),
    [STATS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_TICKETS_SUCCESS]: (state, { payload: tickets }) => ({
      ...state,
      // tickets: tickets.tickets,
      // page: tickets.page,
      tickets,
      error: null,
    }),
    [LIST_TICKETS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_LABS_SUCCESS]: (state, { payload: labs }) => ({
      ...state,
      labs,
      error: null,
    }),
    [LIST_LABS_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [REGISTER_LAB_SUCCESS]: (state, { payload: lab }) => ({
      ...state,
      error: null,
    }),
    [REGISTER_LAB_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [EDIT_LAB_SUCCESS]: (state, { payload: lab }) => ({
      ...state,
      error: null,
    }),
    [EDIT_LAB_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [LIST_LAB_SUCCESS]: (state, { payload: lab }) => ({
      ...state,
      lab,
      error: null,
    }),
    [LIST_LAB_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
  },
  initialState,
);

export default cs;
