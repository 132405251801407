import client from './client';
import qs from 'qs';
import { newActionId } from '../../index';

export const listLabs = () => {
  return client.get('/v1/labs/');
};

export const listConnections = (search = null) => {
  const queryString = qs.stringify({ search: search });
  return client.get('/v1/connections/' + (search ? `?${queryString}` : ''));
};

export const createLabConnection = ({ labId }) =>
  client.post(
    `/v1/labs/${labId}/request-connection/`,
    {},
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

export const createConnection = ({ email, alias, message }) =>
  client.post(
    '/v1/connections/0/request/',
    {
      email,
      alias,
      text: message,
    },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

export const requestConnection = ({ email, alias, message, id }) =>
  client.post(
    `/v1/connections/${id}/request/`,
    {
      email,
      alias,
      text: message,
    },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

export const acceptConnection = ({ cidb64, token, srv_loc = null }) => {
  let uri = `/v1/connections/accept/${cidb64}/${token}/`;
  if (srv_loc) uri += `?srv_loc=${srv_loc}`;
  return client.put(uri, null, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });
};

export const rejectConnection = (id) =>
  client.put(`/v1/connections/${id}/reject/`, null, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });

export const editConnection = ({ id, alias }) =>
  client.put(
    `/v1/connections/${id}/edit/`,
    { alias },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

export const removeConnection = (id) =>
  client.delete(`/v1/connections/${id}/remove/`, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });
