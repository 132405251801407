import React from 'react';
import styled, { css } from 'styled-components';

const colorStyles = css`
  background-color: ${(props) => props.theme.main_color};
  &:hover {
    color: #ffffff;
    background-color: ${(props) => props.theme.main_color_hover};
  }
  &:active {
    border: solid 1px ${(props) => props.theme.main_color};
    color: ${(props) => props.theme.main_color};
    background-color: ${(props) => props.theme.main_color_focus};
  }
  &:disabled {
    color: #c3c6c9;
    background-color: #f5f6f6;
  }
`;

const sizeStyles = css`
  ${({ width, height }) => css`
    width: ${width};
    height: ${height};
  `}
`;

const StyledButton = styled.button`
  /* Common */
  margin: ${(props) => props.margin};
  outline: none;
  border: none;
  padding: 0;
  border-radius: 4px;
  color: white;

  font-family: 'Source Sans Pro';
  font-size: ${(props) => props.fontSize || '16px'};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) => props.lineHeight || 1};
  letter-spacing: ${(props) => props.letterSpacing || '0.1px'};
  text-align: center;
  cursor: pointer;
  user-select: none; /*Prevent drag*/

  /* Size */
  ${sizeStyles}

  /* Color */
  ${colorStyles}
`;

function Button({ children, color, margin, width, height, ...rest }) {
  return (
    <StyledButton color={color} margin={margin} width={width} height={height} {...rest}>
      {children}
    </StyledButton>
  );
}

Button.defaultProps = {
  color: '#ff6d22',
  width: '448px',
  height: '48px',
  margin: '24px 496px 0 496px',
};

export default React.memo(Button);
