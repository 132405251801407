import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addLockConnections } from '../../lib/api/devices';
import { listDevices } from '../../modules/devices';
import Devices from '../../components/lab/Devices';

const DeviceContainer = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({ searchString: null });
  const [page, setPage] = useState(1);
  const { devices, loading, userChecking, user, odm } = useSelector(
    ({ devices, loading, user, app }) => ({
      devices: devices.devices,
      loading: loading['devices/LIST_DEVICES'],
      userChecking: loading['user/CHECK'],
      user: user.user,
      odm: app.odm,
    }),
  );
  const pageInfo = devices && devices.page;
  const error = devices && devices.error;

  // get case list when user checking is done and filters changed
  useEffect(() => {
    if (!userChecking) {
      dispatch(listDevices({ filters, page }));
    }
  }, [userChecking, filters, page]);

  const paginate = (number) => {
    setPage(number);
  };

  const onEditLockConnections = async (locked_list) => {
    let dev_conns = [];
    locked_list.forEach((item) => {
      dev_conns.push({
        dev_id: item.id,
        user_id: user.id,
        dev_locked: item.locked,
      });
    });
    // console.log(dev_conns);
    try {
      await addLockConnections({ dev_conns });
      dispatch(listDevices({ filters, page }));
    } catch (e) {
      console.log(e);
    }
  };

  const onSearchEnter = async (_filters) => {
    try {
      setFilters({ ...filters, searchString: _filters });
      dispatch(listDevices({ filters, page }));
    } catch (e) {
      console.log(e);
    }
  };

  const onSort = (sortQuery) => {
    setFilters({ ...filters, orderBy: sortQuery });
  };

  return (
    <>
      <Devices
        loading={loading}
        devices={devices}
        pageInfo={pageInfo}
        paginate={paginate}
        user={user}
        odm={odm}
        onSearchEnter={onSearchEnter}
        onSort={onSort}
        setPage={setPage}
        onEditLockConnections={onEditLockConnections}
      />
    </>
  );
};

export default withRouter(DeviceContainer);
