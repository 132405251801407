import React from 'react';
import styled from 'styled-components';
import PasswordAssistanceContainer from '../containers/auth/PasswordAssistanceContainer';

const Body = styled('div')`
  /* 
  max-width: 1440px;
  min-height: 798px; */
  /* height: 100vh; */
  min-width: 360px;
  width: 100%;
  height: 100%;
  margin: auto;
  background: #eeeff0;
`;

const PasswordAssistancePage = () => {
  return (
    <Body>
      <PasswordAssistanceContainer />
    </Body>
  );
};

export default PasswordAssistancePage;
