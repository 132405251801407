import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const CaseDetailTeethBox = styled.div`
  /* margin-left: 20px; */
  margin-top: 22px;
  width: 100%;
  font-family: Source Sans Pro;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 15px;
  font-weight: normal;
  color: #59616a;

  .caseDetailTeeth {
    margin-left: 20px;
    margin-top: 3px;
    height: auto;
  }

  .caseDetailTeeth::after {
    content: '';
    display: table;
    clear: both;
  }

  .caseDetailTeethName {
    font-size: 20px;
    font-weight: 600;
    float: left;
  }

  .caseDetailTeethNo {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    float: left;
  }

  .caseDetailTeethShadeMaterial {
    height: 19px;
    margin-top: 5px;
    margin-left: 20px;
    text-align: bottom;
    float: left;
  }
`;

const CaseDetailTeeth = ({ content }) => {
  const { t } = useTranslation();

  const FDI = [
    { id: 'tu1', str: '18' },
    { id: 'tu2', str: '17' },
    { id: 'tu3', str: '16' },
    { id: 'tu4', str: '15' },
    { id: 'tu5', str: '14' },
    { id: 'tu6', str: '13' },
    { id: 'tu7', str: '12' },
    { id: 'tu8', str: '11' },
    { id: 'tu9', str: '21' },
    { id: 'tu10', str: '22' },
    { id: 'tu11', str: '23' },
    { id: 'tu12', str: '24' },
    { id: 'tu13', str: '25' },
    { id: 'tu14', str: '26' },
    { id: 'tu15', str: '27' },
    { id: 'tu16', str: '28' },
    { id: 'td1', str: '48' },
    { id: 'td2', str: '47' },
    { id: 'td3', str: '46' },
    { id: 'td4', str: '45' },
    { id: 'td5', str: '44' },
    { id: 'td6', str: '43' },
    { id: 'td7', str: '42' },
    { id: 'td8', str: '41' },
    { id: 'td9', str: '31' },
    { id: 'td10', str: '32' },
    { id: 'td11', str: '33' },
    { id: 'td12', str: '34' },
    { id: 'td13', str: '35' },
    { id: 'td14', str: '36' },
    { id: 'td15', str: '37' },
    { id: 'td16', str: '38' },
  ];

  const Universal = [
    { id: 'tu1', str: '1' },
    { id: 'tu2', str: '2' },
    { id: 'tu3', str: '3' },
    { id: 'tu4', str: '4' },
    { id: 'tu5', str: '5' },
    { id: 'tu6', str: '6' },
    { id: 'tu7', str: '7' },
    { id: 'tu8', str: '8' },
    { id: 'tu9', str: '9' },
    { id: 'tu10', str: '10' },
    { id: 'tu11', str: '11' },
    { id: 'tu12', str: '12' },
    { id: 'tu13', str: '13' },
    { id: 'tu14', str: '14' },
    { id: 'tu15', str: '15' },
    { id: 'tu16', str: '16' },
    { id: 'td1', str: '32' },
    { id: 'td2', str: '31' },
    { id: 'td3', str: '30' },
    { id: 'td4', str: '29' },
    { id: 'td5', str: '28' },
    { id: 'td6', str: '27' },
    { id: 'td7', str: '26' },
    { id: 'td8', str: '25' },
    { id: 'td9', str: '24' },
    { id: 'td10', str: '23' },
    { id: 'td11', str: '22' },
    { id: 'td12', str: '21' },
    { id: 'td13', str: '20' },
    { id: 'td14', str: '19' },
    { id: 'td15', str: '18' },
    { id: 'td16', str: '17' },
  ];

  const Palmer = [
    { id: 'tu1', str: '┌8' },
    { id: 'tu2', str: '┌7' },
    { id: 'tu3', str: '┌6' },
    { id: 'tu4', str: '┌5' },
    { id: 'tu5', str: '┌4' },
    { id: 'tu6', str: '┌3' },
    { id: 'tu7', str: '┌2' },
    { id: 'tu8', str: '┌1' },
    { id: 'tu9', str: '1┐' },
    { id: 'tu10', str: '2┐' },
    { id: 'tu11', str: '3┐' },
    { id: 'tu12', str: '4┐' },
    { id: 'tu13', str: '5┐' },
    { id: 'tu14', str: '6┐' },
    { id: 'tu15', str: '7┐' },
    { id: 'tu16', str: '8┐' },
    { id: 'td1', str: '└8' },
    { id: 'td2', str: '└7' },
    { id: 'td3', str: '└6' },
    { id: 'td4', str: '└5' },
    { id: 'td5', str: '└4' },
    { id: 'td6', str: '└3' },
    { id: 'td7', str: '└2' },
    { id: 'td8', str: '└1' },
    { id: 'td9', str: '1┘' },
    { id: 'td10', str: '2┘' },
    { id: 'td11', str: '3┘' },
    { id: 'td12', str: '4┘' },
    { id: 'td13', str: '5┘' },
    { id: 'td14', str: '6┘' },
    { id: 'td15', str: '7┘' },
    { id: 'td16', str: '8┘' },
  ];

  const PalmerDigital = [
    { id: 'tu1', str: 'UR8' },
    { id: 'tu2', str: 'UR7' },
    { id: 'tu3', str: 'UR6' },
    { id: 'tu4', str: 'UR5' },
    { id: 'tu5', str: 'UR4' },
    { id: 'tu6', str: 'UR3' },
    { id: 'tu7', str: 'UR2' },
    { id: 'tu8', str: 'UR1' },
    { id: 'tu9', str: 'UL1' },
    { id: 'tu10', str: 'UL2' },
    { id: 'tu11', str: 'UL3' },
    { id: 'tu12', str: 'UL4' },
    { id: 'tu13', str: 'UL5' },
    { id: 'tu14', str: 'UL6' },
    { id: 'tu15', str: 'UL7' },
    { id: 'tu16', str: 'UL8' },
    { id: 'td1', str: 'LR8' },
    { id: 'td2', str: 'LR7' },
    { id: 'td3', str: 'LR6' },
    { id: 'td4', str: 'LR5' },
    { id: 'td5', str: 'LR4' },
    { id: 'td6', str: 'LR3' },
    { id: 'td7', str: 'LR2' },
    { id: 'td8', str: 'LR1' },
    { id: 'td9', str: 'LL1' },
    { id: 'td10', str: 'LL2' },
    { id: 'td11', str: 'LL3' },
    { id: 'td12', str: 'LL4' },
    { id: 'td13', str: 'LL5' },
    { id: 'td14', str: 'LL6' },
    { id: 'td15', str: 'LL7' },
    { id: 'td16', str: 'LL8' },
  ];

  const Primary = [
    { id: 'tu1', str: '' },
    { id: 'tu2', str: '' },
    { id: 'tu3', str: '' },
    { id: 'tu4', str: 'A' },
    { id: 'tu5', str: 'B' },
    { id: 'tu6', str: 'C' },
    { id: 'tu7', str: 'D' },
    { id: 'tu8', str: 'E' },
    { id: 'tu9', str: 'F' },
    { id: 'tu10', str: 'G' },
    { id: 'tu11', str: 'H' },
    { id: 'tu12', str: 'I' },
    { id: 'tu13', str: 'J' },
    { id: 'tu14', str: '' },
    { id: 'tu15', str: '' },
    { id: 'tu16', str: '' },
    { id: 'td1', str: '' },
    { id: 'td2', str: '' },
    { id: 'td3', str: '' },
    { id: 'td4', str: 'T' },
    { id: 'td5', str: 'S' },
    { id: 'td6', str: 'R' },
    { id: 'td7', str: 'Q' },
    { id: 'td8', str: 'P' },
    { id: 'td9', str: 'O' },
    { id: 'td10', str: 'N' },
    { id: 'td11', str: 'M' },
    { id: 'td12', str: 'L' },
    { id: 'td13', str: 'K' },
    { id: 'td14', str: '' },
    { id: 'td15', str: '' },
    { id: 'td16', str: '' },
  ];

  const toothSystem = { FDI, Universal, Palmer, PalmerDigital, Primary };
  let toothString = [];

  const GetToothNumber = (heronNum, number_sys) => {
    if (number_sys != null) {
      toothString = toothSystem[number_sys];
    } else {
      toothString = toothSystem['FDI'];
    }

    if (heronNum.includes(',')) {
      const arr = heronNum.split(',');
      let str = '';
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < toothString.length; j++) {
          if (toothString[j].id === arr[i]) {
            str += toothString[j].str;
            if (i < arr.length - 2) str += ', ';
            break;
          }
        }
      }
      return str;
    } else {
      for (let i = 0; i < toothString.length; i++) {
        if (toothString[i].id === heronNum) return toothString[i].str;
      }
      return '';
    }
  };

  return (
    <CaseDetailTeethBox>
      {content.restorations && (
        <>
          {content.restorations.map((restoration, key) => (
            <div key={key} className={'caseDetailTeeth'}>
              <div className={'caseDetailTeethName'}>{restoration.displayName}</div>
              <div className={'caseDetailTeethNo'}>
                {GetToothNumber(restoration.tooth_no, restoration.number_sys)}
              </div>
              <div className={'caseDetailTeethShadeMaterial'}>
                {t('Shade')}: {restoration.shade} <span>&nbsp;&nbsp;</span>{' '}
                {t('Material')}: {restoration.material.name}
              </div>
            </div>
          ))}
        </>
      )}
    </CaseDetailTeethBox>
  );
};

export default CaseDetailTeeth;
