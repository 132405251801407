import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CaseList from '../../components/cases/CaseList';
import { listCases, setFilters } from '../../modules/cases';
import { useRefreshOnAuthError, useAutoRefreshEvent } from '../../lib/common';

const CaseListContainer = ({ history, match, location }) => {
  const dispatch = useDispatch();
  const {
    userChecking,
    cases,
    error,
    loading,
    filterChecking,
    // userFilters,
    filters,
    user,
    userSettings,
    del_disabled_all,
    page,
  } = useSelector(({ cases, loading, user }) => ({
    userChecking: loading['user/CHECK'],
    cases: cases.cases,
    error: cases.error,
    loading: loading['cases/LIST_CASES'],
    filterChecking: loading['cases/USER_FILTER'],
    // userFilters: cases.userFilters,
    filters: cases.filters,
    user: user.user,
    userSettings: user.settings,
    del_disabled_all: cases.del_disabled_all,
    page: cases.page,
  }));
  const historyRef = useRef();
  const [popCount, setPopCount] = useState(0);
  const prevLocRef = useRef({
    pathname: location.pathname,
    search: location.search,
    state: location.state,
  });

  const caseNo = match.params.caseNo;
  const _case = caseNo ? (caseNo.includes('-') ? { no: caseNo } : { id: caseNo }) : null;

  // history listener callback
  const onHistoryChanged = useCallback((location, action) => {
    // back or forward
    if (action === 'POP') {
      setPopCount((count) => count + 1);
    } else {
      prevLocRef.current = {
        pathname: location.pathname,
        search: location.search,
        state: location.state,
      };
    }
  }, []);

  /* history listener */
  useEffect(() => {
    historyRef.current = history.listen(onHistoryChanged);
    return () => {
      // unbind listener
      historyRef.current();
    };
  }, []);

  var selectedCaseID = '';

  const event = useAutoRefreshEvent();

  useEffect(() => {
    if (event) {
      const msg = JSON.parse(event);
      if (!window.actionIDs.has(msg.action_id)) {
        if (
          (msg.id === 'posts_deleted' || msg.id === 'post_modified') &&
          0 < cases.filter((c) => msg.post_ids.includes(c.id)).length
        ) {
          dispatch(listCases({ filters, page: page.number }));
        } else if (msg.id === 'post_published' /* || msg.id === 'post_uploaded'*/) {
          if (page.number === 1) dispatch(listCases({ filters }));
        }
      }
    }
  }, [event]);

  // get filters from local storage when mount
  useEffect(() => {
    if (!filters) {
      try {
        var caseFilters = JSON.parse(localStorage.getItem('caseFilters'));
        if (caseFilters) {
          if (caseFilters.createDateFrom !== null)
            caseFilters.createDateFrom = new Date(caseFilters.createDateFrom);
          if (caseFilters.createDateTo !== null)
            caseFilters.createDateTo = new Date(caseFilters.createDateTo);
          console.log(caseFilters);
          dispatch(setFilters(caseFilters));
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      // pathname is /cases or /cases/:caseNo
      if (location.pathname.includes('/cases')) history.replace(location.pathname, { filters });
    }
  }, []);

  // get case list when user checking is done and filters changed
  useEffect(() => {
    if (!userChecking) {
      if (filters.createDateFrom !== null) filters.createDateFrom.setHours(0, 0, 0, 0);

      if (filters.createDateTo !== null) filters.createDateTo.setHours(0, 0, 0, 0);

      dispatch(listCases({ filters }));
    }
  }, [dispatch, userChecking, filters]);

  useRefreshOnAuthError();

  return (
    <CaseList
      loading={loading}
      error={error}
      cases={cases}
      filters={filters}
      filterChecking={filterChecking}
      // userFilter={userFilters}
      page={page}
      del_disabled_all={del_disabled_all}
      selectedID={selectedCaseID}
      _case={_case}
      popCount={popCount}
      prevLocRef={prevLocRef}
    />
  );
};

export default withRouter(CaseListContainer);
