import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import HButton from '../common/HButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SettingItem, { SettingItemText } from './SettingItem';
import SearchInput from './styled/SearchInput';
import Spinner from '../common/Spinner';
import { getLocaleDate } from '../common/Util';

const MainBlock = styled.div`
  /* user-select: none; */
  .main {
    width: 100%;
    height: 100%;
    min-height: 798px;
    background: #eeeff0;
  }

  .mainColl {
    width: 100%;
    height: 100%;
    min-height: 798px;
    background: #eeeff0;
  }

  .loading {
    margin-top: 40px;
    text-align: center;
  }

  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  .top {
    display: flex;
  }

  .title {
    width: 135px;
    height: 32px;
    margin: 64px 0 0 48px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }

  .searchInput {
    display: flex;
    margin-top: 60px;
    /* width: 632px; */
    flex: 1 1 0;
    justify-content: center;
  }

  .viewTitle {
    width: 176x;
    height: 25px;
    margin-top: 56px;
    margin-bottom: 17px;
    margin-left: 48px;
    font-size: 20px;
    color: #969ba1;
  }

  .notificationTitle {
    width: 176x;
    height: 25px;
    margin: 46px 0 17px 48px;
    font-size: 20px;
    color: #969ba1;
  }

  .migrationInfo {
    width: 176x;
    height: 15px;
    margin: 3px 48px 0 0;
    font-size: 14px;
    color: #59616a;
    text-align: right;
  }
`;

// const Settings = ({ settings, loading, error, onToggle, onSaveSettings }) => {
const Settings = ({
  settings,
  migrationInfo,
  userChecking,
  loading,
  onSaveSettings,
  odm,
}) => {
  const { t, i18n } = useTranslation();
  const { leftpanel, app } = useSelector(({ leftpanel, app }) => ({
    leftpanel: leftpanel,
    app: app.app,
  }));
  const settingRef = useRef(null);
  const settingRef2 = useRef(null);
  const settingRef3 = useRef(null);
  const { data_location, ...settings_except_data_loc } = settings;
  const [checkedItems, setCheckedItems] = useState(settings_except_data_loc);
  const HCloud =
    odm.app_name === 'HeronCloud' || odm.app_name === '3DiscCloud' ? true : false;
  const [migrationText, setMigrationText] = useState('');

  const onToggle = (name) => {
    checkedItems[name] = !checkedItems[name];
    setCheckedItems(checkedItems);
  };

  const onSelect = (item) => {
    checkedItems['_3d_projection'] = item;
    setCheckedItems(checkedItems);
  };

  const SettingItems = React.forwardRef((props, ref) => (
    <div ref={ref}>
      {/* <SettingItem
        name="show_task_filter"
        text={t("Show 'Cases/Drafts' filter above case list")}
        checked={settings.show_task_filter}
        onToggle={onToggle}
      /> */}
      <SettingItem
        name="use_auto_refresh"
        text={t('Use auto refresh')}
        checked={settings.use_auto_refresh}
        onToggle={onToggle}
      />
    </div>
  ));

  const SettingItems2 = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <SettingItem
        name="post_publish_email"
        text={t('Receive notification email when new case is submitted')}
        checked={settings.post_publish_email}
        onToggle={onToggle}
      />
      <SettingItem
        name="post_edit_email"
        text={t('Receive notification email when case is modified')}
        checked={settings.post_edit_email}
        onToggle={onToggle}
      />
      <SettingItem
        name="comment_add_email"
        text={t('Receive notification email when comment is added')}
        checked={settings.comment_add_email}
        onToggle={onToggle}
      />
      <SettingItem
        name="comment_edit_email"
        text={t('Receive notification email when comment is modified')}
        checked={settings.comment_edit_email}
        onToggle={onToggle}
      />
    </div>
  ));

  const SettingItems4 = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <SettingItem
        name="3DProjection"
        text={t('3D Projection')}
        checked={settings.comment_edit_email}
        onToggle={onToggle}
        isDropdownList={true}
        onSelect={onSelect}
        defaultValue={settings._3d_projection}
      />
    </div>
  ));

  useEffect(() => {
    if (!loading && migrationInfo) {
      if (settings.data_loc_set) {
        setMigrationText(
          ' ' +
          t('Data migration completed') +
          ` (${getLocaleDate(settings.data_loc_set_date, i18n.language)})`,
        );
      } else if (migrationInfo.cases_mv_percent) {
        setMigrationText(
          ' ' +
          t('Data migration is in progress') +
          ` (${migrationInfo.cases_mv_percent} %)`,
        );
      }
    }
  }, [loading]);

  const SettingItems3 = React.forwardRef((props, ref) => (
    <div ref={ref}>
      <SettingItemText
        name="data_location"
        text={t('Data Region')}
        value={settings.data_location}
      // onSelect={onSelect}
      />
    </div>
  ));

  // if (error) {
  //   return (
  //     <div>
  //       <p>{error.message}</p>
  //     </div>
  //   );
  // }

  return (
    <MainBlock>
      <div
        className={leftpanel.collapsed || leftpanel.collapsedAuto ? 'mainColl' : 'main'}
      >
        <div className="top">
          <div className="title">{t('Settings')}</div>
          {/* <div className="searchInput">
            <SearchInput
              placeholder={t("Filter settings by search")}
              settingRef={settingRef}
              settingRef2={settingRef2}
            />
          </div> */}
          <div
            style={{
              marginTop: '60px',
              marginRight: '40px',
              marginLeft: 'auto',
            }}
          >
            <HButton
              category="Primary"
              size="Medium"
              text={t('Save Settings').toUpperCase()}
              width={i18n.language === 'fr' ? '150px' : '136px'}
              // onClick={(e) => onSaveSettings(settings)}
              onClick={(e) => onSaveSettings(checkedItems)}
            />
          </div>
        </div>

        {/* View */}
        <div className="viewTitle">{t('View')}</div>
        {userChecking && (
          <div className={'loading'}>
            <Spinner />
          </div>
        )}
        {!userChecking && settings && <SettingItems ref={settingRef}></SettingItems>}

        {/* 3D */}
        <div className="notificationTitle">{'3D'}</div>
        {userChecking && (
          <div className={'loading'}>
            <Spinner />
          </div>
        )}
        {!userChecking && settings && <SettingItems4 ref={settingRef}></SettingItems4>}

        {/* Notification */}
        <div className="notificationTitle">{t('Notification')}</div>
        {userChecking && (
          <div className={'loading'}>
            <Spinner />
          </div>
        )}
        {!userChecking && settings && <SettingItems2 ref={settingRef2}></SettingItems2>}

        {HCloud && !userChecking && settings && (
          <>
            <div className="notificationTitle">{t('Data')}</div>
            <SettingItems3 ref={settingRef3}></SettingItems3>
            <div className="migrationInfo">
              {settings.data_location === 'EU' &&
                !loading &&
                migrationInfo &&
                migrationText}
            </div>
          </>
        )}
      </div>
    </MainBlock>
  );
};

export default Settings;
