import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeField, initializeForm } from '../../modules/auth';
import { validate } from '../../lib/api/auth';
import PasswordAssistanceForm from '../../components/auth/PasswordAssistanceForm';
import { withRouter } from 'react-router-dom';
import {
  resetPassword,
  resetPasswordConfirm,
  checkResetPasswordToken,
  changePassword,
} from '../../modules/auth';
import { PasswordAssistanceForm2 } from '../../components/auth/PasswordAssistanceForm2';
import { useTranslation } from 'react-i18next';
//import Notification from '../../components/auth/styled/Notification';
import ChangePassword from '../../components/auth/ChangePassword';
import { changePasswordSuccess, setchangePasswordSuccess } from '../../modules/auth';

const PasswordAssistanceContainer = ({ match, history }) => {
  const { t } = useTranslation();
  const { token } = match.params;
  const [error, setError] = useState(null);
  const [tokenValid, setTokenValid] = useState(false);
  const dispatch = useDispatch();
  const { form, auth, authError, errorMessage, user } = useSelector(
    ({ auth, user, app }) => ({
      form: auth.signup,
      auth: auth.auth,
      authError: auth.authError,
      errorMessage: auth.error,
      user: user.user,
    }),
  );

  const [messageVisible, setMessageVisible] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageType, setMessageType] = useState('ok');
  const [messageContent, setMessageContent] = useState('');

  const isLoadingRef = useRef(false);

  const onChange = (e) => {
    const { value, name } = e.target;
    dispatch(
      changeField({
        form: 'signup',
        key: name,
        value,
      }),
    );
  };

  const onFocusOut = (e) => {
    const { value, name } = e.target;
    validate_password(value, 'password', name);
  };

  const validate_password = async (value, formName, field) => {
    if (field === 'old_password') return;
    const formData = new FormData();
    formData.append('old_password', '');
    formData.append('new_password', '');
    formData.append('new_password_confirm', '');
    if (field === 'password') {
      field = 'new_password';
    }
    if (field === 'passwordConfirm') {
      field = 'new_password_confirm';
      formData.set('new_password', form.password);
    }
    if (field) formData.set(field, value);

    try {
      let res = await validate({ formData, form: formName, field });

      if (res.statusText !== 'OK') {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const node = document.getElementsByName(field + 'Validate')[0];
      if (JSON.stringify(res.data) === '{}') {
        if (node.childNodes.length > 0) node.removeChild(node.childNodes[0]);
        node.classList.remove('validateCss');
      } else {
        if (res.data.error_message) {
          let text = '';
          res.data.error_message.forEach((el) => {
            text += '<div key={index}>' + t(el) + '</div>';
          });

          let small = document.createElement('small');
          // small.setAttribute('class', 'message');
          small.innerHTML = "<font color='red'>" + `${text}` + '</font>';

          if (node.childNodes.length === 0) node.appendChild(small);
          else node.replaceChild(small, node.childNodes[0]);
          node.classList.add('validateCss');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (user || token) {
      //Set passwoard
      const { old_password, password, passwordConfirm } = form;
      if (old_password) formData.append('old_password', old_password);
      if (password) formData.append('new_password', password);
      if (passwordConfirm) formData.append('new_password_confirm', passwordConfirm);

      if (password === passwordConfirm) {
        if (user) dispatch(changePassword({ formData }));
        else {
          if (isLoadingRef.current) {
            return;
          }
          isLoadingRef.current = true;

          await dispatch(resetPasswordConfirm({ token, password }));

          isLoadingRef.current = false;
        }
      } else {
        setError([t('The two password fields didn’t match')]);
      }
    } else if (!token) {
      // prvvent duplicate api calls
      if (isLoadingRef.current) {
        return;
      }
      isLoadingRef.current = true;

      // api call
      const { username } = form;
      await dispatch(resetPassword({ email: username }));

      isLoadingRef.current = false;
    }
  };

  const onConfirmBoxOk = () => {
    setMessageVisible(false);
    setchangePasswordSuccess(false);
    history.push('/profile');
  };

  const onCancel = () => {
    setMessageVisible(false);
    setchangePasswordSuccess(false);
    history.push('/profile');
  };

  useEffect(() => {
    if (changePasswordSuccess === true) {
      setMessageContent(t('Password has been changed successfully'));
      setMessageVisible(true);
      setMessageTitle(t('Info'));
    }
  }, [changePasswordSuccess]);

  useEffect(() => {
    dispatch(initializeForm('signup'));
    if (token) {
      dispatch(checkResetPasswordToken({ token }));
    }
    return () => {
      dispatch(initializeForm('authError'));
    };
  }, [dispatch, token]);

  useEffect(() => {
    try {
      if (errorMessage) {
        if (errorMessage.response.data.old_password) {
          if (
            errorMessage.response.data.old_password[0] === 'Wrong password.'
          ) {
            setError(t('The old password is incorrect'));
          } else {
            setError(errorMessage.response.data.old_password);
          }

          //init
          dispatch(
            changeField({
              form: 'signup',
              key: 'old_password',
              value: '',
            }),
          );
          dispatch(
            changeField({
              form: 'signup',
              key: 'password',
              value: '',
            }),
          );
          dispatch(
            changeField({
              form: 'signup',
              key: 'passwordConfirm',
              value: '',
            }),
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (authError) {
      if (tokenValid) {
        setError(authError.response.data['password']);
      } else {
        setError(t(authError.response.data['email']));
      }
      return;
    } else {
      setError(null);
    }
    if (auth && !user) {
      setError(null);
      if (!token) {
        history.push('/passwordLink');
      } else {
        //got token
        if (!tokenValid) {
          setTokenValid(true);
          dispatch(initializeForm('signup'));
        }
      }
    }
    // return () => {
    //   dispatch(initializeForm('authError'));
    // };
  }, [auth, user, authError, tokenValid, token]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    dispatch(initializeForm('authError'));
    history.push('/');
  };

  return !user ? (
    !token ? (
      <PasswordAssistanceForm
        form={form}
        onChange={onChange}
        onSubmit={onSubmit}
        error={error}
        onBackButtonEvent={onBackButtonEvent}
        isLoadingRef={isLoadingRef}
      />
    ) : (
      tokenValid && (
        <PasswordAssistanceForm2
          form={form}
          onChange={onChange}
          onSubmit={onSubmit}
          error={error}
          onFocusOut={onFocusOut}
          isLoadingRef={isLoadingRef}
        />
      )
    )
  ) : (
    <ChangePassword
      form={form}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      messageVisible={messageVisible}
      messageTitle={messageTitle}
      messageType={messageType}
      messageContent={messageContent}
      onConfirmBoxOk={onConfirmBoxOk}
      onCancel={onCancel}
      onFocusOut={onFocusOut}
    ></ChangePassword>
  );
};
export default withRouter(React.memo(PasswordAssistanceContainer));
