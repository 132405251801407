import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import SignupPage from './pages/SignupPage';
import CaseListPage from './pages/CaseListPage';
// import CasePage from './pages/CasePage';
import GroupsPage from './pages/GroupsPage';
import ConnectionsPage from './pages/ConnectionsPage';
import SettingsPage from './pages/SettingsPage';
import SocialLogin from './containers/auth/SocialLogin';
import { useSelector } from 'react-redux';
import PasswordAssistancePage from './pages/PasswordAssistancePage';
import Notification from './components/auth/styled/Notification';
import EditProfileContainer from './containers/auth/EditProfileContainer';
import SocialSignupPage from './pages/SocialSignupPage';
// import ChangePassword from './components/auth/ChangePassword';
import { useTranslation } from 'react-i18next';
// import DeviceRegistrationPage from './pages/DeviceRegistrationPage';
// import Registration from './components/registration/Registration';
import RegInfo from './components/registration/RegInfo';
// import DeviceAdminList from './components/registration/DeviceAdminList';
import SocialLoginComplete from './components/auth/SocialLoginComplete';
import LabDeviceManagementPage from './pages/LabDeviceManagementPage';
// import UsersPage from './pages/UsersPage';
// import CasePatCommPage from './pages/CasePatCommPage';
// dynamic import
// /adm pages
const DeviceAdminList = React.lazy(() =>
  import('./components/registration/DeviceAdminList'),
);
const Registration = React.lazy(() => import('./components/registration/Registration'));
const DeviceRegistrationPage = React.lazy(() =>
  import('./pages/DeviceRegistrationPage'),
);
const UsersPage = React.lazy(() => import('./pages/UsersPage'));
// external pages
const CasePage = React.lazy(() => import('./pages/CasePage'));
const CasePatCommPage = React.lazy(() => import('./pages/CasePatCommPage'));

const cookies = new Cookies();

const NotFound = () => {
  return (
    <div>
      <h1>404 not found</h1>
    </div>
  );
};

function PrivateRoute({ component: Component, authed, docsHost = null, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const { pathname, search } = props.location;
        if (authed === true) {
          if (docsHost === null) {
            if (props.location.pathname === '/') {
              return (
                <Redirect
                  to={{ pathname: '/dashboard', state: { from: props.location } }}
                />
              );
            } else {
              return <Component {...props} />;
            }
          } else {
            window.location.href = `https://${docsHost}${pathname}${search}`;
            return null;
          }
        } else {
          return (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          );
        }
      }}
    />
  );
}

function PrivateAdminRoute({ component: Component, authed, csAuthed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        csAuthed === true ? (
          <Component {...props} />
        ) : (
          // ) : authed === true ? (
          //   //Can't move to adm
          //   // <Redirect to={{ pathname: '/login' }} />
          <Redirect to={{ pathname: '/adm', state: { from: props.location } }} />
        )
      }
    />
  );
}

function App() {
  const { t } = useTranslation();
  // const { authed, csAuthed } = useSelector(({ user, cs }) => ({
  const { authed, csAuthed, docsHost, cursorLoading } = useSelector(
    ({ user, cursorLoading }) => ({
      authed: !!(user && user.user),
      // csAuthed: cs.auth ? true : false,
      csAuthed: !!(
        user &&
        user.user &&
        (user.user.is_device_manager ||
          user.user.is_sales_manager ||
          user.user.is_staff ||
          user.user.is_device_user_viewer)
      ),
      docsHost: cookies.get('original_host'),
      cursorLoading: cursorLoading['caseDownLoading'],
    }),
  );

  return (
    <div
      style={{
        cursor: cursorLoading ? 'wait' : 'default',
        height: '100%',
      }}
    >
      <Switch>
        {/* root */}
        {/* <Route path="/" exact>
        <Redirect to="/dashboard" />
      </Route> */}
        <PrivateRoute path="/" exact authed={authed} docsHost={docsHost} />
        {/* DeviceRegistration */}
        <PrivateAdminRoute
          component={DeviceAdminList}
          path="/adm/devices"
          // authed={authed}
          csAuthed={csAuthed}
        />
        <PrivateAdminRoute
          component={Registration}
          path="/adm/factoryRegistration"
          // authed={authed}
          csAuthed={csAuthed}
        />
        <PrivateAdminRoute
          component={RegInfo}
          path="/adm/registration/info/:deviceId"
          csAuthed={csAuthed}
        />
        <PrivateAdminRoute
          component={DeviceRegistrationPage}
          path={['/adm/registration', '/adm/registration/tickets']}
          // authed={authed}
          csAuthed={csAuthed}
        />
        {/* auth */}
        <Route component={SocialLogin} path="/login/social/response" />
        <Route
          component={SocialLoginComplete}
          path={['/social-login/complete', '/login-social/complete']}
        />
        <Route component={SocialSignupPage} path="/signup/social" />
        <Route component={LoginPage} path={['/login', '/adm']} exact />
        <Route component={SignupPage} path="/signup" />
        <Route
          path={'/signupEmail'}
          render={() => (
            <Notification
              title={t(
                'An email has been sent with instructions about your account activation',
              )}
              content={t(
                'Please check your inbox. If you haven’t received an email within the next 2 minutes please check your spam folder',
              )}
              height="64px"
              width="500px"
              homeLink={true}
            />
          )}
        />
        <Route
          path={'/activate/:uidb64/:token'}
          render={({ match }) => (
            <Notification match={match} height="64px" width="500px" />
          )}
        />
        <Route
          path={'/resend/:uid'}
          render={({ match }) => (
            <Notification match={match} height="64px" width="500px" />
          )}
        />

        <Route
          component={PasswordAssistancePage}
          path={['/password-reset/:token', '/password_reset/', '/changePassword']}
        />
        <Route
          path={'/passwordLink'}
          render={() => (
            <Notification
              title={t('We have sent you an email with a password reset link')}
              content={t(
                'Please check your inbox. If you haven’t received an email within the next 2 minutes please check your spam folder',
              )}
              // height="64px"
              homeLink={true}
            />
          )}
        />
        <Route
          path={'/createNewPassword'}
          render={() => (
            <Notification
              title={t('Success')}
              content={t('Your password has been changed')}
              homeLink={true}
            />
          )}
        />
        <Route
          path={'/tokenExpired'}
          render={() => (
            <Notification
              title={t('This token has expired')}
              content={t('Please go back to home')}
              homeLink={true}
            />
          )}
        />
        {/* dashboard */}
        <PrivateRoute component={DashboardPage} path="/dashboard" authed={authed} />
        {/* cases */}
        <PrivateRoute
          component={CaseListPage}
          path={['/cases/:caseNo', '/cases']}
          authed={authed}
        />
        {/* case detail*/}
        <PrivateRoute component={CasePage} path="/case/:caseNo" authed={authed} />
        {/* group */}
        <PrivateRoute
          component={GroupsPage}
          path={['/sharegroup/accept/:sguidb64/:token', '/groups']}
          authed={authed}
        />
        {/* connections */}
        <PrivateRoute
          component={ConnectionsPage}
          path={['/connections/accept/:cidb64/:token', '/connections']}
          authed={authed}
        />
        {/* devices */}
        <Route
          path={'/devices/confirm-email/:rdidb64/:token'}
          render={({ match }) => (
            <Notification match={match} height="64px" width="500px" />
          )}
        />
        {/* user */}
        <PrivateRoute component={SettingsPage} path="/settings" authed={authed} />
        <PrivateRoute
          component={EditProfileContainer}
          path="/profile"
          authed={authed}
        />
        <PrivateRoute
          component={LabDeviceManagementPage}
          path="/devices"
          authed={authed}
        />
        {/* patient communication */}
        <Route component={CasePatCommPage} path="/share/case" />
        {/* adm */}
        <PrivateAdminRoute
          component={UsersPage}
          path={[
            '/adm/user-locations',
            '/adm/users/:uid',
            '/adm/users',
            '/adm/cases',
            '/adm/group/:gid',
            '/adm/connections',
            '/adm/labs',
            '/adm/dashboard',
            '/adm/lab/:id',
            '/adm/lab',
          ]}
          csAuthed={csAuthed}
        />
        {/* common */}
        {/* <Route path={'*'} component={NotFound} /> */}
        <PrivateRoute path={'*'} component={NotFound} authed={authed} />

        {/* HelpDesk */}
      </Switch>
    </div>
  );
}

export default App;
