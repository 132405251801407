const ODM = {
  HCLOUD: {
    company_name: '3DISC',
    app_name: 'HeronCloud',
    copyright_year: '2019-2023',
    hclinic_name: 'HeronClinic',
    main_color: '#ff6d22',
    main_color_hover: '#e5621f',
    main_color_focus: '#fff2eb',
  },
  GRO3X: {
    company_name: 'Gro3X',
    app_name: 'Gro3XCloud',
    copyright_year: '2021-2023',
    hclinic_name: 'Gro3XClinic',
    main_color: '#df8800',
    main_color_hover: '#cb7d03',
    main_color_focus: '#fcf3e5',
  },
  IMESICORE: {
    company_name: 'imes-icore',
    app_name: 'one cloud',
    copyright_year: '2021-2023',
    hclinic_name: 'one-clinic',
    main_color: '#af212a',
    main_color_hover: '#9c1d25',
    main_color_focus: '#f7e9ea',
  },
  WHIPMIX: {
    company_name: 'Whip Mix',
    app_name: 'Whip Mix Cloud',
    copyright_year: '2021-2023',
    hclinic_name: 'WhipMixClinic',
    main_color: '#0072C6',
    main_color_hover: '#0369b5',
    main_color_focus: '#e5f1f9',
  },
  EZSCAN: {
    company_name: 'vatech',
    app_name: 'EzScan Cloud',
    hclinic_name: 'EzScan-i',
    copyright_year: '2021-2024',
    main_color: '#af212a',
    main_color_hover: '#9c1d25',
    main_color_focus: '#f7e9ea',
  },
};
const SKIN = {
  '3disccloud': {
    app_name: '3DiscCloud',
    copyright_year: '2019-2024',
    hclinic_name: '3DiscClinic',
    main_color: '#ff6d22',
    main_color_hover: '#e5621f',
    main_color_focus: '#fff2eb',
  },
};

const odm_domain_name = {
  gro3x: 'GRO3X',
  'imes-icore': 'IMESICORE',
  whipmix: 'WHIPMIX',
  ezscan: 'EZSCAN',
};
const skin_domain_name = {
  '3disccloud': '3disccloud',
  // localhost: '3disccloud',
  quantorshare: '3disccloud',
};

const get_odm_name = () => {
  let odm_name = 'HCLOUD'; // default is HCLOUD
  const http_host = window.location.hostname;
  if (http_host) {
    for (const key in odm_domain_name) {
      if (http_host.includes(key)) {
        odm_name = odm_domain_name[key];
        break;
      }
    }
  }
  return odm_name;
};

const get_odm_attr = (odm_name, attr_name) => {
  return ODM[odm_name][attr_name];
};

const get_skin_name = () => {
  let skin_name = null;
  const http_host = window.location.hostname;
  if (http_host) {
    for (const key in skin_domain_name) {
      if (http_host.includes(key)) {
        skin_name = skin_domain_name[key];
        break;
      }
    }
  }
  return skin_name;
};

const get_skin_attr = (skin_name, attr_name) => {
  return SKIN[skin_name][attr_name];
};

export const odm_name = get_odm_name();
// export const odm_name = 'EZSCAN';
const is_default = odm_name === 'HCLOUD';
export const skin_name = is_default ? get_skin_name() : null;
const is_skin = skin_name !== null;

export const getODM = () => {
  return {
    name: odm_name,
    company_name: get_odm_attr(odm_name, 'company_name'),
    app_name: !is_skin
      ? get_odm_attr(odm_name, 'app_name')
      : get_skin_attr(skin_name, 'app_name'),
    hclinic_name: !is_skin
      ? get_odm_attr(odm_name, 'hclinic_name')
      : get_skin_attr(skin_name, 'hclinic_name'),
    images: !is_default
      ? `images/odm/${odm_name}`
      : is_skin
      ? `images/skin/${skin_name}`
      : 'images',
    css: !is_default ? `css/${odm_name}` : 'css',
    copyright_year: !is_skin
      ? get_odm_attr(odm_name, 'copyright_year')
      : get_skin_attr(skin_name, 'copyright_year'),
    main_color: !is_skin
      ? get_odm_attr(odm_name, 'main_color')
      : get_skin_attr(skin_name, 'main_color'),
    main_color_hover: !is_skin
      ? get_odm_attr(odm_name, 'main_color_hover')
      : get_skin_attr(skin_name, 'main_color_hover'),
    main_color_focus: !is_skin
      ? get_odm_attr(odm_name, 'main_color_focus')
      : get_skin_attr(skin_name, 'main_color_focus'),
  };
};

export const theme = {
  main_color: !is_skin
    ? get_odm_attr(odm_name, 'main_color')
    : get_skin_attr(skin_name, 'main_color'),
  main_color_hover: !is_skin
    ? get_odm_attr(odm_name, 'main_color_hover')
    : get_skin_attr(skin_name, 'main_color_hover'),
  main_color_focus: !is_skin
    ? get_odm_attr(odm_name, 'main_color_focus')
    : get_skin_attr(skin_name, 'main_color_focus'),
  images: !is_default
    ? `images/odm/${odm_name}`
    : is_skin
    ? `images/skin/${skin_name}`
    : 'images',
};

// export const get_odm_info = (odm_name) => {
//   return ODM[odm_name];
// };

// document.title = getODM().app_name;
// const favi16 = document.getElementsByName('favicon16');
// const favi32 = document.getElementsByName('favicon32');
// favi16[0].href =
//   process.env.PUBLIC_URL + '/' + getODM().images + '/favicon_16.png';
// favi32[0].href =
//   process.env.PUBLIC_URL + '/' + getODM().images + '/favicon_32.png';
