import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import HButton from '../common/HButton';
import {
  changeField,
  createConnection,
  initializeForm,
} from '../../modules/connections';
import { useTranslation } from 'react-i18next';
import SVG from '../common/svg';
import ValidateDiv from '../../components/auth/styled/ValidateText';
import { validate } from '../../lib/api/auth';
import { getOtherLocation } from '../../lib/util';
import DropdownList from '../auth/styled/DropdownList';
import Card from './ConnectionLabCard';

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
`;

const Fullscreen = styled.div`
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${fadeOut};
    `}
`;

const ModalBlock = styled.div`
  width: 528px;
  /* height: 340px; */
  background: white;
  border-radius: 4px;
  border: solid 1px #eeeff0;

  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-name: ${slideDown};
  animation-fill-mode: forwards;

  ${(props) =>
    props.disappear &&
    css`
      animation-name: ${slideUp};
    `}
`;

const TitleBlock = styled.div`
  .titleBox {
    width: 528px;
    height: 56px;
    border-radius: 4px 4px 0 0;
    margin: 0 0 0 0;
    background: #eeeff0;
  }

  .titleText {
    width: 400px;
    height: 25px;
    margin: 15px 0 0 19px;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    float: left;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    margin: 16px 19px 0 0;
    float: right;
  }
`;

const InputBlock = styled.div`
  margin-top: 24px;
  margin-left: 20px;

  .email {
    width: 454px;
    height: 36px;
    margin: 0 0 0 0;
    padding-left: 16px;
    padding-right: 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }

  .email::-webkit-input-placeholder {
    color: #969ba1;
  }

  .email:focus::placeholder {
    color: transparent;
  }

  .email:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.main_color};
  }
  .email:disabled::placeholder {
    color: #c3c6c9;
  }

  input:disabled {
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: #f5f6f6;
  }

  .alias {
    width: 454px;
    height: 36px;
    margin: 8px 0 0 0;
    padding-left: 16px;
    padding-right: 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
  }

  .alias::-webkit-input-placeholder {
    color: #969ba1;
  }

  .alias:focus::placeholder {
    color: transparent;
  }

  .alias:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.main_color};
  }

  .alias:disabled::placeholder {
    color: #c3c6c9;
  }

  .message {
    width: 454px;
    height: ${(props) => (props.inst_type_guessed === 'Clinic' ? '156px' : '54px')};
    margin: 8px 0 0 0;
    padding: 10px 16px 10px 16px;
    outline: none;
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    color: #969ba1;
    background-color: transparent;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59616a;
    resize: none;
  }

  .message::-webkit-input-placeholder {
    color: #969ba1;
  }

  .message:focus::placeholder {
    color: transparent;
  }

  .message:focus {
    outline: none !important;
    border: 1px solid ${(props) => props.theme.main_color};
  }

  .message:disabled {
    border-radius: 4px;
    border: solid 1px #e7e9ea;
    background-color: #f5f6f6;
  }
  .message:disabled::placeholder {
    color: #c3c6c9;
  }
`;

const ButtonBlock = styled.div`
  margin: 24px 0 20px 0;
  height: 40px;
`;

const SelectButtonBlock = styled.div`
  display: flex;
  background: #e7e9ea;
  margin: 24px 20px;
  border-radius: 4px;
`;

const CreateConnection = ({
  user,
  connections,
  page1,
  lab,
  labNames,
  visible,
  title,
  form,
  onSubmit,
  onCancel,
  onSelect,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [animate, setAnimate] = useState(false);
  const [localVisible, setLocalVisible] = useState(visible);
  const [validEmail, setValidEmail] = useState(false);
  const [validMessage, setValidMessage] = useState(false);

  const { odm, app } = useSelector(({ app }) => ({
    odm: app.odm,
    app: app.app,
  }));

  useEffect(() => {
    if (localVisible && !visible) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 250);
      setValidEmail(false);
      setValidMessage(false);
    }
    setLocalVisible(visible);
  }, [localVisible, visible]);

  useEffect(() => {
    setValidEmail(false);
    setValidMessage(false);
  }, [page1]);

  const onPage1 = () => {
    dispatch(
      changeField({
        form: 'page',
        key: 'page1',
        value: true,
      }),
    );

    dispatch(initializeForm('changefield'));
    dispatch(initializeForm('lab'));
  };

  const onPage2 = () => {
    dispatch(
      changeField({
        form: 'page',
        key: 'page1',
        value: false,
      }),
    );

    dispatch(initializeForm('changefield'));
    dispatch(initializeForm('lab'));
  };

  const onButtonStatus = () => {
    if (page1 && validEmail && validMessage) {
      // If it's a page for entering an email, check email and message
      return false;
    } else if (!page1 && lab) {
      // If it's a page for selecting a Lab, check lab
      let result = false;
      connections.accepted.map((connection) => {
        if (lab && connection.email === lab.email) {
          result = true;
        }
      });
      return result;
    } else {
      return true;
    }
  };

  const onFormChanged = (e) => {
    const { value, name } = e.target;

    dispatch(
      changeField({
        form: 'changefield',
        key: name,
        value,
      }),
    );

    if (name === 'email') {
      // let re =
      //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (re.test(value)) setValidEmail(true);
      // else setValidEmail(false);
      validate_field(value, 'connection', name);
    }

    if (name === 'message') {
      if (value !== '') setValidMessage(true);
      else setValidMessage(false);
    }
  };

  // const onFocusOut = (e) => {
  //   const { value, name } = e.target;
  //   validate_field(value, 'connection', name);
  // };

  const validate_field = async (value, formName, field) => {
    const formData = new FormData();
    formData.append('email', '');
    if (field) formData.set(field, value);

    try {
      let res = await validate({ formData, form: formName, field });

      if (res.statusText !== 'OK') {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const node = document.getElementsByName(field + 'Validate')[0];
      if (JSON.stringify(res.data) === '{}') {
        setValidEmail(true);

        if (node.childNodes.length > 0) node.removeChild(node.childNodes[0]);
        node.classList.remove('validateCss');
      } else {
        if (res.data.error_message) {
          setValidEmail(false);

          let text = '';
          res.data.error_message.forEach((el) => {
            if (el === 'user not in the same region') {
              // const other_loc = app ? (app.server_loc === 'US' ? 'EU' : 'non-EU') : '';
              let msg = t(
                'This email account is located in a different region: you cannot connect to transfer data.',
              );
              // msg = msg.replace('EU', other_loc);
              text += '<div>' + msg + '</div>';
            } else text += '<div>' + t(el) + '</div>';
          });

          let small = document.createElement('small');
          small.innerHTML = "<font color='red'>" + `${text}` + '</font>';

          if (node.childNodes.length === 0) node.appendChild(small);
          else node.replaceChild(small, node.childNodes[0]);
          node.classList.add('validateCss');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!animate && !localVisible) return null;

  return (
    <Fullscreen disappear={!visible}>
      <ModalBlock disappear={!visible}>
        <form onSubmit={onSubmit}>
          <TitleBlock>
            <div className="titleBox">
              <div class="titleText">{title}</div>
              <div className={'closeIcon'} onClick={onCancel}>
                <SVG
                  name="close"
                  color="#878D93"
                  colorOver={odm.main_color_hover}
                  colorOut="#878D93"
                  colorDown={odm.main_color}
                />
              </div>
            </div>
          </TitleBlock>

          {user.inst_type_guessed === 'Clinic' && (
            <SelectButtonBlock>
              <HButton
                category="Secondary"
                size="Medium"
                text={t('With Your Lab').toUpperCase()}
                width="244px"
                margin="0"
                tab={true}
                unSelect={!page1}
                onClick={onPage1}
              />
              <HButton
                category="Secondary"
                size="Medium"
                text={t('Select a Partner').toUpperCase()}
                width="244px"
                margin="0"
                tab={true}
                unSelect={page1}
                onClick={onPage2}
              />
            </SelectButtonBlock>
          )}
          {page1 && (
            <>
              <InputBlock inst_type_guessed={user.inst_type_guessed}>
                <input
                  name="email"
                  type="email"
                  className="email"
                  placeholder={t('Email Address')}
                  value={form.email}
                  onChange={onFormChanged}
                  // onBlur={onFocusOut}
                />
                <ValidateDiv name="emailValidate" paddingLeft="15px"></ValidateDiv>
                <input
                  name="alias"
                  type="text"
                  className="alias"
                  placeholder={t('Alias')}
                  value={form.alias}
                  onChange={onFormChanged}
                />
                <textarea
                  name="message"
                  className="message"
                  placeholder={t('Message')}
                  value={form.message}
                  onChange={onFormChanged}
                />
              </InputBlock>
            </>
          )}
          {!page1 && (
            <div
              style={{
                width: '488px',
                minHeight: '278px',
                marginLeft: '20px',
                marginTop: '15px',
                // marginBottom: '14px',
              }}
            >
              <div style={{ height: '48px' }}>
                <DropdownList
                  name="Labs"
                  width={'488px'}
                  height="48px"
                  placeholder={t('Select a Partner')}
                  margin="0 0 0 0"
                  img_margin="12px 12px 12px 0"
                  search={true}
                  items={labNames}
                  onSelect={onSelect}
                  zIndex={2}
                  tabIndex={6}
                  required
                />
              </div>

              {lab && (
                <Card
                  companyName={lab.company_name}
                  logo={lab.lab_logo}
                  country={lab.country_name}
                  contactName={lab.contact_name}
                  contactEmail={lab.contact_email}
                  contactPhone={lab.contact_phone}
                  contactAddress={lab.company_address}
                />
              )}
            </div>
          )}

          <ButtonBlock>
            <HButton
              type="submit"
              category="Primary"
              size="Medium"
              text={t('Create Connection').toUpperCase()}
              width="auto"
              margin="0 20px 0 0"
              float="right"
              disabled={onButtonStatus()}
            />
            <HButton
              category="Secondary"
              size="Medium"
              text={t('Cancel').toUpperCase()}
              width="auto"
              margin="0 16px 0 0"
              float="right"
              onClick={onCancel}
            />
          </ButtonBlock>
        </form>
      </ModalBlock>
    </Fullscreen>
  );
};

export default CreateConnection;
