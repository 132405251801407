import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '../../components/leftpanel/MenuItem';
import {
  collapsed,
  collapsedAuto,
  //selectedCaseStatus,
  listAlarms,
  selectedMenu,
} from '../../modules/leftpanel';
import MTextBox from '../../components/leftpanel/TextBox';
import { logout } from '../../modules/user';
import LoggedUser from '../../components/leftpanel/LoggedUser';
import './LeftPanelContainer.css';

//import { listAlarms } from '../../lib/api/leftPanel';
import { setFilters } from '../../modules/cases';
import { useHistory } from 'react-router-dom';

import { initializeForm } from '../../modules/case';
import Util from '../../components/common/Util';
import { getODM } from '../../lib/odm';
import SVG from '../../components/common/svg';
import { useTranslation } from 'react-i18next';
import { useAutoRefreshEvent, removeLocalStorages } from '../../lib/common';
import { logout as api_logout } from '../../lib/api/auth';
import MobileTop from '../../components/auth/styled/MobileTop';
import { TabletOrDesktop } from '../../lib/responsive';

const LeftPanelContainer = () => {
  const { app, leftpanel, user, userSettings, user_checking, error, loading, filter, odm } =
    useSelector(({ app, leftpanel, user, loading, cases }) => ({
      app: app.app,
      odm: app.odm,
      leftpanel,
      user: user.user,
      userSettings: user.settings,
      user_checking: loading['user/CHECK'],
      loading: loading['leftpanel/LIST_ALARM'],
      error: leftpanel.error,
      filter: cases.filters,
    }));

  const [connAlarm, setConnAlarm] = useState(-1);
  const [groupAlarm, setGroupAlarm] = useState(-1);
  // const [event, setEvent] = useState(null);

  const dispatch = useDispatch();
  let history = useHistory();

  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0];

  const event = useAutoRefreshEvent();
  const isTabletOrDesktop = TabletOrDesktop();

  // useEffect(() => {
  //   alert(isTabletOrDesktop);
  // }, [isTabletOrDesktop]);

  useEffect(() => {
    if (event) {
      const msg = JSON.parse(event);
      if (
        msg.id === 'contact_requested' ||
        msg.id === 'sguser_requested' ||
        msg.id === 'contact_deleted' ||
        msg.id === 'sguser_deleted' ||
        msg.id === 'sharegroup_deleted'
      ) {
        dispatch(listAlarms());
      }
    }
  }, [event]);

  const logoutUser = async () => {
    if (window.es) {
      window.es.close();
      delete window.es;
    }
    // localStorage.removeItem('user');
    // localStorage.removeItem('settings');
    // localStorage.removeItem('caseFilters');
    removeLocalStorages();

    dispatch(initializeForm('content'));
    dispatch(logout());
    await api_logout();
  };

  useEffect(() => {
    const handleLogout = (e) => {
      if (e.key === 'user' && e.oldValue && !e.newValue) {
        console.log(e);
        logoutUser();
      }
    };

    if (!user_checking) {
      dispatch(listAlarms());
    }

    window.addEventListener('storage', handleLogout);
    return function cleanup() {
      window.removeEventListener('storage', handleLogout);
    };
  }, [dispatch, user_checking]);

  useEffect(() => {
    let items = window.location.href.toString().split('/');
    // console.log(items);

    let menu = items[items.length - 1];
    const isCaseNo = /\d+-\d+/.test(menu); // ex) 1-86
    if (isCaseNo && 2 <= items.length) menu = items[items.length - 2];
    dispatch(selectedMenu(menu));
  }, [dispatch]);

  function onPersonalSelect(id) {
    if (id === 'Sign out') {
      history.push('/');

      dispatch(
        setFilters({
          status: [],
          unreadCommentsOnly: false,
          createDateFrom: null,
          createDateTo: null,
          institution: 'All',
          person: 'All',
          searchString: '',
          orderBy: '',
        }),
      );
      // dispatch(logout());
      logoutUser();
    } else {
      dispatch(selectedMenu(id));
    }
  }

  function onCaseStatusClicked(id) {
    var tmp = [];

    if (id === 'Any') {
      tmp = [];
    } else if (filter.status.indexOf(id) === -1) {
      tmp = filter.status.concat(id).filter((x) => x !== 'Any');
    } else {
      tmp = filter.status.filter((x) => x !== id && x !== 'Any');

      if (tmp.length === 0) tmp = [];
    }

    filter.status = tmp;

    try {
      localStorage.setItem('caseFilters', JSON.stringify(filter));
    } catch (e) {
      console.log(e);
    }

    dispatch(setFilters(filter));
    history.push('/cases', { filters: filter });
  }

  if (leftpanel.alarms) {
    if (connAlarm !== leftpanel.alarms.contacts.length)
      setConnAlarm(leftpanel.alarms.contacts.length);
    if (groupAlarm !== leftpanel.alarms.sg_users.length)
      setGroupAlarm(leftpanel.alarms.sg_users.length);
  }

  useEffect(() => {
    window.addEventListener('resize', OnResize);
    return () => {
      window.removeEventListener('resize', OnResize);
    };
  }, []);

  useEffect(() => {
    OnResize();
  }, []);

  const OnResize = () => {
    if (window.innerWidth > 1440) {
      dispatch(collapsedAuto(false));
    } else {
      dispatch(collapsedAuto(true));
    }
  };

  const getMainClass = () => {
    if (leftpanel.collapsed || leftpanel.collapsedAuto) return 'lpSmallRect';
    else return 'lpRect';
    /*
  if (window.innerWidth > 1440) {
    if (leftpanel.collapsed)
      return 'ConnectionCollapsed';
    else
      return 'ConnectionNormal';
  }
  else {
    if (leftpanel.collapsed)
      return 'ConnectionCollapsed';
    else
      return 'ConnectionNormal';
  }
  */
  };

  return (
    <>
      {isTabletOrDesktop && (
        <div className={getMainClass()}>
          <div className="row">
            <div
              className="column"
              // style={{ width: '205px' }}
            >
              <img
                src={process.env.PUBLIC_URL + '/' + odm.images + '/leftpanel/logo-home.svg'}
                alt=""
                style={{
                  marginLeft: 20,
                  marginTop: 24,
                  marginBottom: 26,
                  display:
                    leftpanel.collapsed === true || leftpanel.collapsedAuto === true
                      ? 'none'
                      : 'block',
                  userSelect: 'none',
                }}
              />
            </div>
            <div className="column nav_log_locale_div">
              {app &&
                app.server_loc === 'EU' &&
                leftpanel.collapsedAuto === false &&
                leftpanel.collapsed === false && <span class="nav_logo_locale">.eu</span>}
            </div>

            {!leftpanel.collapsedAuto && (
              <div
                className="column"
                style={{
                  marginLeft:
                    app && app.server_loc === 'EU'
                      ? leftpanel.collapsed === true
                        ? '1px'
                        : '11px'
                      : leftpanel.collapsed === true
                      ? '1px'
                      : '31px',
                }}
                onClick={() => dispatch(collapsed(!leftpanel.collapsed))}
                title={leftpanel.collapsed === true ? t('Expand') : t('Collapse')}
              >
                <div className={leftpanel.collapsed === true ? 'expandArrow' : 'collapseArrow'}>
                  <SVG
                    name={'collapse'}
                    color={'#A5A9AE'}
                    colorOver={getODM().main_color_hover}
                    colorOut={'#A5A9AE'}
                    colorDown={getODM().main_color}
                  />
                </div>
                {/* <img
                alt=""
                src={
                  process.env.PUBLIC_URL +
                  '/images/leftpanel/icon-24-menu-collapse-active.svg'
                }
                className={
                  leftpanel.collapsed === true ? 'expandArrow' : 'collapseArrow'
                }
              /> */}
              </div>
            )}
          </div>

          <div style={{ height: 48 }}></div>

          <div className="row">
            <MenuItem id="dashboard" name="Dashboard" link="/dashboard" />
          </div>

          <div className="row">
            <MenuItem id="cases" name="Cases" link="/cases" />
          </div>

          <div
            name="caseStatus"
            style={{
              display:
                leftpanel.menu === 'cases' &&
                leftpanel.collapsed === false &&
                leftpanel.collapsedAuto === false
                  ? 'block'
                  : 'none',
              paddingLeft: '32px',
              paddingTop: '12px',
              paddingBottom: '12px',
              width: '256',
              height: '186',
            }}
          >
            <div>
              <MTextBox
                name="All"
                id="Any"
                cursor="pointer"
                color="#59616a"
                sel_color="#59616a24"
                margin="0px"
                selectedStatus={filter.status.length === 0 ? ['Any'] : filter.status}
                onClick={onCaseStatusClicked}
                fat={false}
              />
              <MTextBox
                name="Open"
                id="Open"
                cursor="pointer"
                color="#2776e9"
                sel_color="#2776e924"
                margin="0 0 0 8px"
                selectedStatus={filter.status}
                onClick={onCaseStatusClicked}
                fat={false}
              />

              {language !== 'fr' && language !== 'pt' && language !== 'pl' && (
                <MTextBox
                  name="In Progress"
                  id="InProgress"
                  cursor="pointer"
                  color="#a78120"
                  sel_color="#a7812024"
                  margin="0 0 0 8px"
                  selectedStatus={filter.status}
                  onClick={onCaseStatusClicked}
                  fat={false}
                />
              )}

              {/* <MTextBox
              name="Submitted"
              id="Submitted"
              cursor="pointer"
              color="#ff6d22"
              sel_color="#ff6d2224"
              margin="0 0 0 8px"
              selectedStatus={filter.status}
              onClick={onCaseStatusClicked}
              fat={false}
            /> */}
            </div>

            {(language === 'fr' || language === 'pt' || language === 'pl') && (
              <div style={{ display: 'block', marginTop: '8px' }}>
                <MTextBox
                  name="In Progress"
                  id="InProgress"
                  cursor="pointer"
                  color="#a78120"
                  sel_color="#a7812024"
                  margin="0 0 0 0px"
                  selectedStatus={filter.status}
                  onClick={onCaseStatusClicked}
                  fat={false}
                />
              </div>
            )}

            <div style={{ display: 'block', marginTop: '8px' }}>
              <MTextBox
                name="Completed"
                id="Completed"
                cursor="pointer"
                color="#50b019"
                sel_color="#50b01924"
                margin="0 0 0 0px"
                selectedStatus={filter.status}
                onClick={onCaseStatusClicked}
                fat={false}
              />
              <MTextBox
                name="Reopened"
                id="Reopened"
                cursor="pointer"
                // color="#e34a4a"
                // sel_color="#e34a4a24"
                color="#2776e9"
                sel_color="#2776e924"
                margin="0 0 0 8px"
                selectedStatus={filter.status}
                onClick={onCaseStatusClicked}
                fat={false}
              />
              {/* <MTextBox
              name="Rescan"
              id="Rescan"
              cursor="pointer"
              color="#e34a4a"
              sel_color="#e34a4a24"
              margin="0 0 0 8px"
              selectedStatus={filter.status}
              onClick={onCaseStatusClicked}
              fat={false}
            /> */}
            </div>

            <div style={{ display: 'block', marginTop: '8px' }}>
              <MTextBox
                name="Closed"
                id="Closed"
                cursor="pointer"
                color="#a0a0a0"
                sel_color="#a0a0a024"
                margin="0 0 0 0px"
                selectedStatus={filter.status}
                onClick={onCaseStatusClicked}
                fat={false}
              />
            </div>
          </div>

          {/* <div className="row">
          <MenuItem
            id="Groups"
            name="Groups"
            img_active={
              process.env.PUBLIC_URL +
              '/images/leftpanel/icon-menu-groups-active.svg'
            }
            img_hover={
              process.env.PUBLIC_URL +
              '/images/leftpanel/icon-menu-groups-hover.svg'
            }
            img_selected={
              process.env.PUBLIC_URL +
              '/images/leftpanel/icon-menu-groups-selected.svg'
            }
            count={groupAlarm}
            link="/groups"
          />
        </div> */}

          <div className="row">
            <MenuItem id="connections" name="Connections" count={connAlarm} link="/connections" />
          </div>

          {/* <div className="row">
          <MenuItem
            id="Settings"
            name="Settings"
            img_active={
              process.env.PUBLIC_URL +
              '/images/leftpanel/icon-menu-archived-active.svg'
            }
            img_hover={
              process.env.PUBLIC_URL +
              '/images/leftpanel/icon-menu-archived-hover.svg'
            }
            img_selected={
              process.env.PUBLIC_URL +
              '/images/leftpanel/icon-menu-archived-selected.svg'
            }
            link="/settings"
          />
        </div> */}

          {!user_checking && (
            <div style={{ position: 'absolute', bottom: '0' }}>
              <LoggedUser
                img={user.picture}
                name={(user.first_name + ' ' + user.last_name).trim()}
                width="254px"
                height="72px"
                collapsed={leftpanel.collapsed || leftpanel.collapsedAuto}
                onSelect={onPersonalSelect}
              />
            </div>
          )}
        </div>
      )}
      {!isTabletOrDesktop && (
        <>
          <MobileTop isLogined={true}></MobileTop>
        </>
      )}
    </>
  );
};

export default LeftPanelContainer;
