import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { changeField, initializeForm, signup } from '../../modules/auth';
import { validate } from '../../lib/api/auth';
import { withRouter } from 'react-router-dom';
import SignupForm from '../../components/auth/SignupForm';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import MessageBox from '../../components/common/MessageBox';
// import ValidateText from '../../components/auth/styled/ValidateText'

const SignupContainer = ({ history, location }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language.split('-')[0];
  const [disabled, setDisabled] = useState(false);
  const imgRef = useRef(null);
  const coverImgRef = useRef(null);
  const [error, setError] = useState(null);
  const recaptchaRef = useRef();
  const dispatch = useDispatch();
  const { form, authError, user, userSettings, odm } = useSelector(({ auth, user, app }) => ({
    form: auth.signup,
    // auth: auth.auth,
    // language: auth.language.language,
    authError: auth.authError,
    user: user.user,
    userSettings: user.settings,
    odm: app.odm,
  }));
  const queryData = qs.parse(location.search, { ignoreQueryPrefix: true });
  // const regex = new RegExp('(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}', 'g');  //Domain
  const themeContext = useContext(ThemeContext);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);
  const [token, setToken] = useState(null);
  const [recaptcha, setRecaptcha] = useState(false);

  const baseImage =
    process.env.PUBLIC_URL + '/' + themeContext.images + '/signup/avatar-64-add.svg';
  const [imgSrc, setImgSrc] = useState(baseImage);
  const isHCloud = odm.name === 'HCLOUD';

  const onMessageBoxOk = () => {
    setMessageVisible(false);

    const formData = new FormData();
    const {
      username,
      password,
      passwordConfirm,
      first_name,
      last_name,
      role,
      institution,
      country,
      phone,
      address,
      picture,
    } = form;

    if ([username, password, passwordConfirm, first_name, last_name, role, country].includes('')) {
      setError(t('Please enter all required items.'));
      window.scrollTo(0, 0);
      return;
    }

    // if (regex.test(first_name) || regex.test(last_name)) {
    //   setError([t('an incorrect first name or last name was entered. please try again')]);
    //   window.scrollTo(0, 0);
    //   return;
    // }

    if (password !== passwordConfirm) {
      setError([t('The two password fields didn’t match')]);
      dispatch(
        changeField({
          form: 'signup',
          key: 'password',
          value: '',
        }),
      );
      dispatch(
        changeField({
          form: 'signup',
          key: 'passwordConfirm',
          value: '',
        }),
      );
      window.scrollTo(0, 0);
      return;
    }

    if (username) formData.append('username', username);
    if (password) formData.append('password', password);
    if (passwordConfirm) formData.append('password_confirm', passwordConfirm);
    if (first_name) formData.append('first_name', first_name);
    if (last_name) formData.append('last_name', last_name);
    if (role) formData.append('role', role);
    if (institution) formData.append('institution', institution);
    if (country) formData.append('country', country);
    if (phone) formData.append('phone', phone);
    if (address) formData.append('address', address);
    if (picture) formData.append('picture', picture);
    // reCAPTCHA
    if (token) formData.append('g-recaptcha-response', token);

    // for (let value of formData.values()) {
    //   console.log(value);
    // }

    dispatch(signup({ formData }));
    recaptchaRef.current.reset();
    //Server will send email about user activate.
  };

  const onMessageBoxCancel = () => {
    setRecaptcha(false);
    recaptchaRef.current.reset();
    setMessageVisible(false);
  };

  const onChange = (e) => {
    const { value, name } = e.target;

    dispatch(
      changeField({
        form: 'signup',
        key: name,
        value,
      }),
    );
  };

  const onFocusOut = (e) => {
    const { value, name } = e.target;
    validate_signup(value, 'signup', name);
  };

  const validate_signup = async (value, formName, field) => {
    const formData = new FormData();
    formData.append('username', '');
    formData.append('password', '');
    formData.append('password_confirm', '');
    formData.append('first_name', '');
    formData.append('last_name', '');
    formData.append('role', '');
    formData.append('institution', '');
    formData.append('country', '');
    formData.append('phone', '');
    formData.append('address', '');
    formData.append('picture', '');
    if (field === 'passwordConfirm') {
      field = 'password_confirm';
      formData.set('password', form.password);
    }
    if (field) formData.set(field, value);

    try {
      let res = await validate({ formData, form: formName, field });

      if (res.statusText !== 'OK') {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const node = document.getElementsByName(field + 'Validate')[0];
      if (JSON.stringify(res.data) === '{}') {
        if (node.childNodes.length > 0) node.removeChild(node.childNodes[0]);
        node.classList.remove('validateCss');
      } else {
        if (res.data.error_message) {
          let text = '';
          res.data.error_message.forEach((el) => {
            if (el === 'This email cannot be used') {
              text = '<div>' + t('This email address is already registered') + '</div>';
            } else {
              text += '<div>' + t(el) + '</div>';
            }
          });

          let small = document.createElement('small');
          // small.setAttribute('class', 'message');
          small.innerHTML = "<font color='red'>" + `${text}` + '</font>';

          if (node.childNodes.length === 0) node.appendChild(small);
          else node.replaceChild(small, node.childNodes[0]);
          node.classList.add('validateCss');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSelect = (value, name) => {
    dispatch(
      changeField({
        form: 'signup',
        key: name,
        value,
      }),
    );
  };

  const onChangeImage = (e) => {
    const { name } = e.target;
    if (e.target.files.length) {
      //selected file
      var imgTarget = e.target.files[0];

      dispatch(
        changeField({
          form: 'signup',
          key: name,
          value: imgTarget,
        }),
      );

      var fileReader = new FileReader();
      fileReader.readAsDataURL(imgTarget);
      fileReader.onload = function (e) {
        setImgSrc(e.target.result);

        if (imgRef) {
          imgRef.current.addEventListener('mouseover', onMouseOver);
          imgRef.current.addEventListener('mouseout', onMouseOut);
        }
        if (coverImgRef) {
          coverImgRef.current.addEventListener('mouseover', onMouseOver);
          coverImgRef.current.addEventListener('mouseout', onMouseOut);
        }
      };

      e.target.value = ''; //onchagne event when same file
    }
  };

  const onMouseOver = useCallback((e) => {
    if (imgRef) {
      imgRef.current.style.opacity = '0.3';
      coverImgRef.current.style.display = 'flex';
      setDisabled(true);
    }
  }, []);
  const onMouseOut = useCallback((e) => {
    if (imgRef) {
      imgRef.current.style.opacity = '1';
      coverImgRef.current.style.display = 'none';
      setDisabled(false);
    }
  }, []);

  const onDeleteClick = (e) => {
    e.preventDefault();
    setImgSrc(baseImage);

    if (imgRef && coverImgRef) {
      onMouseOut();
      imgRef.current.removeEventListener('mouseover', onMouseOver);
      imgRef.current.removeEventListener('mouseout', onMouseOut);
      coverImgRef.current.removeEventListener('mouseover', onMouseOver);
      coverImgRef.current.removeEventListener('mouseout', onMouseOut);
    }

    dispatch(
      changeField({
        form: 'signup',
        key: 'picture',
        value: '',
      }),
    );
  };

  const onSubmit = (token) => {
    if (!token) {
      alert('No reCAPTCHA response');
      return;
    }

    setToken(token);
    if (isHCloud) {
      setMessageVisible(true);
      setMessageTitle(t('Info'));
      setMessageContent(
        t(
          'Data storage region is assigned depending on the country you select. This option cannot be modified after you click OK. Do you want to continue?',
        ),
      );
    }
  };

  useEffect(() => {
    // ODM
    if (token && !isHCloud) onMessageBoxOk();
  }, [token]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    dispatch(initializeForm('authError'));
    history.push('/');
  };

  useEffect(() => {
    window.recaptchaOptions = {
      nonce: window.csp_nonce,
    };
    dispatch(initializeForm('signup'));
    if (queryData) {
      if (queryData.email)
        dispatch(
          changeField({
            form: 'signup',
            key: 'username',
            value: queryData.email,
          }),
        );
      if (queryData.fname)
        dispatch(
          changeField({
            form: 'signup',
            key: 'first_name',
            value: queryData.fname,
          }),
        );
      if (queryData.lname)
        dispatch(
          changeField({
            form: 'signup',
            key: 'last_name',
            value: queryData.lname,
          }),
        );
    }
  }, []);

  //Set ErrorMessage
  useEffect(() => {
    if (authError) {
      if (authError.response.data.username) {
        // setError(authError.response.data.username[0]);
        setError(t('This email address is already registered'));
      } else if (authError.response.data.password) {
        setError(authError.response.data.password);
      } else if (authError.response.data.profile) {
        if (authError.response.data.profile.role) {
          setError(authError.response.data.profile.role);
        } else if (authError.response.data.profile.country) {
          setError(authError.response.data.profile.country);
        }
      } else {
        setError(t('Unable to sign up'));
      }
      window.scrollTo(0, 0);
    }
  }, [authError]);

  useEffect(() => {
    if (user) {
      history.push('/');
      try {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('settings', JSON.stringify(userSettings));
      } catch (e) {
        console.log('localStorage is not working');
      }
    }
  }, [user]);

  return (
    <>
      <SignupForm
        form={form}
        language={language}
        onChange={onChange}
        onSelect={onSelect}
        onChangeImage={onChangeImage}
        imgSrc={imgSrc}
        onSubmit={onSubmit}
        error={error}
        disabled={disabled}
        imgRef={imgRef}
        coverImgRef={coverImgRef}
        recaptchaRef={recaptchaRef}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onDeleteClick={onDeleteClick}
        onBackButtonEvent={onBackButtonEvent}
        onFocusOut={onFocusOut}
        recaptcha={recaptcha}
        setRecaptcha={setRecaptcha}
      />

      <MessageBox
        visible={messageVisible}
        type="okcancel"
        title={messageTitle}
        content={messageContent}
        onOk={onMessageBoxOk}
        onCancel={onMessageBoxCancel}
      />
    </>
  );
};

export default withRouter(SignupContainer);
