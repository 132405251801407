import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Input from '../auth/styled/Input';
import Button from '../auth/styled/Button';
import ErrorList from '../auth/styled/ErrorList';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../modules/user';
import { logout as api_logout } from '../../lib/api/auth';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #eeeff0;

  .title {
    width: 416px;
    height: 32px;
    margin: 0px 0 0 16px;
    font-size: 25px;
    font-weight: 600;
    color: #59616a;
  }
`;

const Content = styled.div`
  display: flex;
  width: 448px;
  flex-direction: column;
`;

const DeviceLogin = ({
  form,
  language,
  lngs,
  onChange,
  onSelect,
  onSubmit,
  onSocialLogin,
  error,
  history,
  app,
  odm,
  match,
}) => {
  const { t } = useTranslation();
  const isDeviceLogin = match.path === '/adm';
  const dispatch = useDispatch();
  const { user, userChecking } = useSelector(({ user, loading }) => ({
    user: user.user,
    userChecking: loading['user/CHECK'],
  }));

  const Logout = async () => {
    if (window.es) {
      window.es.close();
      delete window.es;
    }
    localStorage.removeItem('user');
    localStorage.removeItem('settings');
    localStorage.removeItem('caseFilters');
    dispatch(logout());
    await api_logout();
  };

  useEffect(() => {
    if (!userChecking) {
      if (isDeviceLogin && user && !user.is_device_manager) Logout();
    }
  }, [userChecking]);

  return (
    <Container>
      <Content error={error}>
        <ErrorList error={error} />
        <div className="title">{'Administration'}</div>
        <form onSubmit={onSubmit}>
          <Input
            type="email"
            name="username"
            placeholder={'Email Address'}
            margin="28px 0 0 0"
            onChange={onChange}
            value={form.username}
            tabIndex={1}
            autoFocus
          />
          <Input
            name="password"
            placeholder={'Password'}
            type="password"
            margin="8px 0 0 0 0"
            onChange={onChange}
            value={form.password}
            tabIndex={2}
          />
          <Button margin="16px 0 0 0">{'Sign in'.toUpperCase()}</Button>
        </form>
      </Content>
    </Container>
  );
};

export default DeviceLogin;
