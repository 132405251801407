import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import SVG from '../common/svg';

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination {
    display: inline-block;
    cursor: pointer;
  }

  .pagination span {
    font-family: 'Source Sans Pro';
    font-size: 15px;
    color: rgba(48, 57, 69, 0.8);
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    padding: 0;
    text-decoration: none;
    border: 0;
    /* border: 1px solid #ddd; */
  }

  .pagination span.active {
    font-family: 'Source Sans Pro';
    font-size: 15px;
    color: ${(props) => props.theme.main_color};
    /* cursor: default; */
  }

  .pagination span.disabled {
    background-color: transparent;
    cursor: default;
    /* color: #c3c6c9;*/
  }

  /* .pagination span:hover:not(.active):not(.disabled) { */
  .pagination span:hover:not(.disabled) {
    background-color: #f5f6f6;
  }
`;

const DevicePagination = ({ pageInfo, paginate, odm }) => {
  return (
    <PageContainer>
      {pageInfo && (
        <div class="pagination">
          {/* <span>&laquo;</span> */}
          <span
            className={pageInfo.has_previous ? null : 'disabled'}
            onClick={() => {
              if (pageInfo.has_previous) paginate(1);
            }}
          >
            <SVG
              name="arrow_first"
              color={pageInfo.number !== 1 ? '#878D93' : '#C3C6C9'}
              colorOver={pageInfo.number !== 1 ? '#878D93' : '#C3C6C9'}
              colorOut={pageInfo.number !== 1 ? '#878D93' : '#C3C6C9'}
              colorDown={pageInfo.number !== 1 ? '#878D93' : '#C3C6C9'}
            />
          </span>

          <span
            className={pageInfo.has_previous ? null : 'disabled'}
            onClick={() => {
              if (pageInfo.has_previous) paginate(pageInfo.number - 1);
            }}
          >
            <SVG
              name="arrow_left"
              color={pageInfo.has_previous ? '#878D93' : '#C3C6C9'}
              colorOver={pageInfo.has_previous ? '#878D93' : '#C3C6C9'}
              colorOut={pageInfo.has_previous ? '#878D93' : '#C3C6C9'}
              colorDown={pageInfo.has_previous ? '#878D93' : '#C3C6C9'}
              // colorDown={pageInfo.has_previous ? odm.main_color : '#C3C6C9'}
            />
          </span>
          {/* numbers */}
          {pageInfo.page_range.map((number) => (
            <span
              className={pageInfo.number === number ? 'active' : null}
              key={number}
              onClick={() => {
                if (pageInfo.number !== number) paginate(number);
              }}
            >
              {number}
            </span>
          ))}
          <span
            className={pageInfo.has_next ? null : 'disabled'}
            onClick={() => {
              if (pageInfo.has_next) paginate(pageInfo.number + 1);
            }}
          >
            <SVG
              name="arrow_right"
              color={pageInfo.has_next ? '#878D93' : '#C3C6C9'}
              colorOver={pageInfo.has_next ? '#878D93' : '#C3C6C9'}
              colorOut={pageInfo.has_next ? '#878D93' : '#C3C6C9'}
              colorDown={pageInfo.has_next ? '#878D93' : '#C3C6C9'}
            />
          </span>

          <span
            className={pageInfo.has_next ? null : 'disabled'}
            onClick={() => {
              if (pageInfo.has_next) paginate(pageInfo.num_pages);
            }}
          >
            <SVG
              name="arrow_end"
              color={pageInfo.num_pages !== pageInfo.number ? '#878D93' : '#C3C6C9'}
              colorOver={pageInfo.num_pages !== pageInfo.number ? '#878D93' : '#C3C6C9'}
              colorOut={pageInfo.num_pages !== pageInfo.number ? '#878D93' : '#C3C6C9'}
              colorDown={pageInfo.num_pages !== pageInfo.number ? '#878D93' : '#C3C6C9'}
            />
          </span>
          {/* <span>&raquo;</span> */}
        </div>
      )}
    </PageContainer>
  );
};

export default React.memo(DevicePagination);

export const TicketPagination = ({ pageInfo, paginate }) => {
  return (
    <PageContainer>
      {pageInfo && (
        <div class="pagination">
          <span
            className={pageInfo.has_previous ? null : 'disabled'}
            onClick={() => {
              if (pageInfo.has_previous) paginate(pageInfo.number - 1);
            }}
          >
            <SVG
              name="arrow_left"
              color={pageInfo.has_previous ? '#878D93' : '#C3C6C9'}
              colorOver={pageInfo.has_previous ? '#878D93' : '#C3C6C9'}
              colorOut={pageInfo.has_previous ? '#878D93' : '#C3C6C9'}
              colorDown={pageInfo.has_previous ? '#878D93' : '#C3C6C9'}
              // colorDown={pageInfo.has_previous ? odm.main_color : '#C3C6C9'}
            />
          </span>
          {/* numbers */}
          {pageInfo.page_range.map((number) => (
            <span
              className={pageInfo.number === number ? 'active' : null}
              key={number}
              onClick={() => {
                if (pageInfo.number !== number) paginate(number);
              }}
            >
              {number}
            </span>
          ))}
          <span
            className={pageInfo.has_next ? null : 'disabled'}
            onClick={() => {
              if (pageInfo.has_next) paginate(pageInfo.number + 1);
            }}
          >
            <SVG
              name="arrow_right"
              color={pageInfo.has_next ? '#878D93' : '#C3C6C9'}
              colorOver={pageInfo.has_next ? '#878D93' : '#C3C6C9'}
              colorOut={pageInfo.has_next ? '#878D93' : '#C3C6C9'}
              colorDown={pageInfo.has_next ? '#878D93' : '#C3C6C9'}
            />
          </span>
        </div>
      )}
    </PageContainer>
  );
};
