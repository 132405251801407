import { createAction, handleActions } from 'redux-actions';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as appAPI from '../lib/api/app';

const [APP_INFO, APP_INFO_SUCCESS, APP_INFO_FAILURE] =
  createRequestActionTypes('app/APP_INFO');
const SET_ODM = 'app/SET_ODM';

export const appInfo = createAction(APP_INFO);
export const setODM = createAction(SET_ODM, (odm) => odm);

const appInfoSaga = createRequestSaga(APP_INFO, appAPI.appInfo);

export function* appSaga() {
  yield takeLatest(APP_INFO, appInfoSaga);
}

const initialState = {
  app: null,
  error: null,
};

const app = handleActions(
  {
    [APP_INFO_SUCCESS]: (state, { payload: app }) => ({
      ...state,
      app,
      error: null,
    }),
    [APP_INFO_FAILURE]: (state, { payload: error }) => ({ ...state, error }),
    [SET_ODM]: (state, { payload }) => ({
      ...state,
      odm: payload,
    }),
  },
  initialState,
);

export default app;
