import React from 'react';
import LeftPanel from '../containers/leftpanel/LeftPanelContainer';
import ConnectionsContainer from '../containers/connections/ConnectionsContainer';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GotoCases } from '../lib/responsive';

const Desktop = styled.div`
  .Normal {
    width: 100%;
    min-width: 1100px !important;
    height: 100%;
    margin: auto;
    background: #eeeff0;
    display: grid;
    grid-template-columns: 256px auto;
    grid-template-rows: 100vh;
    gap: 0px;
  }

  .Collapsed {
    width: 100%;
    min-width: 1100px !important;
    height: 100%;
    background: #eeeff0;
    margin: auto;
    display: grid;
    grid-template-columns: 64px auto;
    grid-template-rows: 100vh;
    gap: 0px;
  }

  .LeftPanel {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .Connection {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .ScrollBox {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const ConnectionsPage = () => {
  GotoCases();
  const leftpanel = useSelector((state) => state.leftpanel);

  const getMainClass = () => {
    if (leftpanel.collapsed || leftpanel.collapsedAuto) return 'Collapsed';
    else return 'Normal';
  };

  return (
    <Desktop>
      <div className={getMainClass()}>
        <div className={'LeftPanel'}>
          <LeftPanel />
        </div>
        <div className={'ScrollBox'}>
          <div className={'Connection'}>
            <ConnectionsContainer />
          </div>
        </div>
      </div>
    </Desktop>
  );
};

export default ConnectionsPage;
