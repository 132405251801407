import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { TransformControls } from '../../lib/threejs/TransformControls';
import { Desktop } from '../../lib/responsive';

const CaseDetail3DPreview = ({ width, height, mainScene, mainCamera }) => {
  const mountRef = useRef(null);
  const isMouseDownRef = useRef(false);
  const isDraggingControls = useRef(false);
  const transformControlsRef = useRef(null);
  const isDesktop = Desktop();

  useEffect(() => {
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);
    mountRef.current.appendChild(renderer.domElement);

    const scene = new THREE.Scene();
    // scene.background = new THREE.Color(0xf0f1f2);

    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.z = 5.8;

    // Créer des textures pour chaque lettre
    const createTextTexture = (text) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = 256;
      canvas.height = 256;
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = '#797878';
      context.font = '220px Source Sans Pro';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(text, canvas.width / 2, canvas.height / 2);
      return new THREE.CanvasTexture(canvas);
    };

    const materials = [
      new THREE.MeshBasicMaterial({ color: 0xf0f1f2, map: createTextTexture('L') }), // Face à gauche
      new THREE.MeshBasicMaterial({ color: 0xf0f1f2, map: createTextTexture('R') }), // Face à droite
      new THREE.MeshBasicMaterial({ color: 0xf0f1f2, map: createTextTexture('H') }), // Face haut
      new THREE.MeshBasicMaterial({ color: 0xf0f1f2, map: createTextTexture('F') }), // Face dessous
      new THREE.MeshBasicMaterial({ color: 0xf0f1f2, map: createTextTexture('A') }), // Face avant
      new THREE.MeshBasicMaterial({ color: 0xf0f1f2, map: createTextTexture('P') }), // Face arrière
    ];

    const geometry = new THREE.BoxGeometry(2, 2, 2);
    const cube = new THREE.Mesh(geometry, materials);

    // Création des arêtes
    const createThickEdges = (geometry, thickness) => {
      const edges = new THREE.EdgesGeometry(geometry);
      const edgeLines = new THREE.Group();
      const edgeMaterial = new THREE.MeshBasicMaterial({ color: '#C3C2C2' });

      edges.attributes.position.array.forEach((_, i) => {
        if (i % 6 === 0) {
          const start = new THREE.Vector3(
            edges.attributes.position.array[i],
            edges.attributes.position.array[i + 1],
            edges.attributes.position.array[i + 2],
          );
          const end = new THREE.Vector3(
            edges.attributes.position.array[i + 3],
            edges.attributes.position.array[i + 4],
            edges.attributes.position.array[i + 5],
          );

          const edgeGeometry = new THREE.BoxGeometry(
            thickness,
            thickness,
            start.distanceTo(end),
          );
          const edge = new THREE.Mesh(edgeGeometry, edgeMaterial);

          edge.position.copy(start).add(end).divideScalar(2);
          edge.lookAt(end);

          edgeLines.add(edge);
        }
      });

      return edgeLines;
    };

    const thickEdges = createThickEdges(geometry, 0.045);

    cube.add(thickEdges);
    scene.add(cube);

    // Add TransformControls
    const transformControls = new TransformControls(camera, renderer.domElement);
    transformControls.isPreview = true;
    transformControls.setMode('rotate');
    transformControls.attach(cube);

    scene.add(transformControls);

    transformControlsRef.current = transformControls; // Store ref

    // Listen for transform changes
    transformControls.addEventListener('objectChange', () => {
      if (mainScene) {
        const pivot = mainScene?.children.find((child) => child.type === 'Group');

        if (pivot) {
          pivot.quaternion.copy(cube.quaternion); // Cube is accessible
          pivot.updateMatrix();
        }
      }
    });

    //Dragging
    transformControls.addEventListener('dragging-changed', (event) => {
      isDraggingControls.current = event.value;
    });

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      if (mainScene) {
        const pivot = mainScene.children.find((child) => child.type === 'Group');
        if (pivot) {
          if (transformControls.dragging) {
            pivot.quaternion.copy(cube.quaternion); // Cube is accessible
          } else {
            cube.quaternion.copy(pivot.quaternion); // Cube is accessible
          }
        }
      }

      renderer.render(scene, camera);
    };
    animate();

    const onMouseDown = (event) => {
      isMouseDownRef.current = true;

      const mouse = new THREE.Vector2(
        (event.offsetX / width) * 2 - 1,
        -(event.offsetY / height) * 2 + 1,
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObject(cube); // Cube is accessible

      if (intersects.length > 0 && !isDraggingControls.current) {
        // If clicking on the cube and NOT dragging controls, disable controls temporarily
        transformControlsRef.current.enabled = false;
      }
    };

    const onMouseUp = (event) => {
      if (isMouseDownRef.current) {
        const mouse = new THREE.Vector2(
          (event.offsetX / width) * 2 - 1,
          -(event.offsetY / height) * 2 + 1,
        );

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObject(cube); // Cube is accessible

        if (intersects.length > 0 && !isDraggingControls.current) {
          // Only rotate if it was a click on the cube and not a TransformControls drag
          const face = intersects[0].face;
          const normal = face.normal;
          const pivot = mainScene?.children.find((child) => child.type === 'Group');

          if (pivot) {
            pivot.rotation.set(0, 0, 0);

            if (normal.x > 0.9) {
              pivot.rotation.y = -Math.PI / 2;
            } else if (normal.x < -0.9) {
              pivot.rotation.y = Math.PI / 2;
            } else if (normal.y > 0.9) {
              pivot.rotation.x = Math.PI / 2;
            } else if (normal.y < -0.9) {
              pivot.rotation.x = -Math.PI / 2;
            } else if (normal.z > 0.9) {
              // Default rotation (0,0,0)
            } else if (normal.z < -0.9) {
              pivot.rotation.y = Math.PI;
            }

            pivot.updateMatrix();

            if (mainScene) {
              const mainTransformControls = mainScene.children.find(
                (child) => child.isTransformControls,
              );
              if (mainTransformControls) {
                mainTransformControls.update();
              }
            }
          }
        }
      }

      isMouseDownRef.current = false;
      transformControlsRef.current.enabled = true;
    };

    // Helper function to get face name
    const getFaceName = (normal) => {
      if (normal.x > 0.9) return 'Left';
      if (normal.x < -0.9) return 'Right';
      if (normal.y > 0.9) return 'Top';
      if (normal.y < -0.9) return 'Bottom';
      if (normal.z > 0.9) return 'Front';
      return 'Back';
    };

    renderer.domElement.addEventListener('mousedown', onMouseDown);
    renderer.domElement.addEventListener('mouseup', onMouseUp);

    // Cleanup
    return () => {
      renderer.domElement.removeEventListener('mousedown', onMouseDown);
      renderer.domElement.removeEventListener('mouseup', onMouseUp);
      mountRef.current?.removeChild(renderer.domElement);
    };
  }, [width, height, mainScene, mainCamera]);

  return (
    <div
      ref={mountRef}
      style={{
        position: 'absolute',
        width: width,
        height: height,
        borderRadius: '4px',
        overflow: 'hidden',
        display: 'block',
        // border: '1px solid red' // FOR DEBUG
        ...(isDesktop
          ? {
              bottom: -10,
              left: -60,
              // border: "1px solid red"
            }
          : {
              bottom: -40,
              left: -40,
              // border: "1px solid green"
            }),
      }}
    />
  );
};

export default CaseDetail3DPreview;
