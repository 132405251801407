import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router';
import app, { appSaga } from './app';
import auth, { authSaga } from './auth';
import loading from './loading';
import user, { userSaga } from './user';
import leftpanel, { alarmSaga } from './leftpanel';
import cases, { casesSaga } from './cases';
import caseDetail, { caseDetailSaga } from './case';
import dashboard, { dashboardSaga } from './dashboard';
import connections, { connectionsSaga } from './connections';
import groups, { groupsSaga } from './groups';
// import settings, { settingsSaga } from './settings';
import devices, { devicesSaga } from './devices';
import cs, { csSaga } from './cs';
import { userFilters } from '../lib/api/cases';
import helpdesk from './helpdesk';
import cursorLoading from './cursorLoading';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    loading,
    cursorLoading,
    user,
    leftpanel,
    cases,
    caseDetail,
    dashboard,
    connections,
    groups,
    // settings,
    devices,
    cs,
    helpdesk,
  });

export function* rootSaga() {
  yield all([
    appSaga(),
    authSaga(),
    userSaga(),
    casesSaga(),
    caseDetailSaga(),
    dashboardSaga(),
    connectionsSaga(),
    groupsSaga(),
    // settingsSaga(),
    alarmSaga(),
    devicesSaga(),
    csSaga(),
    // helpdeskSaga(),
  ]);
}

export default createRootReducer;
