import client from './client';
import { newActionId } from '../../index';

export const listGroupUsers = () => client.get('/v1/group/users/');

export const newGroup = ({ name }) => client.post('/v1/group/new/', { name });

export const editGroup = ({ id, name }) =>
  client.put(
    `/v1/group/${id}/edit/`,
    { name },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

export const deleteGroup = ({ id }) =>
  client.delete(`/v1/group/${id}/remove/`, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });

export const requestGroupUser = ({ sg_id, id, email }) =>
  client.post(
    `/v1/group/${sg_id}/request/${id}/`,
    { email },
    {
      headers: { 'X-Action-ID-For-Event': newActionId() },
    },
  );

export const acceptGroupUser = ({ sguidb64, token, srv_loc = null }) => {
  let uri = `/v1/group/accept/${sguidb64}/${token}/`;
  if (srv_loc) uri += `?srv_loc=${srv_loc}`;
  return client.put(uri, null, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });
};

export const rejectGroupUser = ({ id }) =>
  client.put(`/v1/group/users/${id}/reject/`, null, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });

export const deleteGroupUser = ({ id }) =>
  client.delete(`/v1/group/users/${id}/remove/`, {
    headers: { 'X-Action-ID-For-Event': newActionId() },
  });
