import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CaseDetailTop from './CaseDetailTop';
import CaseDetailTitle from './CaseDetailTitle';
import CaseDetailInfo from './CaseDetailInfo';
import CaseDetailTeeth from './CaseDetailTeeth';
import CaseDetail3D from './CaseDetail3D';
import CaseDetailNotes from './CaseDetailNotes';
import CaseDetailComments from './CaseDetailComments';
import CaseDetailAttachments from './CaseDetailAttachments';
import { useTranslation } from 'react-i18next';
import Spinner from '../common/Spinner';
import Dropzone from 'react-dropzone';
import { TabletOrDesktop } from '../../lib/responsive';

const CaseDetailMainBox = styled.div`
  width: 100%;
  height: 100%;

  .caseDetailBottom {
    height: ${(props) => props.bottomHeight};
  }
`;

const CaseDetailTransition = styled.div`
  .loading {
    width: 100%;
    height: 53px;
    margin-top: 200px;
    text-align: center;
  }

  .noContent {
    width: 100%;
    height: 19px;
    margin-top: 390px;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #59646a;
  }
`;

const CaseDetail = ({ error, loading, downloadFilesAll }) => {
  const { content, files, comments, user, cursorLoading } = useSelector(
    ({ caseDetail, user, cursorLoading }) => ({
      content: caseDetail.content,
      files: caseDetail.files,
      comments: caseDetail.comments,
      user: user.user,
      cursorLoading: cursorLoading['caseDownLoading'],
    }),
  );
  const { t } = useTranslation();
  const [anyUserDeleted, setAnyUserDeleted] = useState(null);
  const [OpenAddCommentDlg, setOpenAddCommentDlg] = useState(false);
  const [CaseDetailFileList, setCaseDetailFileList] = useState(undefined);
  const commentRef = useRef(null);
  const attachmentRef = useRef(null);
  const isTabletOrDesktop = TabletOrDesktop();

  useEffect(() => {
    if (content)
      setAnyUserDeleted(
        (content.to.is && content.to.is_deleted) || content.author.is_deleted,
      );
    window.scrollTo(0, 0);
  }, [content]);

  const handleDrop = (acceptedFiles) => {
    if (content.status.code !== 'CLSD') {
      setOpenAddCommentDlg(true);
      setCaseDetailFileList(acceptedFiles);
    }
  };

  if (error) {
    return (
      <CaseDetailTransition>
        <div className={'noContent'}>
          <p>{error.message}</p>
        </div>
      </CaseDetailTransition>
    );
  }

  if (!content) {
    return (
      <CaseDetailTransition>
        <div className={'noContent'}>{t('No Case Selected')}</div>
      </CaseDetailTransition>
    );
  }

  const onClickComments = () => commentRef.current.scrollIntoView();
  const onClickAttachments = () => attachmentRef.current.scrollIntoView();

  return (
    <Dropzone
      onDrop={handleDrop}
      noClick={true}
      maxSize={200 * 1024 * 1024}
      accept="image/*,.pdf,.xml,.obj,.ply,.stl,.qcProject,.xyz"
    >
      {({ getRootProps, getInputProps }) => (
        <CaseDetailMainBox
          {...getRootProps({ className: 'dropzone' })}
          bottomHeight={isTabletOrDesktop ? '96px' : '80px'}
        >
          {loading && (
            <CaseDetailTransition>
              <div className={'loading'}>
                <Spinner />
              </div>
            </CaseDetailTransition>
          )}
          {!loading && (
            <>
              {isTabletOrDesktop && (
                <CaseDetailTop
                  content={content}
                  files={files}
                  user={user}
                  downloadFilesAll={downloadFilesAll}
                  cursorLoading={cursorLoading}
                />
              )}
              <CaseDetailTitle
                files={files}
                comments={comments}
                onClickComments={onClickComments}
                onClickAttachments={onClickAttachments}
              />
              <CaseDetailInfo content={content} anyUserDeleted={anyUserDeleted} />
              <CaseDetailTeeth content={content} />
              {!loading && <CaseDetail3D />}
              <CaseDetailNotes content={content} />
              <div ref={commentRef}></div>
              <CaseDetailComments
                OpenAddCommentDlg={OpenAddCommentDlg}
                CaseDetailFileList={CaseDetailFileList}
                setOpenAddCommentDlg={setOpenAddCommentDlg}
                setCaseDetailFileList={setCaseDetailFileList}
              />
              <div ref={attachmentRef}></div>
              <CaseDetailAttachments
                content={content}
                files={files}
                user={user}
                downloadFilesAll={downloadFilesAll}
                cursorLoading={cursorLoading}
              />
            </>
          )}
          <div className={'caseDetailBottom'}></div>
        </CaseDetailMainBox>
      )}
    </Dropzone>
  );
};

export default React.memo(CaseDetail);
