import qs from 'qs';
import client from './client';

export const casesStatus = () => {
  return client.get(`/v1/dashboard/cases/status/`);
};

export const casesRestorations = ({ period }) => {
  const queryString = qs.stringify({ period });
  return client.get(`v1/dashboard/cases/restorations/?${queryString}`);
};

export const casesActivities = ({ period, page }) => {
  const queryString = qs.stringify({ period, page });
  return client.get(`v1/dashboard/cases/activities/?${queryString}`);
};
