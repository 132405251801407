import qs from 'qs';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { listConnections } from '../../modules/connections';
import Connections from '../../components/connections/Connections';
import { acceptConnection } from '../../lib/api/connections';
import { useRefreshOnAuthError, useAutoRefreshEvent } from '../../lib/common';

const ConnectionsContainer = ({ match, location, history }) => {
  const { cidb64, token } = match.params;
  const { app, user, userSettings, userChecking, error } = useSelector(
    ({ app, user, loading, connections }) => ({
      app: app.app,
      user: user.user,
      userSettings: user.settings,
      userChecking: loading['user/CHECK'],
      error: connections.error,
    }),
  );
  const dispatch = useDispatch();
  const [accept, setAccept] = useState(null);

  const event = useAutoRefreshEvent();

  useEffect(() => {
    if (event) {
      const msg = JSON.parse(event);
      if (!window.actionIDs.has(msg.action_id)) {
        if (
          msg.id === 'contact_requested' ||
          msg.id === 'contact_accepted' ||
          msg.id === 'contact_rejected' ||
          msg.id === 'contact_modified' ||
          msg.id === 'contact_deleted'
        ) {
          dispatch(listConnections());
        }
      }
    }
  }, [event]);

  const acceptConn = async (cidb64, token, sid) => {
    try {
      if (sid) {
        const srv_loc = Buffer.from(sid, 'base64').toString('utf8');
        // if (srv_loc === app.server_loc) {
        const res = await acceptConnection({ cidb64, token, srv_loc });
        setAccept({ result: true, detail: res.data.detail });
        // } else {
        //   setAccept({ result: false, detail: 'Server not same' });
        // }
      } else {
        setAccept({ result: false, detail: 'no server location' });
      }
    } catch (e) {
      setAccept({ result: false, error: e });
    }
  };

  useEffect(() => {
    if (cidb64 && token && !userChecking) {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      acceptConn(cidb64, token, params.sid);
    }
  }, [userChecking]);

  useRefreshOnAuthError();

  return <Connections match={match.params} history={history} accept={accept} />;
};

export default withRouter(ConnectionsContainer);
